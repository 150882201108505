import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { MasterRouteAddEditComponent } from './add-edit/master-route-add-edit.component';
import { MasterRouteListComponent } from './list/master-route-list.component';
import { WarehousePermission } from '../../helpers/permissions.helpers';

export const MasterRouteRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: WarehousePermission.routes } },
        canActivate: [AuthGuard],
        path: 'add',
        component: MasterRouteAddEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.routes } },
        canActivate: [AuthGuard],
        path: 'edit',
        component: MasterRouteAddEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.routes } },
        canActivate: [AuthGuard],
        path: '',
        component: MasterRouteListComponent,
      },
    ],
  },
];
