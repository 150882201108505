import { EventEmitter, Injectable } from '@angular/core';
import { OvAutoService, OvAutoServiceMultipleAll} from '@ov-suite/services';
import { LoadAllocation, WaveInstance } from '@ov-suite/models-warehouse';
import { InventoryLocation } from '@ov-suite/models-admin';
import moment from 'moment/moment';
import { PageReturn } from '@ov-suite/ov-metadata';
import { getCreate } from '@ov-suite/graphql-helpers';
import * as _ from 'lodash';
import { DateRange } from '@ov-suite/ui';
import { ListCombo, LoadPlan } from './wave-allocation.interface';
import { releaseForPickingGql } from './wave-allocation.grahpql';

/**
 * This Services is used for Fetching, Manipulating and Saving Data.
 *
 * This should be the only service on load-allocation with ovAutoService. Please be sure to manage observables correctly
 */

// Todo: Make conflict check on loading

interface SortableColumn {
  name: string;
  getSortValue: (loadPlan: LoadPlan) => unknown;
}

interface LoadsFilter {
  customerSearchTerm: string;
}

@Injectable()
export class WaveAllocationDataService {
  displayUpdate = new EventEmitter();

  releaseWaveCall = new EventEmitter<WaveInstance>();

  loadsFilter: LoadsFilter = {
    customerSearchTerm: null,
  };

  waveDays: ListCombo<WaveInstance[]> = {
    all: [],
    value: [],
    observable: new EventEmitter<WaveInstance[][]>(),
  };

  loadBays: ListCombo<InventoryLocation> = {
    all: [],
    value: [],
    observable: new EventEmitter<InventoryLocation[]>(),
  };

  loads: ListCombo<LoadPlan> = {
    all: [],
    value: [],
    observable: new EventEmitter<LoadAllocation[]>(),
  };

  distributedLoads: ListCombo<LoadPlan> = {
    all: [],
    value: [],
    observable: new EventEmitter<LoadAllocation[]>(),
  };

  _dateRange: DateRange;

  set dateRange(input: DateRange) {
    this._dateRange = input;
    this.fetchData();
  }

  get dateRange(): DateRange {
    return this._dateRange;
  }

  loadsDateRange: DateRange = this.getDefaultDateRange();

  loadPlanColumns: SortableColumn[] = [
    { name: 'load id', getSortValue: this.getId },
    { name: 'registration', getSortValue: this.getVehicleRegistration },
    { name: 'due date', getSortValue: this.getDueDate },
    { name: 'orders', getSortValue: this.getOrders },
  ];

  sortingData: { column: string; direction: 'ASC' | 'DESC' } = {
    column: 'id',
    direction: 'ASC',
  };

  constructor(private readonly ovAutoService: OvAutoService) {}

  onInit() {
    this.fetchData();
  }

  getDefaultDateRange(): DateRange {
    const date = moment();
    return {
      type: 'date-range',
      from: date.startOf('day').toDate(),
      to: date.endOf('day').toDate(),
    };
  }

  getLoadBaysFetch(): OvAutoServiceMultipleAll {
    return {
      entity: InventoryLocation,
      type: 'list',
      keys: ['id', 'factoryAreaId', 'name', 'description'],
      filter: {
        categoryId: ['2'],
      },
    };
  }

  getLoadKeys(): string[] {
    return [
      'id',
      'date',
      'commit',
      'vehicle.id',
      'vehicle.class.name',
      'vehicle.registration',
      'externalVehicle.id',
      'externalVehicle.vehicleClass',
      'externalVehicle.registration',
      'externalVehicle.model',
      'externalVehicle.make',
      'externalVehicle.weightLimit',
      'externalVehicle.width',
      'externalVehicle.length',
      'externalVehicle.height',
      'externalVehicle.startTime',
      'externalVehicle.endTime',
      'orders.id',
      'orders.location',
      'orders.priority',
      'orders.orderDate',
      'orders.customer.id',
      'orders.customer.name',
      'orders.customer.customerCode',
      'orders.customer.description',
      'orders.orderItems.quantity',
      'orders.orderItems.id',
      'orders.orderItems.productSku',
      'wave.id',
      'loadBay.id',
      'commitDate',
      'releaseDate',
    ];
  }

  getLoadsFetch(): OvAutoServiceMultipleAll {
    const customerSearchTerm = this.loadsFilter.customerSearchTerm ? [this.loadsFilter.customerSearchTerm] : [''];
    const loadsDateRange = this.getDefaultDateRange();
    return {
      entity: LoadAllocation,
      type: 'list',
      query: {
        waveId: [null],
        loadBayId: [null],
        commitDate: [
          {
            value: null,
            operator: 'IS NOT',
          },
        ],
        'load.date': [this.loadsDateRange ?? loadsDateRange],
      },
      search: {
        'orders.customer.name': customerSearchTerm,
        'orders.customer.customerCode': customerSearchTerm,
      },
      keys: this.getLoadKeys(),
    };
  }

  getDistributedLoadsFetch(): OvAutoServiceMultipleAll {
    const date = moment().format('yyyy-MM-DD');
    return {
      type: 'list',
      entity: LoadAllocation,
      query: {
        waveId: [
          {
            value: null,
            operator: 'IS NOT',
          },
        ],
        loadBayId: [
          {
            value: null,
            operator: 'IS NOT',
          },
        ],
        date: [this.dateRange ?? date],
      },
      keys: this.getLoadKeys(),
    };
  }

  getWavesFetch(): OvAutoServiceMultipleAll {
    const date = moment().format('yyyy-MM-DD');
    return {
      entity: WaveInstance,
      type: 'list',
      keys: [
        'id',
        'startDate',
        'waveConfig.id',
        'waveConfig.waveName',
        'waveConfig.startTime',
        'waveConfig.endTime',
        'waveConfig.description',
      ],
      query: {
        startDate: [this.dateRange ?? date],
      },
    };
  }

  fetchData = _.debounce(this._fetchData, 500, { trailing: true });

  public _fetchData(): void {
    this.ovAutoService
      .multipleFetch({
        loadBays: this.getLoadBaysFetch(),
        loads: this.getLoadsFetch(),
        waves: this.getWavesFetch(),
        distributedLoads: this.getDistributedLoadsFetch(),
      })
      .then(response => {
        const loadBays = (response.loadBays as PageReturn<InventoryLocation>).data;
        const loads = (response.loads as PageReturn<LoadAllocation>).data;
        const distributedLoads = (response.distributedLoads as PageReturn<LoadAllocation>).data;
        const waves = (response.waves as PageReturn<WaveInstance>).data;

        this.setLoads(loads);
        this.setLoadBays(loadBays);
        this.setWaves(waves);
        this.setDistributedLoads(distributedLoads);

        this.displayUpdate.emit();
      });
  }

  setWaves(waves: WaveInstance[]): void {
    const formattedWavesMap: Record<string, WaveInstance[]> = {};
    waves.forEach(w => {
      if (!formattedWavesMap[w.startDate]) {
        formattedWavesMap[w.startDate] = [w];
      } else {
        formattedWavesMap[w.startDate].push(w);
      }
    });
    const formattedWaves = Object.values(formattedWavesMap);
    formattedWaves.forEach(waveSet => {
      waveSet.sort((a, b) => {
        if (a.startDate > b.startDate) {
          return 1;
        }
        if (a.startDate < b.startDate) {
          return -1;
        }
        return 0;
      });
    });

    this.waveDays.all = formattedWaves;
    this.waveDays.value = formattedWaves;
    this.waveDays.observable.emit(formattedWaves);
  }

  setLoadBays(loadBays: InventoryLocation[]): void {
    this.loadBays.value = loadBays;
    this.loadBays.all = loadBays;
    this.loadBays.observable.emit(loadBays);
  }

  setLoads(loads: LoadAllocation[]): void {
    const loadPlans = loads.map(load => LoadPlan.fromLoad(load));
    // const distributed: LoadPlan[] = [];
    // const undistributedLoads = loadPlans.filter(i => {
    //   if (!i.load.loadBay && !i.load.wave) {
    //     return true;
    //   }
    //   distributed.push(i);
    //   return false;
    // });
    const undistributedLoads = loadPlans;
    this.loads.all = loadPlans;
    this.loads.value = undistributedLoads;
    this.loads.observable.emit(loadPlans);

    // this.distributedLoads.all = distributed;
    // this.distributedLoads.value = distributed;
    // this.distributedLoads.observable.emit(distributed);
    this.displayUpdate.emit();
  }

  setDistributedLoads(loads: LoadAllocation[]): void {
    const distributed = loads.map(load => LoadPlan.fromLoad(load));
    this.distributedLoads.all = distributed;
    this.distributedLoads.value = distributed;
    this.distributedLoads.observable.emit(distributed);
    this.displayUpdate.emit();
  }

  save(input: LoadPlan) {
    input.load.wave = input.wave ?? null;
    input.load.loadBay = input.loadBay ?? null;
    this.ovAutoService.debounceUpdate(
      {
        entity: LoadAllocation,
        item: getCreate(input.load),
        keys: ['id'],
      },
      res => {
        console.log({ res });
      },
    );
  }

  releaseWave(wave: WaveInstance) {
    const conf = window.confirm('Are you sure you want to release this wave for picking? It cannot be undone');
    if (conf) {
      this.releaseWaveCall.emit(wave);
    }
  }

  pendingRelease: LoadPlan[] = [];

  accumulateRelease(loadPlan: LoadPlan[]) {
    const plansToCommit = loadPlan.filter(i => !i.load.releaseDate);
    this.pendingRelease.push(...plansToCommit);
    this.debounceRelease();
  }

  private readonly debounceRelease = _.debounce(
    () => {
      this.release(this.pendingRelease);
      this.pendingRelease = [];
    },
    500,
    { trailing: true },
  );

  release(input: LoadPlan | LoadPlan[]) {
    let loads;
    if (Array.isArray(input)) {
      loads = input.map(i => {
        i.load.releaseDate = new Date();
        return getCreate(i.load);
      });
    } else {
      input.load.releaseDate = new Date();
      loads = [getCreate(input.load)];
    }

    this.ovAutoService.apollo
      .use('warehouselink')
      .mutate({
        mutation: releaseForPickingGql(),
        fetchPolicy: 'no-cache',
        variables: {
          loads,
        },
      })
      .toPromise()
      .catch(error => {
        if (Array.isArray(input)) {
          input.forEach(i => {
            i.load.releaseDate = null;
          });
        } else {
          input.load.releaseDate = null;
        }
        throw error;
      });
  }

  getId(loadPlan: LoadPlan): number {
    return loadPlan.load.id;
  }

  getVehicleRegistration(loadPlan: LoadPlan): string {
    return loadPlan.load.getVehicle()?.registration;
  }

  getDueDate(loadPlan: LoadPlan): string {
    return loadPlan.load.date;
  }

  getOrders(loadPlan: LoadPlan): number {
    return loadPlan.load.orders.length;
  }

  changeSortingColumn(column: string): void {
    this.sortingData = { column, direction: 'ASC' };
  }

  changeSortingDirection() {
    this.sortingData.direction = this.sortingData.direction === 'ASC' ? 'DESC' : 'ASC';
  }

  sortLoads() {
    const sortingColumn = this.loadPlanColumns.find(item => item.name === this.sortingData.column);
    const { getSortValue } = sortingColumn;

    if (typeof getSortValue(this.loads.value[0]) === 'number') {
      this.loads.value.sort((a, b) => {
        return (getSortValue(a) as number) - (getSortValue(b) as number);
      });
    } else if (typeof getSortValue(this.loads.value[0]) === 'string') {
      this.loads.value.sort((a, b) => {
        return (getSortValue(a) as string).localeCompare(getSortValue(b) as string);
      });
    }

    if (this.sortingData.direction === 'DESC') {
      this.loads.value.reverse();
    }
  }

  updateLoadsDateRange(dateRange: DateRange) {
    this.loadsDateRange = dateRange;
    this.fetchData();
  }
}
