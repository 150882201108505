import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { OrderAuditTrailEvent } from '@ov-suite/models-order';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: OrderAuditTrailEvent,
      api: 'warehouselink',
      createTitle: 'Order Audit Trail Events',
      route: ['order-events'],
      tableTitle: 'Order Events',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      permissionId: WarehousePermission.resources,
    }),
  ],
})
export class OrdersAuditTrailEventModule {}
