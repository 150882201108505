<div id="load-sheet-print" class="p-2">
  <h4> Confirmed Vehicles </h4>

        <table class="table">
          <thead>
          <tr class="table-secondary">
            <th scope="col">Delivery Area</th>
            <th scope="col">Vehicle Class</th>
            <th scope="col">Vehicle Reg</th>
            <th scope="col">Wave</th>
            <th scope="col">Load Bay</th>
            <th scope="col">Load Date</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of loads">
            <th>Pietermaritzberg</th>
            <td>{{ item.load.getVehicle()?.class?.name }}</td>
            <td>{{ item.load.getVehicle()?.registration }}</td>
            <td>{{ item.wave?.waveConfig?.waveName }}</td>
            <td>{{ item.loadBay?.name }}</td>
            <td>{{ item.load?.date }}</td>
          </tr>
          </tbody>
        </table>

        <button type="button" (click)="activeModal.close()" class="btn btn-outline-primary ml-4 mb-4 float-left">
          Close
        </button>
        <button type="button" (click)="onPrint()" class="btn btn-outline-dark mr-4 mb-4 float-right">
          Print <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
        </button>
</div>


