import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import gql from 'graphql-tag';
import { WaveConfig, WaveInstance } from '@ov-suite/models-warehouse';

@Injectable()
export class WaveService extends AutoService<WaveConfig> {
  constructor(private readonly apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      WaveConfig,
      'WaveConfig',
      'WaveConfigs',
      'warehouselink',
    );
  }

  async updateWaveConfig(
    item: Partial<WaveConfig>,
  ): Promise<{ id: number, changes: WaveInstance[] }> {
    const name = 'updateWaveConfig';
    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: WaveConfigUpdateInput!) {
            ${name}(data: $data) {
                id
                changes {
                  startDate
                }
            }
          }`),
          variables: {
            data: item,
          },
        })
        .subscribe(
          response => {
            resolve(response.data[name]);
            },
          reject,
        );
    });
  }
}
