import { Component, Input, OnInit } from '@angular/core';
import { WaveInstance } from '@ov-suite/models-warehouse';
import { InventoryLocation } from '@ov-suite/models-admin';
import { LoadPlan } from '../wave-allocation.interface';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';
import { WaveAllocationActionService } from '../wave-allocation.action.service';
import { WaveAllocationDataService } from '../wave-allocation.data.service';

@Component({
  selector: 'ov-suite-wave-allocation-wave-bay',
  templateUrl: './wave-allocation-wave-bay.component.html',
  styleUrls: ['./wave-allocation-wave-bay.component.scss'],
})
export class WaveAllocationWaveBayComponent implements OnInit {
  @Input() loadBay: InventoryLocation;

  @Input() wave: WaveInstance;

  waveBay: LoadPlan[] = [];

  pinId = `load_${Math.random()}`;

  constructor(
    public baseService: WaveAllocationBaseService,
    public actionService: WaveAllocationActionService,
    private readonly dataService: WaveAllocationDataService,
  ) {
    actionService.conflictCheck.subscribe((event: LoadPlan) => {
      this.waveBay.forEach(waveBay => {
        if (waveBay !== event) {
          if (waveBay.wave === event.wave && waveBay.load.getVehicle()?.id === event.load.getVehicle()?.id) {
            actionService.logConflict([waveBay, event]);
          }
        }
      });
    });
    dataService.loads.observable.subscribe((loads: LoadPlan[]) => {
      this.initialize(loads);
    });
    dataService.releaseWaveCall.subscribe((wave: WaveInstance) => {
      if (wave === this.wave) {
        dataService.accumulateRelease(this.waveBay);
      }
    });
  }

  initialize(loads: LoadPlan[]): void {
    const currentLoads = loads.filter(i => i.load.wave?.id === this.wave.id && i.load.loadBay?.id === this.loadBay.id);
    currentLoads.forEach(load => {
      load.wave = this.wave;
      load.loadBay = this.loadBay;
      this.waveBay.push(load);
    });
  }

  ngOnInit(): void {
    this.pinId = `load_${this.wave.id}_${this.loadBay.id}`;
    this.initialize(this.dataService.distributedLoads.all);
  }

  onRelease(data) {
    this.dataService.release(data);
  }
}
