<div class="ads-input-container">
  <div class="ads-input-wrapper">
    <input placeholder="Search" type="text" class="advanced-search-input" (change)="onSearchChange($event)"/>
  </div>
  <div class="ads-dropdown-wrapper">
    <div ngbDropdown display="dynamic" [placement]="searchDropdownPosition || 'bottom-right'" class="d-inline-block ads-dropdown" #dropdown>
      <button class="fa fa-times-circle ads-dropdown-btn" (click)="onClearSimpleSearch()"></button>
      <button class="fa fa-filter ads-dropdown-btn" id="dropdownForm1" ngbDropdownToggle aria-hidden="true"></button>
      <div ngbDropdownMenu class="ads-dropdown-content" aria-labelledby="dropdownForm1">
        <div class="p-3">
          <div class="d-flex justify-content-between align-items-center">
            <h6> Advanced Search </h6>
            <button class="ads-clear-filter" (click)="clearSearchFields()" >clear</button>
          </div>
          <div class="ads-dropdown-container" *ngIf="filterEnabled">
          <div class="ads-dropdown-inputs-container">
            <div *ngFor="let col of filteredColumnData; let j = index" [ngStyle]="{'display': (!col.disableFiltering)?'block': 'none'}">

              <div *ngIf="!col?.disableFiltering" class="form-group">
                <label> {{ col?.title }} </label>

              <ng-container [ngSwitch]="col?.type">

                <ng-container *ngSwitchCase="'time'">
                  <ov-suite-input
                    type="time"
                    [(ngModel)]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                    [placeholder]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                    (change)="onFilterChange(col, $event)"
                  ></ov-suite-input>

                </ng-container>

                <ng-container *ngSwitchCase="'date'">
                  <ov-suite-input
                    type="date"
                    [placeholder]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                    (submitOverride)="onFilterChange(col, $event)"
                    (change)="onFilterChange(col, $event)"
                  ></ov-suite-input>
                </ng-container>

                <ng-container *ngSwitchCase="'dropdown'">
                    <ov-suite-input
                      [id]="col?.id + 'row' + col?.id + 'col' + j"
                      type="dropdown"
                      [data]="dropdownData ? dropdownData[col.key.split('.').pop()] : []"
                      [label]="col?.displayKeys"
                      [ngModel]="(filterMapInput[!!col?.filterKey ? col.filterKey : col?.key] | displayKey: col.key)"
                      (ngModelChange)="setValueByKey($event, col?.key)"
                    ></ov-suite-input>
                </ng-container>

                <ng-container *ngSwitchCase="'other'">
                  <ov-suite-input
                    *ngIf="col.id !== 'fast_actions' && col.id !== 'status' && !col.disableFiltering"
                    [(ngModel)]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                    type="text"
                    (change)="onFilterChange(col, $event)"
                  ></ov-suite-input>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ov-suite-input
                    *ngIf="col.id !== 'fast_actions' && col.id !== 'status' && !col.disableFiltering"
                    [(ngModel)]="filterMapInput[!!col?.filterKey ? col.filterKey : col?.key]"
                    type="text"
                    (change)="onFilterChange(col, $event)"
                  ></ov-suite-input>
                </ng-container>
              </ng-container>
              </div>
            </div>
          </div>
          </div>
          <button class="btn btn-clear-filter float-right" (click)="onFilterApply()"> Search &nbsp; &nbsp; <i class="fa fa-search" aria-hidden="true"></i> </button>
        </div>
      </div>
    </div>
  </div>
</div>

