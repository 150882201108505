<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Order Info</a>
    <ng-template ngbNavContent>
      <div class="simple-table">
        <div class="simple-row">
          <div>Priority</div>
          <div>
            <span [class]="'badge '+ orderPriority[action.orderSelected.value?.order?.priority]?.color">
            {{ orderPriority[action.orderSelected.value?.order?.priority]?.text }}
            </span>
            </div>
        </div>
        <div class="simple-row">
          <div>Order ID</div>
          <div>{{ action.orderSelected.value?.order?.orderCode}}</div>
        </div>
        <div class="simple-row">
          <div>Order Placement Date</div>
          <div>{{ action.orderSelected.value?.order?.orderDate }}</div>
        </div>
        <div class="simple-row">
          <div>Delivery Date</div>
          <div>{{ action.orderSelected.value?.order?.fulfilmentDate }}</div>
        </div>
        <div class="simple-row">
          <div>SLA Remaining</div>
          <div> {{ getSLA(action.orderSelected.value?.order?.orderDate, action.orderSelected.value?.order?.fulfilmentDate) }} </div>
        </div>
        <div class="simple-row">
          <div>Capacity 1</div>
          <div>{{action.orderSelected.value?.capacity1}}</div>
        </div>
        <div class="simple-row">
          <div>Capacity 2</div>
          <div>{{action.orderSelected.value?.capacity2}}</div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Customer Details</a>
    <ng-template ngbNavContent>
      <div class="simple-table">
        <div class="simple-row">
          <div>Name</div>
          <div>{{action.orderSelected.value?.order.customer.name}}</div>
        </div>
        <div class="simple-row">
          <div>Code</div>
          <div>{{action.orderSelected.value?.order.customer.customerCode}}</div>
        </div>
        <div class="simple-row">
          <div>Description</div>
          <div>{{action.orderSelected.value?.order.customer.description}}</div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink>Order Items</a>
    <ng-template ngbNavContent>
      <table class="table table-sm">
        <thead>
        <tr>
          <th>SKU Name</th>
          <th>Qty</th>
        </tr>
        </thead>
        <tbody id="order-item-list">

        <tr *ngFor="let orderItem of action.orderSelected.value?.order?.orderItems" class="border">
          <td>{{ orderItem.productSku?.sku }}</td>
          <td>{{ orderItem?.quantity }}</td>
        </tr>

        </tbody>
      </table>

    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
