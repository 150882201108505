import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function commitLoadAllocationGql(): DocumentNode {
  return gql(
    `mutation CommitLoadAllocation($load: LoadAllocationUpdateInput!) {
      commitLoadAllocation(load: $load) { id }
      }`,
  );
}

export function unCommitLoadAllocationGql(): DocumentNode {
  return gql(
    `mutation UnCommitLoadAllocationGql($load: LoadAllocationUpdateInput!) {
      unCommitLoadAllocation(load: $load) { id }
      }`,
  );
}
