<div>
  <form>
    <div class="form-row ">

      <div class="col-3">
        <div class="form-group">
          <label for="repeatOn">Repeat Every:</label>
          <input type="number"
                 id="repeatOn"
                 class="form-control custom-border-styling"
                 (change)="onRepeatChange($event)"
                 [value]="current.repeat"
                 min="1"
                 #repeatEvery
                 name="repeat" placeholder="1">
          <small class="text-danger"
            *ngIf="repeatEvery.validity.rangeUnderflow && repeatEvery.value.length > 0">
          Value should be greater than 0
          </small>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="repeatOn">Occurs:</label>
          <select class="form-control custom-border-styling"
                  name="frequency"
                  (change)="onTypeChange($event)"
                  [(ngModel)]="current.type"
                  id="exampleFormControlSelect1">
            <option value="daily">Day</option>
            <option value="weekly">Week</option>
            <option value="monthly">Month</option>
          </select>
        </div>
      </div>
    </div>
    <div *ngIf="current.type == 'weekly'" class="form-row">
      <div class="form-group">
        <label for="daySelector">Repeat On</label>
        <ov-suite-day-selector
          [(ngModel)]="current.days"
          (ngModelChange)="onDayChange($event)"
          [data]="current.days" name="days"
          id="daySelector" size="xs">
        </ov-suite-day-selector>
      </div>
    </div>

    <div *ngIf="current.type == 'monthly'" class="form-row">
      <div class="form-group">
        <label for="monthSelector">Repeat On</label>
        <select class="form-control custom-border-styling"
                (change)="onMonthlySelectorChange($event)"
                id="monthSelector">
          <option [value]="-1" > Select Day </option>
          <option [value]="0" >Monthly on day {{getMonthlyDay()}} </option>
          <option [value]="1">Monthly on the {{weekNumbers[getMonthlyWeekNumber()]}} {{ getMonthlyDayString() }}
          </option>
        </select>
      </div>
    </div>
<span class="mt-1 p-1"> </span>
    <h6> End </h6>

    <div class="form-row ml-3">
      <div class="col-2">
        <div class="form-check">
          <input class="form-check-input"
                 type="radio"
                 name="exampleRadios"
                 id="exampleRadios1"
                 (change)="setEndType('never')"
                 [checked]="current.endType == 'never'">
          <label class="" for="exampleRadios1">
            Never
          </label>
        </div>
      </div>
      <div class="col">

      </div>
    </div>

    <div class="form-row ml-3 mt-1 d-flex flex-row align-content-center align-items-center">
      <div class="col-1">
        <div class="form-check">
          <input class="form-check-input custom-border-styling" type="radio"
                 name="exampleRadios"
                 id="exampleRadios2"
                 (change)="setEndType('on')"
                 [checked]="current.endType == 'on'">
          <label class="" for="exampleRadios2">
            On
          </label>
        </div>
      </div>
      <div class="col-4">
        <input type="date"
               [disabled]="current.endType!='on'"
               (change)="onEndDateChange($event)"
               [value]="endDate"
               name="endDate"
               class="form-control custom-border-styling" />
      </div>
    </div>

    <div class="form-row ml-3 mt-1 d-flex flex-row align-content-center align-items-center">
      <div class="col-1">
        <div class="form-check">
          <input class="form-check-input custom-border-styling"
                 type="radio"
                 name="endTypeAfterRadio"
                 id="endTypeAfterRadio"
                 (change)="setEndType('after')"
                 [checked]="current.endType == 'after'" />
          <label class="" for="endTypeAfterRadio">
            After
          </label>
        </div>
      </div>
      <div class="col-4">
        <input type="number"
               [disabled]="current.endType!='after'"
               (change)="onAfterOccurrenceChange($event)"
               [value]="endCount"
               name="endCount"
               id="occurrence"
               class="form-control custom-border-styling"
               placeholder="1" />
      </div>
      <div class="col">
        <p class="form-check-label"> occurrences </p>
      </div>
    </div>
  </form>
</div>
