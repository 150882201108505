<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>

  <div class="main-container">
    <div class="bg-white p-2">
  <div class="form-group">
    <ov-suite-input
      [id]="'templateName'"
      [name]="'templateName'"
      [type]="'text'"
      [inputLabel]="'Template Name'"
      [danger]="!hasTemplateName"
      [tooltip]="'Template Name'"
      [(ngModel)]="templateName"
      required="true"
      [placeholder]="'New Vehicle Template Name'"
    >
    </ov-suite-input>
    <small *ngIf="!hasTemplateName"
      class="form-text text-muted text-danger">{{ 'Required' }}</small>
  </div>

  <br />
    <div class="card d-flex align-items-end">
      <div class="pr-1">
        <button type="button"
                class="btn btn-primary"
                (click)="onAddNew(modalContent)">
          <i class="fa fa-plus" aria-hidden="true"></i>
          Add Vehicles</button>
        <div ngbDropdown display="dynamic" placement="left-top" class="d-inline-block">
        <button class="btn btn-outline-primary custom-bulk-dropdown-btn"
                id="templateBulkAction"
                ngbDropdownToggle>Bulk Actions &nbsp; &nbsp;</button>
        <div ngbDropdownMenu aria-labelledby="templateBulkAction">
          <ov-suite-vehicle-table-bulk-action (dataChange)="onBulkDataChanges($event)"
                                              (bulkActionApply)="onApplyBulk()"
                                              [dropdownData]="dropdownData">
           <div class="bg-info"> <button class="btn btn-info"> Testing </button> </div>
          </ov-suite-vehicle-table-bulk-action>
        </div>
          <button type="button" [disabled]="!data" class="btn btn-secondary" (click)="onSave()">Save Template</button>
      </div>
      </div>
    </div>

  <ov-suite-table
    [showIndex]="false"
    [striped]="true"
    [selectableRows]="true"
    [showFiller]="true"
    [data]="pageData"
    [loading]="loading"
    [currentPage]="currentPage"
    [editableRows]="true"
    [dropdownData]="dropdownData"
    [pageChangeEnabled]="true"
    (columnButtonAction)="onButtonAction($event)"
    [columnData]="metadata?.table"
    [ovAutoService]="ovAutoService"
    [totalCount]="totalCount"
    [pageSize]="pageSize"
    [disableUrlParamsFiltering]="true"
    [filterEnabled]="true"
    [hideColumnKeys]="[]"
    [keyStore]="metadata?.name"
    (changePage)="changePage($event)"
    (orderChange)="onSort($event)"
    (filterChange)="onFilter($event)"
    (itemSelect)="onSelectItem($event)"
  ></ov-suite-table>

  <div class="d-flex flex-row justify-content-between align-content-between">
    <button type="button" class="btn btn-outline-primary" (click)="onCancel()">Cancel</button>
  </div>

  <ng-template #modalContent>
    <div class="col-md-12 pt-1">
      <div class="row mt-2 mb-1">
        <div class="col-md-4">
          <h5 class="mb-0 text-primary">Add Vehicles</h5>
        </div>
      </div>
    </div>
    <div class="p-3">
      <ov-suite-template-vehicle-line-selector [vehicleList]="original" (selectedItems)="onAddNewItems($event)">
      </ov-suite-template-vehicle-line-selector>
    </div>

  </ng-template>
    </div>
    </div>
</div>
