import { NgModule } from '@angular/core';
import { OvGenericModule, UiModule } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { ViewPalletsComponent } from './view-pallets/view-pallets.component';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { InventoryLocationCountModel } from '../inventory-product-sku/models/inventory-location-count.model';

// TODO temporary popup eager loading fix
const load = ViewPalletsComponent;

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: InventoryLocationCountModel,
      api: 'warehouselink',
      createTitle: 'Locations',
      route: ['inventory-location'],
      tableTitle: 'Inventory Locations',
      hideAdd: true,
      hideImport: true,
      tabs: [
        { title: 'Products', iconClass: '', path: '/inventory-product' },
        { title: 'Locations', iconClass: '', path: '/inventory-locations' },
      ],
      // topFilters: [
      //   {
      //     type: 'lookup',
      //     key: 'productSku',
      //     placeholder: 'Inventory',
      //     labelKey: 'name',
      //   },
      //   {
      //     type: 'lookup',
      //     key: 'factoryArea',
      //     placeholder: 'Area',
      //     labelKey: 'name',
      //   },
      // ],
      permissionId: WarehousePermission.inventoryProduct,
    }),
    UiModule,
  ],
  declarations: [ViewPalletsComponent],
  providers: [OvAutoService],
})
export class InventoryLocationModule {}
