import { Routes } from '@angular/router';
import { WaveAllocationMainComponent } from './wave-allocation-main/wave-allocation-main.component';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { AuthGuard } from '@ov-suite/authguard-angular';

export const WaveAllocationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: WarehousePermission.waveAllocation } },
        canActivate: [AuthGuard],
        path: '',
        component: WaveAllocationMainComponent,
      },
    ],
  },
];
