import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataPills } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-pills-cell',
  template: `
    <span
      class="badge badge-pill custom-badge-pill ml-1"
      *ngFor="let itemValue of col?.action(item) | slice:0: (showMoreLessLimits[columnIndex] || col.limit);
              let pillIndex = index">
     {{ itemValue }}
    </span>
    <span *ngIf="col.limit < col?.action(item).length"  #lessMore
          class="ml-1 custom-badge-cursor-pointer btn-link"
          (click)="onToggleLessMore(col.limit, columnIndex, col?.action(item).length, lessMore)"
    > show more </span>
  `,
  // styleUrls: ['./basic-table.component.scss'],
})
export class PillsCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataPills<T>;
  @Input() columnIndex: number = 0;
  @Output() itemChange = new EventEmitter<T>();

  showMoreLessLimits: Record<number, number> = {};

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }

  onToggleLessMore(limit: number, index: number, itemsCount: number, event: HTMLElement) {
    if (event.textContent.trim().toLocaleLowerCase() === 'show more') {
      event.textContent = 'show less';
      this.showMoreLessLimits[index] = itemsCount;
    } else {
      this.showMoreLessLimits[index] = limit;
      event.textContent = 'show more';
    }
  }
}
