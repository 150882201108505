import { LoadAllocation } from '@ov-suite/models-warehouse';
import { Order } from '@ov-suite/models-order';
import { EventEmitter } from '@angular/core';

export class VehicleAllocation {
  load: LoadAllocation;

  capacity1?: number;

  capacity2?: number;

  dirty = false;

  slots: Slot[];

  static fromLoadAllocation(load: LoadAllocation): VehicleAllocation {
    const output = new VehicleAllocation();
    output.load = load;
    output.capacity1 = round(load.getVehicle()?.class?.weightLoadAllowed);
    output.capacity2 = round((load.getVehicle()?.class?.length / 100) * (load.getVehicle()?.class?.width / 100) * (load.getVehicle()?.class?.height / 100));
    output.slots = [Slot.fromLoadAllocation(load)];

    return output;
  }

  commit(): void {
    this.load.orders = [];
    const pins = [];
    this.slots.forEach(slot => {
      slot.pins.forEach(pin => {
        pins.push(pin);
        const { capacity1, capacity2, ...allocation } = pin;
        this.load.orders.push(allocation.order);
      });
    });
  }

  removePin(input: OrderAllocation): void {
    this.slots.forEach(slot => {
      slot.pins = slot.pins.filter(pin => pin !== input);
    });
  }
}

export class Slot {
  id: number;

  startTime: string;

  endTime: string;

  pins: OrderAllocation[];

  static fromLoadAllocation(load: LoadAllocation): Slot {
    const output = new Slot();
    output.startTime = load.getVehicle().resource?.startTime;
    output.endTime = load.getVehicle().resource?.endTime;
    output.pins = load.orders.map(o => OrderAllocation.fromOrder(o));

    return output;
  }
}

export class OrderAllocation {
  id: number;

  order: Order;

  capacity1: number;

  capacity2: number;

  static fromOrder(order: Order): OrderAllocation {
    const output = new OrderAllocation();
    output.order = order;
    output.capacity1 = round(order.orderItems.reduce((p, c) => p + (c.productSku?.weight * c.quantity), 0) / 1000);
    output.capacity2 = round(order.orderItems.reduce((p, c) => p +
      (c.productSku?.width / 100) *
      (c.productSku?.length / 100) *
      (c.productSku?.height / 100) *
      c.quantity, 0));

    return output;
  }
}

function round(amount: number, decimal: number = 2): number {
  return Math.round(amount * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export interface TimeSlotHeader {
  name: string;
  value: number;
  left: number;
}

export interface TimeSlot {
  width: number;
  hourSpacing: number;
  totalWidth: number;
  startTime: number;
  endTime: number;
  headings: TimeSlotHeader[];
}

export interface SingleCombo<T> {
  value: T,
  observable: EventEmitter<T>,
}

export interface ListCombo<T> {
  value: T[],
  map?: Record<number, T>,
  all?: T[],
  observable: EventEmitter<T[]>,
}
