import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constructor, GenericHierarchy, getFieldMetadata } from '@ov-suite/ov-metadata';
import { OvAutoService } from '@ov-suite/services';

export interface CardRender<T>{
  title: (item: T)=> string;
  footer: (item: T)=> string;
  body: ((item: T)=> string)[];
  recurring: (item: T)=> boolean;
  keys?: string[];
}

export interface CardTableTemplate {
  id: number | string;
  title: string;
  body: string[];
  footer: string;
  recurring?: boolean;
}

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss']
})
export class CardTableComponent<T extends GenericHierarchyType> implements OnInit{

  data: T[] = [];
  loading: boolean;
  queryFilter: Record<string, string[]>;
  compiledData: CardTableTemplate[] = [];

  @Input() cardRender: CardRender<T>;

  @Input() formClass: Constructor<T>;

  @Input()
  set filter(event) {
    this.queryFilter = event;
    this.getData();
  }

  get filter() {
    return this.queryFilter;
  }

  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() clone = new EventEmitter();

  constructor(private readonly ovAutoService: OvAutoService) {}

  ngOnInit(): void {
    this.getData();
  }

  compileData(items: T[]){
    const t =  items.map(item=> {
        const output: CardTableTemplate = {
          title: this.cardRender.title(item),
          body: this.cardRender.body.map( r=> r(item)),
          footer: this.cardRender.footer(item),
          recurring: this.cardRender.recurring(item),
          id: item.id,
        };
      return output;
    })

    this.compiledData = t;
  }

  getData(): void {
    const metadata = getFieldMetadata<T>(this.formClass);

    const keys: string[] = ['id', ...this.cardRender?.keys];
    this.loading = true;

      this.ovAutoService
        .list({
          search: {},
          filter: this.queryFilter,
          query: {},
          entity: this.formClass,
          specifiedApi: metadata.api,
          limit: 1000,
          offset: null,
          orderDirection: 'DESC',
          orderColumn: 'id',
          keys
        })
        .then(result => {
          this.data = result.data;
          this.compileData(result.data);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
  }

  onAddNewItem() {
    this.add.emit();
  }

  async onRemoveItem(item) {
    const metadata = getFieldMetadata<T>(this.formClass);
    if (confirm('Are you sure you want to delete this?')) {
        await this.ovAutoService.delete(
          this.formClass,
          metadata.api,
          item?.id
        );

      this.getData();
    }
  }

  onEditItem(item) {
    this.edit.emit(item);
  }

  onCopyItem(item) {
    this.clone.emit(item);
  }
}
