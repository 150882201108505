import { Component } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { Router } from '@angular/router';
import { VehicleException } from '@ov-suite/models-warehouse';
import { TableTopTabMenuItem } from '../../../components/table-top-tab-menu/table-top-tab-menu.model';

@Component({
  selector: 'ov-suite-vehicle-exception-list',
  templateUrl: './vehicle-exception-list.component.html',
  styleUrls: ['./vehicle-exception-list.component.scss'],
})
export class VehicleExceptionListComponent {
  formClass = VehicleException;

  filter: Record<string, unknown[]>;

  tabs: HierarchyTab[] = [
    { title: 'Picking Waves', path: '/waves' },
    { title: 'Vehicles', path: '/vehicles' },
    { title: 'Routes', path: '/master-route' },
  ];

  tableTabs: TableTopTabMenuItem[] = [
    { title: 'All', path: '/vehicles', key: 'all' },
    { title: 'Templates', path: '/vehicles/templates', key: 'templates' },
    // { title: 'Archived', path: '/vehicles/archived', key: 'archived' },
    { title: 'Exceptions', path: '/vehicles/exception', key: 'exception' },
    ];

  constructor(public readonly ovAutoService: OvAutoService, private readonly router: Router) {}

  onTabClick(key) {
    const tab = this.tableTabs.find(t => t.key === key);
    this.router.navigate([tab.path]);
  }
}
