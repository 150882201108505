import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoService, DataSources, OvAutoService } from '@ov-suite/services';
import { getCreate, getUpdate } from '@ov-suite/graphql-helpers';
import {
  CompiledFieldData,
  Constructor,
  FieldMetadata,
  GenericHierarchy,
  getCompiledSidebarFieldMetadata,
  getFieldMetadata,
  getTypeMetadata,
  isFieldParamsConstructor,
} from '@ov-suite/ov-metadata';
import { FormComponent } from '../form/form.component';

type GenericHierarchyType = GenericHierarchy;

export interface Status {
  id: number;
  title: string;
  color?: string;
}

@Component({
  selector: 'ov-suite-create-or-edit',
  templateUrl: './create-or-edit.component.html',
  styleUrls: ['./create-or-edit.component.scss'],
})
export class CreateOrEditComponent<T extends GenericHierarchyType> implements OnInit {
  @Input() service: AutoService<T>;

  @Input() ovAutoService: OvAutoService;

  @Input() api: string;

  @Input() formClass: Constructor<T>;

  private _data: T;

  private original: T;

  loading = false;

  set data(item: T) {
    this.original = { ...item };
    this._data = item;
    this.assignDataValues();
  }

  get data() {
    return this._data;
  }

  metadata: FieldMetadata<T>;

  sidebarFields: CompiledFieldData[][] = [];

  @Input() public title: string;

  @Input() public statuses: Status[];

  @Output() public save = new EventEmitter<T>();

  @Output() public delete = new EventEmitter();

  @Output() public edit = new EventEmitter();

  @Input() public dataSources: DataSources<GenericHierarchy> = {};

  @Output() public createSuccess = new EventEmitter<T>();

  @Output() public deleteSuccess = new EventEmitter<T>();

  @Output() public updateSuccess = new EventEmitter<T>();

  @ViewChild('form') form: FormComponent;

  loadMap: Record<string, unknown> = {};

  constructor(public route: ActivatedRoute, @Inject('DEFAULT_API') private readonly defaultApi: string) {}

  ngOnInit() {
    this.metadata = getFieldMetadata(this.formClass);
    this.sidebarFields = getCompiledSidebarFieldMetadata(this.formClass);
    this.assignDataValues();

    this.route.queryParamMap.subscribe(response => {
      if (response.has('_parentId')) {
        this.data = new this.formClass();
        this.data.parent = { id: Number(response.get('_parentId')) };
      } else if (response.has('id')) {
        const id = Number(response.get('id'));
        if (this.service) {
          this.service.get(id).then(item => {
            this.data = item;
          });
        } else {
          this.ovAutoService.get(this.formClass, this.api, id).then(item => {
            this.data = item;
          });
        }
      } else {
        this.data = new this.formClass();
      }
    });
  }

  assignDataValues() {
    if (this.data) {
      this.fieldForEach(data => {
        if (this.data[data.propertyKey]) {
          data.value = this.data[data.propertyKey];
        }
      });
    }
  }

  private fieldForEach(callback: (data: CompiledFieldData) => void) {
    if (this.sidebarFields) {
      this.sidebarFields.forEach(row => {
        row.forEach(column => {
          callback(column);
        });
      });
    }
  }

  private async fieldForEachAsync(callback: (data: CompiledFieldData) => void): Promise<void> {
    if (this.sidebarFields) {
      for (const row of this.sidebarFields) {
        for (const column of row) {
          await callback(column);
        }
      }
    }
  }

  public onDelete(): void {
    // this.delete.emit();
    this.loading = true;
    this.ovAutoService
      .delete(this.formClass, 'shared', this.data.id)
      .then(() => {
        this.delete.emit();
        this.deleteSuccess.emit(this.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onCancel(): void {
    window.history.back();
  }

  public async onSave(): Promise<T | undefined> {
    let proceed = true;
    let formData;

    formData = await this.form.submit(true);
    proceed = !!formData;

    const output = <T>formData ?? this.data ?? new this.formClass();
    let requiredFlag = false;

    const { validateField } = this;

    async function validate(data: CompiledFieldData): Promise<void> {
      if (data.propertyKey) {
        await validateField(data);
        if (data.danger) {
          requiredFlag = true;
        }
        if (!data.readonly) {
          output[data.propertyKey] = data.value;
        }
      }
    }

    await this.fieldForEachAsync(async data => await validate(data));

    if (!requiredFlag && proceed) {
      this.loading = true;
      if (output.id) {
        if (this.service) {
          this.service
            .update(getUpdate(output, this.original))
            .then(res => {
              this.updateSuccess.emit(this.data);
              this.save.emit(res);
            })
            .catch(res => console.error(res))
            .finally(() => (this.loading = false));
        } else {
          this.ovAutoService
            .update({ entity: this.formClass, specifiedApi: this.api, item: getUpdate(output, this.original) })
            .then(res => {
              this.updateSuccess.emit(res as T);
              this.save.emit(res as T);
            })
            .catch(res => console.error(res))
            .finally(() => (this.loading = false));
        }
      } else if (this.service) {
        this.service
          .create(getCreate(output))
          .then(res => {
            this.createSuccess.emit(res);
            this.save.emit(res);
          })
          .catch(res => console.error(res))
          .finally(() => (this.loading = false));
      } else {
        this.ovAutoService
          .create(this.formClass, this.api, getCreate(output))
          .then(res => {
            this.createSuccess.emit(res);
            this.save.emit(res);
          })
          .catch(res => console.error(res))
          .finally(() => (this.loading = false));
      }
      return output;
    }
  }

  validateField = async (data: CompiledFieldData) => {
    let danger = false;
    if (data.validator) {
      const [valid, errorMessage] = await data.validator(data, null);
      if (!valid) {
        danger = true;
      }
      data.currentErrorMessage = errorMessage;
    }
    if (data.required && (data.value ?? '') === '') {
      danger = true;
    }

    data.danger = danger;
  };

  getDataSource(data: CompiledFieldData): unknown {
    if (this.dataSources && this.dataSources[data.propertyKey]) {
      return this.dataSources[data.propertyKey];
    }
    if (this.loadMap[data.propertyKey] && this.loadMap[data.propertyKey] !== 'loading') {
      return this.loadMap[data.propertyKey];
    }
    if (!this.loadMap[data.propertyKey]) {
      if (isFieldParamsConstructor(data)) {
        this.loadMap[data.propertyKey] = 'loading';
        const dataType = data.withQuantity ? data.subType : data.type;
        const { entity: remoteEntity, metadata: remoteMetadata } = getTypeMetadata(dataType);
        let remoteApi = remoteMetadata.api;
        if (!remoteApi || remoteApi === 'shared') {
          remoteApi = this.defaultApi;
        }
        this.ovAutoService
          ?.list({
            entity: remoteEntity,
            specifiedApi: remoteApi,
            limit: 1000,
          })
          .then(response => {
            this.loadMap[data.propertyKey] = response.data;
          });
      } else {
        return null;
      }
    }
  }
}
