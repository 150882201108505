import {
  OVEntity,
  OVField,
  OVSearchable,
  OVTable,
} from '@ov-suite/ov-metadata';
import { TemplateVehicleLine } from './template-vehicle-line.model';

@OVEntity('VehicleTemplate', 'warehouselink')
@OVTable<VehicleTemplate>([
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    title: 'Vehicle Reg & Class',
    type: 'pills',
    disableSorting: true,
    disableFiltering: true,
    keys: ['vehicleLines', 'vehicleLines.id', 'vehicleLines.registration'],
    limit: 3,
    action: (item) => item.vehicleLines.map(e => `${e.vehicle?.registration} - ${e.vehicle?.class?.name}`),
    },
  {
    key: 'createdAt',
    title: 'Date Created',
    type: 'date',
  },

])
export class VehicleTemplate {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: () => [TemplateVehicleLine],
    keys: ['id',
      'vehicle.id',
      'vehicle.code',
      'vehicle.registration',
      'vehicle.class.name',
      'vehicle.class.weightLoadAllowed',
      'vehicle.class.width',
      'vehicle.class.height',
      'vehicle.class.length',
      'startTime', 'endTime',
      'startLocation.id',
      'endLocation.id'
    ],
    title: 'Vehicle Resources',
    placeholder: 'Required',
    required: false,
  })
  vehicleLines?: TemplateVehicleLine[];

  @OVField({
    type: 'date',
    title: 'Created At',
    placeholder: 'Required',
    required: false,
  })
  createdAt: Date;
}
