import { Component, OnInit } from '@angular/core';
import { FormService } from '../form.service';
import { Form } from '../form.model';

@Component({
  selector: 'ov-suite-form',
  templateUrl: './form-builder-list.component.html',
  styleUrls: ['./form-builder-list.component.scss']
})
export class FormBuilderListComponent implements OnInit {
  formClass = Form;

  constructor(public formService: FormService) {}

  ngOnInit(): void {
    this.formService.list({});
  }
}
