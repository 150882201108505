<div class="empty-vehicle-list-container">
<div class="flex-d text-center p-4 justify-content-center align-content-center">

  <div>
    <img src="assets/img/icon_vehicle.svg" alt="wave icon"/>
  </div>

  <div>
    <h4>
      You have no available vehicles
    </h4>
    <p>
      Why don't you start by clicking below and onboard vehicles.
    </p>
  </div>

  <div>
    <button (click)="OpenAdminLink()" type="button" class="btn btn-outline-dark">
       Add Vehicles <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
    </button>
  </div>
</div>
</div>
