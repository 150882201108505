<div class="main-content">
  <div class="main-container">
  <div class="card">
    <div class="card-body">
        <h5 class="card-title">
          {{ id != null ? 'Edit': 'Create' }} Vehicle Exception
        </h5>
        <hr />
      <ov-suite-form
        #form
        [formClass]="formClass"
        [data]="data"
        (save)="onSave($event)"
        [showSave]="true"
        [dataSources]="dataSources"
        [sideBarMetadata]="null"
      ></ov-suite-form>
    </div>

  </div>
  </div>
</div>
