<div class="d-flex flex-row justify-content-between align-content-between pr-3 pl-3 pt-3 pb-0">
  <div></div>
  <h6 id="modal-basic-title">Set Triggers</h6>
  <div type="button" aria-label="Close" (click)="close()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </div>
</div>
<hr />
<div class="custom-modal-body">
  <h5>Inventory Level Triggers</h5>
  <div>
    <ngb-alert [type]="triggerStatus">
      <div class="alert-text">
        Current Inventory Count : <strong> {{ inventoryProductSku?.inventoryLevelStatus?.quantity }} </strong>
      </div>
    </ngb-alert>

    <ov-suite-input
      id="amberTriggerValue"
      type="number"
      [tooltip]="'Amber Trigger'"
      [(ngModel)]="inventoryThreshold.amberTriggerValue"
      (change)="onTriggerChange($event)"
      [title]="'Amber Trigger Value'"
    >
    </ov-suite-input>

    <ov-suite-input
      id="redTriggerValue"
      type="number"
      [tooltip]="'Red Trigger'"
      (change)="onTriggerChange($event)"
      [(ngModel)]="inventoryThreshold.redTriggerValue"
      [title]="'Red Trigger Value'"
    >
    </ov-suite-input>
    <span *ngIf="error" class="text-danger pt-1"> {{ error }} </span>
  </div>

  <button [disabled]="loading" (click)="onSave()" class="btn btn-secondary">
    <span
      *ngIf="loading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <span *ngIf="loading" class="sr-only">Loading...</span>
    <span *ngIf="!loading" class="">SAVE</span>
  </button>
</div>
<div class="modal-footer">
  <div *ngIf="!loading && onSuccess" class="trigger-save-message">
    <i class="text-success fa fa-check fa-x2" aria-hidden="true"> </i>
    &nbsp;
    <div class="text-success text-center">Saved</div>
  </div>
</div>
