import { Injectable } from '@angular/core';
import moment from 'moment';
import { LoadAllocationDataService } from './load-allocation.data.service';
import { TimeSlot, VehicleAllocation } from './load-allocation.interface';

/**
 * This service is used for shared logic between the vehicle and vehicle-wave components.
 * It mostly handles layout logic for the planner
 */

const spacing = 60;
const width = 50;

@Injectable()
export class LoadAllocationVehicleLayoutService {
  dateDisplay: string;

  timeSlot: TimeSlot = {
    width,
    hourSpacing: spacing,
    totalWidth: spacing * 24 + width,
    startTime: 0,
    endTime: 24,
    headings: [],
  };

  constructor(public dataService: LoadAllocationDataService) {
    this.onInit();
  }

  onInit() {
    this.dataService.vehicleAllocation.observable.subscribe(vehicleAllocations => {
      this.updateVehicleAllocationsDisplay(vehicleAllocations);
    });

    this.setDateDisplay();
  }

  updateVehicleAllocationsDisplay(vehicleAllocations: VehicleAllocation[]): void {
    let startTime: number;
    let endTime: number;

    vehicleAllocations.forEach((vehicle) => {
      vehicle.slots.forEach((slot) => {
        const startCompare = Number(slot.startTime.split(':')[0]);
        let endCompare = Number(slot.endTime.split(':')[0]);

        if (startTime === undefined) startTime = startCompare;
        if (endTime === undefined) endTime = endCompare;

        if (startCompare > endCompare) {
          endCompare += 24;
        }

        if (startTime > startCompare) startTime = startCompare;
        if (endTime < endCompare) endTime = endCompare;
      });
    });

    if (startTime !== undefined) {
      this.setRange(`${startTime.toString().padStart(2, '0')}:00`, `${endTime.toString().padStart(2, '0')}:00`);
    }
  }

  setDateDisplay() {
    moment.updateLocale('en', {
      calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: 'L',
      },
    });
    const momentDate = moment(this.dataService.date.value);
    const dateString = momentDate.format('DD/MM/yyyy');
    const after = momentDate.isSameOrAfter(moment().subtract(7, 'days'));
    const before = momentDate.isSameOrBefore(moment().add(7, 'days'));
    if (after && before) {
      this.dateDisplay = `${momentDate.calendar()}, ${dateString}`;
    } else {
      this.dateDisplay = dateString;
    }
  }

  getXPosition(time: string | number): number {
    let num: number;
    if (typeof time === 'string') {
      const [numStr, decStr] = time.split(':');
      num = Number(numStr) + (Number(decStr) / 60);
    } else {
      num = time;
    }

    return (num - this.timeSlot.startTime) * this.timeSlot.hourSpacing + (this.timeSlot.width / 2);
  }

  getTitleXPosition(time: string | number): number {
    return this.getXPosition(time) - (this.timeSlot.width / 2);
  }

  getLeftXPosition(time: string): number {
    return this.getXPosition(time);
  }

  getRightXPosition(startTime: string, endTime: string): number {
    const [numStr, decStr] = endTime.split(':');
    let num = Number(numStr) + (Number(decStr) / 60);
    if (startTime > endTime) {
      num += 24;
    }
    return this.timeSlot.totalWidth - this.getXPosition(num);
  }

  zoomIn() {
    if (this.timeSlot.hourSpacing < 200) {
      const timeSlot = { ...this.timeSlot };
      timeSlot.hourSpacing += 10;
      timeSlot.totalWidth = timeSlot.hourSpacing * (this.timeSlot.headings.length - 1) + timeSlot.width;
      this.timeSlot = timeSlot;
      this.zoomUpdateHeader();
    }
  }

  zoomOut() {
    if (this.timeSlot.hourSpacing > 40) {
      const timeSlot = { ...this.timeSlot };
      timeSlot.hourSpacing -= 10;
      timeSlot.totalWidth = timeSlot.hourSpacing * (this.timeSlot.headings.length - 1) + timeSlot.width;
      this.timeSlot = timeSlot;
      this.zoomUpdateHeader();
    }
  }

  zoomUpdateHeader() {
    this.timeSlot.headings.forEach((heading) => {
      heading.left = this.getTitleXPosition(heading.value);
    });
  }

  setRange(startTime: string, endTime: string): void {
    const [sNumString] = startTime.split(':');
    const [eNumString] = endTime.split(':');
    let sNum = Number(sNumString);
    let eNum = Number(eNumString);

    if (sNum > eNum) {
      eNum += 24;
    }

    sNum -= 1;
    eNum += 1;

    this.timeSlot.totalWidth = this.timeSlot.hourSpacing * (eNum - sNum) + this.timeSlot.width;
    this.timeSlot.headings = [];
    this.timeSlot.startTime = sNum;

    for (let i = sNum; i <= eNum; i += 1) {
      let num = i;

      if (i < 0) {
        num = 24 + i;
      } else if (i > 24) {
        num = i - 24;
      } else if (i === 24) {
        num = 0;
      }
      const time = `${num.toString().padStart(2, '0')}:00`;

      const slot = {
        name: time,
        left: this.getTitleXPosition(i),
        value: i,
      };
      this.timeSlot.headings.push(slot);
    }
  }
}
