import { Component, OnInit } from '@angular/core';
import { FieldMetadata, getFieldMetadata, OVTag, PageReturn } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryLocationPallet } from '@ov-suite/models-warehouse';
import { OvAutoService } from '@ov-suite/services';

@OVTag('get-pallet-view-popup', InventoryLocationPallet)
@Component({
  selector: 'ov-suite-inventory-view-more-modal',
  templateUrl: './view-pallets.component.html',
})
export class ViewPalletsComponent implements OnInit {
  page = 0;

  pageSize = 10;

  inventoryProductSku: {};

  data: PageReturn<InventoryLocationPallet>;

  // Class - Required
  formClass = InventoryLocationPallet;

  metadata: FieldMetadata<InventoryLocationPallet>;

  constructor(protected modalService: NgbModal, private readonly autoService: OvAutoService) {
    this.metadata = getFieldMetadata<InventoryLocationPallet>(this.formClass);
  }

  ngOnInit(): void {
    this.getData();
  }

  changePage(page: number): void {
    this.page = page;
    this.getData();
  }

  getData(): void {
    this.autoService
      .list({ entity: InventoryLocationPallet, query: { 'inventoryLocation.id': [this.inventoryProductSku['id']] } })
      .then(result => {
        this.data = result;
      });
  }

  close = () => {
    this.modalService.dismissAll();
  };
}
