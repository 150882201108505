<div class="empty-wave-container">
<div class="flex-d text-center p-4 justify-content-center align-content-center">

  <div>
    <img src="assets/img/icon_wave.svg" alt="wave icon"/>
  </div>

  <div>
    <h4>
      You have no available picking waves
    </h4>
    <p>
      Why don't you start by clicking below and set up your first picking wave.
    </p>
  </div>

  <div>
    <button [routerLink]="['/waves/add']" type="button" class="btn btn-outline-dark">
       New Picking Wave <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
    </button>
  </div>
</div>
</div>
