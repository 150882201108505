import 'reflect-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';

export interface HasId {
  id: number | string;
}

export type Constructor<T = unknown> = new (...args: unknown[]) => T;

export interface GenericHierarchyStatus {
  id: number;
  name: string;
  color?: string;
}
export type ConstructorReturn<T extends HasId = HasId> = () => Constructor<T> | string;
export type ConstructorReturnArray<T extends HasId = HasId> = () => Constructor<T>[] | string[];

export interface GenericHierarchy<T extends GenericHierarchy<T> = { id: number | string; name?: string }> {
  id: number | string;
  name?: string;
  parent?: T;
  children?: T[];
  path?: string;
  status?: GenericHierarchyStatus;
}

export interface FieldMetadata<T = unknown> {
  name: string;
  plural: string;
  api: string;
  tableName: string;
  fields: FieldData[];
  format: string[][];
  sideBarFormat: string[][];
  table: ColumnData<T>[];
}

export interface ParentLookup {
  propertyKey: string;
  parentIndex: number;
  childIndex: number;
  format: string;
  foundIndicator: string;
  edited: boolean;
  concatenationProperties: ConcatenationPropertyKeys[];
  concatenationPropertyNames: string[];
  autoGenerated: boolean;
}

export interface Concatenation {
  propertyKeys: ConcatenationPropertyKeys[];
  format: string;
}

export interface ConcatenationPropertyKeys {
  propertyKey: string;
  innerPropertyKey?: string;
  subKey?: string;

  childIndex?: number; // exclusive to implementation
  parentIndex?: number; // exclusive to implementation
}

const defaultFieldMetadata: FieldMetadata = {
  name: null,
  plural: null,
  api: null,
  tableName: null,
  fields: [],
  format: [],
  sideBarFormat: [],
  table: [],
};

export type FieldParams = FieldParamsBase | FieldParamsNumber | FieldParamsConstructor | (FieldParamsBase & FieldParamsCodePreview);

export type FieldParamsQuery = (key: string) => unknown;

export type FieldType =
  | 'string'
  | 'number'
  | 'numbers'
  | 'title'
  | 'password'
  | 'map'
  | 'image'
  | ConstructorReturn
  | ConstructorReturnArray
  | 'blank'
  | 'permission'
  | 'boolean'
  | 'json'
  | 'date'
  | 'date-time'
  | 'time'
  | 'button'
  | 'recurrence'
  | 'code-preview';

export interface FieldParamsBase {
  type: FieldType;
  bulkDependency?: string;
  bulkIgnore?: boolean;
  exportExcludedKeys?: string[];
  exportUnique?: boolean;
  looselyCoupled?: boolean; // Set to true when doing cross api joins.
  /**
   * @deprecated use SelectionType instead
   */
  dropdown?: boolean;
  title?: string;
  placeholder?: string;
  tooltip?: string;
  errorMessage?: string; // NYI
  validator?: (data: unknown, dataQuery: FieldParamsQuery) => [boolean, string] | Promise<[boolean, string]>;
  generator?: (data: unknown, dataQuery: FieldParamsQuery) => unknown;
  action?: (data: CompiledFieldData[][]) => void;
  hidden?: (data: CompiledFieldData[]) => boolean;
  classes?: string[]; // NYI
  labelClasses?: string[]; // NYI

  sidebar?: boolean; // false // Main page or sidebar item

  required?: boolean | ((data: CompiledFieldData, dataQuery?: FieldParamsQuery) => boolean); // false
  generated?: boolean; // false // Not inputted at creation
  readonly?: boolean; // false // Not editable during update
  unnecessary?: boolean; // false // Does not get returned on Queries
  editable?: boolean;
  idOnly?: boolean;
  apis?: string[]; // Relevant Apis used for Graphql
  concatenation?: Concatenation;
}

interface FieldParamsCodePreview {
  type: 'code-preview';
  unnecessary?: boolean;
  codeKey: string;
}

export const fieldParamsDefaults: Required<FieldParams> = {
  type: 'string',
  bulkDependency: '',
  bulkIgnore: false,
  exportExcludedKeys: [],
  exportUnique: false,
  looselyCoupled: false,
  dropdown: false,
  title: '',
  placeholder: '',
  tooltip: '',
  errorMessage: '',
  validator: () => [true, ''],
  generator: null,
  action: () => {},
  hidden: () => false,
  classes: [],
  labelClasses: [],
  sidebar: false,
  required: false,
  generated: false,
  readonly: false,
  unnecessary: false,
  idOnly: false,
  apis: [],
  concatenation: null,
  editable: true,
};

export function isFieldParamsConstructor(field: FieldParams): field is FieldParamsConstructor {
  return typeof field.type === 'function';
}

export interface FieldParamsConstructor extends FieldParamsBase {
  type: ConstructorReturn | ConstructorReturnArray;
  subType?: ConstructorReturn | ConstructorReturnArray; // used when withQuantity = true;
  selectionType?: 'simple' | 'single' | 'multiple'; // Simple is default dropdown, others are tree select tools
  withQuantity?: boolean;
  quantityKey?: string;
  nameColumnLabel?: string;
  nameColumnKey?: string;
  flat?: boolean;
  keys?: string[]; //  get View properties
  dropdownKeys?: string[]; //  used to specify names in the simple dropdown
  dropdownLimit?: number;
}

export const fieldParamsConstructorDefaults: Required<FieldParamsConstructor> = {
  ...fieldParamsDefaults,
  type: () =>
    class Default {
      id: number;
    },
  subType: null,
  selectionType: 'simple',
  withQuantity: false,
  quantityKey: '',
  nameColumnLabel: 'Name',
  nameColumnKey: 'name',
  flat: false,
  keys: [],
  dropdownKeys: ['name'],
  dropdownLimit: 100,
};

interface FieldParamsNumber extends FieldParamsBase {
  type: 'number';
  min?: number; // NYI
  max?: number; // NYI
  step?: string;
}

export const fieldParamsNumberDefaults: Required<FieldParamsNumber> = {
  ...fieldParamsDefaults,
  type: 'number',
  min: 0,
  max: 0,
  step: '1',
};

export type CompiledFieldData<T = unknown> = FieldData & {
  value: T;
  currentErrorMessage?: string;
  danger?: boolean;
};

export type FieldData = Required<FieldParams> & { propertyKey: string };

export function getFieldMetadata<T>(constructor: Constructor<T>): FieldMetadata<T> {
  return Reflect.getMetadata('OVEntity', constructor) ?? defaultFieldMetadata;
}

export function getCompiledFieldMetadata<T>(constructor: Constructor<T>): CompiledFieldData[][] {
  const allMetadata: FieldMetadata<T> = getFieldMetadata(constructor);
  return allMetadata.format.map(row =>
    row.map(item => {
      if (item.startsWith('#')) {
        return {
          ...getFieldParamsWithDefaults({
            title: item.slice(1),
            type: 'title',
          }),
          propertyKey: item,
          value: null,
        };
      }

      const found = allMetadata.fields.find(field => field.propertyKey === item);

      if (found) {
        return {
          ...found,
          value: null,
          danger: false,
        };
      }
      return {
        ...getFieldParamsWithDefaults({ type: 'blank' }),
        propertyKey: item,
        value: null,
      };
    }),
  );
}
export function getCompiledSidebarFieldMetadata<T>(constructor: Constructor<T>): CompiledFieldData[][] {
  const allMetadata: FieldMetadata<T> = getFieldMetadata(constructor);
  return allMetadata.sideBarFormat
    ? allMetadata.sideBarFormat.map(row =>
        row.map(item => {
          if (item.startsWith('#')) {
            return {
              ...getFieldParamsWithDefaults({
                title: item.slice(1),
                type: 'title',
              }),
              propertyKey: item,
              value: null,
            };
          }

          const found = allMetadata.fields.find(field => field.propertyKey === item);

          if (found) {
            return {
              ...found,
              value: null,
              danger: false,
            };
          }
          return {
            ...getFieldParamsWithDefaults({ type: 'blank' }),
            propertyKey: item,
            value: null,
          };
        }),
      )
    : null;
}

export type OvApi = 'idmlink' | 'adminlink' | 'warehouselink' | 'yardlink' | 'ceramic-portal' | 'executionlink' | 'shared';

interface OVEntityParams {
  name: string;
  api?: OvApi;
  plural?: string;
  tableName?: string;
}

// Decorators
export function OVEntity<T>(params: OVEntityParams): ClassDecorator;
export function OVEntity<T>(name: string, api?: OvApi, plural?: string): ClassDecorator;
export function OVEntity<T>(...parameters) {
  return <U = unknown>(constructor: U): U | void => {
    let name: string;
    let api: string;
    let plural: string;
    let tableName: string;
    if (typeof parameters[0] === 'string') {
      [name, api, plural = `${name}s`, tableName = name.toLowerCase()] = parameters;
    } else {
      [{ name, api, plural = `${name}s`, tableName = name.toLowerCase() }] = parameters;
    }
    console.assert(!!name, 'Name Missing on Model', { constructor });
    // console.assert(!!api, 'Api Missing on Model', { constructor });
    if (!api) {
      api = 'shared';
    }
    const allMetadata: FieldMetadata = Reflect.getMetadata('OVEntity', constructor) ?? defaultFieldMetadata;
    const allEntities: Record<string, U> = Reflect.getMetadata('entities', OVEntity) ?? {};

    allMetadata.name = name;
    allMetadata.plural = plural;
    allMetadata.api = api;
    allMetadata.tableName = tableName;
    allEntities[name] = constructor;

    Reflect.defineMetadata('OVEntity', allMetadata, constructor);
    Reflect.defineMetadata('entities', allEntities, OVEntity);
  };
}

export function getOVEntities(): Record<string, Constructor<HasId>> {
  return Reflect.getMetadata('entities', OVEntity) ?? {};
}

export function OVForm<T>(params: string[][]): ClassDecorator {
  return <U = unknown>(constructor: U): U | void => {
    const allMetadata: FieldMetadata = Reflect.getMetadata('OVEntity', constructor) ?? defaultFieldMetadata;

    allMetadata.format = params;

    Reflect.defineMetadata('OVEntity', allMetadata, constructor);
  };
}
export function OVSidebar<T>(params: string[][]): ClassDecorator {
  return <U = unknown>(constructor: U): U | void => {
    const allMetadata: FieldMetadata = Reflect.getMetadata('OVEntity', constructor) ?? defaultFieldMetadata;

    allMetadata.sideBarFormat = params;

    Reflect.defineMetadata('OVEntity', allMetadata, constructor);
  };
}

export function OVTable<T = unknown>(params: ColumnData<T>[]): ClassDecorator {
  return <U = Constructor<T>>(constructor: U): U | void => {
    const allMetadata: FieldMetadata<T> = Reflect.getMetadata('OVEntity', constructor) ?? defaultFieldMetadata;

    const idList = [];

    allMetadata.table = params.map(col => {
      console.assert(!!col.title || !!col.id, 'Columns without Titles must have an ID set', { constructor, col });
      let id = col.id ?? col.title.replace(/\s/gm, '_').toLowerCase();

      if (id === 'status') {
        id = 'status_custom';
      }
      if (id === 'fast_actions') {
        id = 'fast_actions_custom';
      }
      console.assert(!idList.includes(id), 'Table does not have unique ID', {
        constructor,
        id,
      });

      idList.push(id);

      let orderKey: string;

      if (col.type === 'buttons' || col.type === 'other' || col.type === 'column' || col.type === 'pills') {
        console.assert(!!col.keys, 'Table Data of type \'other\' or \'buttons\' or \'column\' or \'pills\' must have \'keys\' declared', constructor);
        orderKey = col.orderKey ?? col.keys?.length ? col.keys[0] : '';
      } else {
        orderKey = col.orderKey ?? (col.key as string);
      }

      return {
        ...col,
        id,
        orderKey,
      };
    });

    Reflect.defineMetadata('OVEntity', allMetadata, constructor);
  };
}
export function OVField(params: FieldParams) {
  return (target: Object, propertyKey: string) => {
    const { constructor } = target;
    const allMetadata: FieldMetadata = Reflect.getMetadata('OVEntity', constructor) ?? { fields: [], format: [], table: [] };

    // if (propertyKey === 'parent' && params.type !== 'string') {
    //   const copy = { ...params };
    //   console.log({copy}, typeof params.type);
    // }

    const data: FieldData = {
      ...getFieldParamsWithDefaults(params),
      propertyKey,
    };

    allMetadata.fields.push(data);

    Reflect.defineMetadata('OVEntity', allMetadata, constructor);
  };
}

export function mapToClass<T = unknown, U = unknown>(constructor: Constructor<T>, input: U): T {
  const metadata = getFieldMetadata(constructor);
  const output = new constructor();
  for (const field of metadata.fields) {
    let value = input[field.propertyKey];
    if (field.type === 'json' && value && typeof value === 'string') {
      value = JSON.parse(value);
    }
    if (typeof field.type !== 'string' && value) {
      const type = getTypeMetadata(field.type);
      if (Array.isArray(value)) {
        value = value.map(i => mapToClass(type.entity, i));
      } else {
        value = mapToClass(type.entity, value);
      }
    }
    output[field.propertyKey] = value;
  }
  return output;
}

export function getFieldParamsWithDefaults(params: FieldParams): Required<FieldParams> {
  if (params.type === 'number') {
    return mergeDefaults(params, fieldParamsNumberDefaults);
  }
  if (typeof params.type === 'function' && typeof params.type() === 'function') {
    return mergeDefaults(params, fieldParamsConstructorDefaults);
  }
  return mergeDefaults(params, fieldParamsDefaults);
}

function mergeDefaults(params: FieldParams, defaults: Required<FieldParams>): Required<FieldParams> {
  Object.keys(defaults).forEach(key => {
    params[key] = params[key] ?? defaults[key];
  });
  return params as Required<FieldParams>;
}

export function getTypeMetadata<T extends HasId = HasId>(
  type: ConstructorReturn<T> | ConstructorReturnArray<T>,
): { metadata: FieldMetadata<T>; entity: Constructor<T> } {
  const value = type();
  const [entityOrString] = Array.isArray(value) ? value : [value];
  if (typeof entityOrString === 'string') {
    const allEntities = getOVEntities();
    const entity = allEntities[entityOrString] as Constructor<T>;
    if (entity) {
      return { entity, metadata: getFieldMetadata(entity) };
    }
    throw new Error(`entity '${entityOrString}' not found`);
  } else {
    return { entity: entityOrString, metadata: getFieldMetadata(entityOrString) };
  }
}
