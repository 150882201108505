
export function ovGetValueByObjectKey(object: {}, key: string): unknown {
  if (key.includes('.')) {
    return key.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, object || self);
  }
  return object[key];
}

 export function ovFilterByKeyValue<T>(data: T[], key, value): T[] {
  return data.filter(item => {
    const filterValue = ovGetValueByObjectKey(item, key);
    return String(filterValue).toLowerCase().includes(String(value).toLowerCase());
  });
}

export function ovSortByValue<T>(data: T[], sortColumnEvent: unknown): T[]{
  return data.sort((aObject, bObject) => {

    let aValue = ovGetValueByObjectKey(aObject, sortColumnEvent['data']['orderKey']);
    let bValue = ovGetValueByObjectKey(bObject, sortColumnEvent['data']['orderKey']);
    // for now will compare the ids, should be values though
    if(typeof aValue === "object"){
      aValue = ovGetValueByObjectKey(aValue, 'id');
      bValue = ovGetValueByObjectKey(bValue, 'id');
    }

    if(sortColumnEvent['direction']==='ASC'){
      if (aValue < bValue) {
        return -1;
      }

      if (bValue > aValue) {
        return 1;
      }
    }

    if(sortColumnEvent['direction']==='DESC'){
      if (aValue > bValue) {
        return -1;
      }

      if (bValue < aValue) {
        return 1;
      }
    }

    return 0;
  });

}
