import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { Feature } from './feature.model';

@OVEntity('App', 'idmlink')
export class App {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: () => [Feature], selectionType: 'multiple', flat: true })
  features: Feature[];
}

export class AppModel {
  id: number;

  name: string;

  feature: Feature[];
}
