import { Component, OnInit } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { FieldMetadata, getFieldMetadata } from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateVehicleLine, VehicleResource, VehicleTemplate } from '@ov-suite/models-warehouse';
import { Factory } from '@ov-suite/models-admin';
import { VehicleTemplateService } from '../vehicle-template.service';
import { ovFilterByKeyValue, ovSortByValue } from '../../../helpers/table.helpers';

@Component({
  selector: 'ov-suite-vehicle-template-create-or-edit',
  templateUrl: './vehicle-template-create-or-edit.component.html',
  styleUrls: ['./vehicle-template-create-or-edit.component.scss'],
})
export class VehicleTemplateCreateOrEditComponent implements OnInit {
  id: number;

  templateName: string;

  data: TemplateVehicleLine[] = [];

  pageData: TemplateVehicleLine[] = [];

  original: TemplateVehicleLine[] = [];

  columnData: ColumnData<TemplateVehicleLine>[];

  dropdownData: Record<string, unknown>;

  loading: boolean;

  refreshThis: number;

  totalCount = 0;

  pageSize = 10;

  currentPage = 0;

  selected: TemplateVehicleLine[];

  bulkChanges: Record<string, unknown>;

  hasTemplateName = true;

  formClass = TemplateVehicleLine;

  metadata: FieldMetadata<TemplateVehicleLine>;

  filter: Record<string, unknown[]>;

  currentData: TemplateVehicleLine[];

  tabs: HierarchyTab[] = [
    { title: 'Picking Waves', path: '/waves' },
    { title: 'Vehicles', path: '/vehicles' },
  ];

  constructor(public readonly ovAutoService: OvAutoService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              public readonly modalService: NgbModal,
              private readonly vehicleTemplateService: VehicleTemplateService) {}

    ngOnInit(): void {
      this.metadata = getFieldMetadata<TemplateVehicleLine>(this.formClass);
      this.columnData = this.metadata.table;

      this.route.queryParamMap.subscribe(response => {
          if (response.has('id')) {
            this.id = Number(response.get('id'));
            this.ovAutoService.get<VehicleTemplate>(VehicleTemplate, '', this.id).then(res => {
              this.templateName = res.name;
              this.data = res.vehicleLines;
              this.original = res.vehicleLines;
              this.totalCount = res.vehicleLines.length;
              this.onPaginate();
            });
          } else {
            this.data = [];
          }
      });

      this.ovAutoService.list({
        search: {},
        filter: {},
        query: {},
        entity: Factory,
        specifiedApi: null,
        limit: 1000,
        offset: null,
        orderDirection: 'DESC',
        orderColumn: 'id',
        keys: ['id', 'name'],
      })
        .then(result => {
          this.dropdownData = {
            endLocation: result.data,
            startLocation: result.data,
          };
        }).catch((err) => console.error(err));
  }

  onAddNew(content) {
    this.modalService.open(content, {
      size: 'xl',
    });
  }

  onButtonAction(event) {
    this.data = this.data.filter(vehicle => vehicle.id !== event.id);
    this.original = this.original.filter(vehicle => vehicle.id !== event.id);
    this.onPaginate();
  }

  onAddNewItems(event: VehicleResource[]) {
    const selectedVehicles: TemplateVehicleLine[] = [];
    event.forEach(vehicle => {
      selectedVehicles.push({
        startTime: vehicle.startTime,
        endTime: vehicle.endTime,
        startLocation: vehicle.startLocation,
        endLocation: vehicle.endLocation,
        vehicle: vehicle.vehicle,
        id: vehicle.id,
      });
    });

    this.data = [...this.data, ...selectedVehicles];
    this.original = [...this.original, ...selectedVehicles];
    this.onPaginate();
  }

  changePage(event) {
    this.currentPage = event;
    this.onPaginate();
  }

  onPaginate() {
    const begin = ((this.currentPage) * this.pageSize);
    const end = begin + this.pageSize;
    this.pageData = this.data.slice(begin, end);
  }

  onFilter(event) {
    const { filter } = event;
    // will use this is the key
    let _original = JSON.parse(JSON.stringify(this.original));

    for (const key in filter) {
      if (key && String(key) !== 'id') {
        const [filterValue] = filter[key];
        _original = ovFilterByKeyValue(_original, key.toString(), filterValue);
      }
    }

    this.data = _original;
    this.onPaginate();
  }

  onSort(event) {
    this.data = ovSortByValue(this.data, event);
    this.onPaginate();
  }

  transformData(data: TemplateVehicleLine[]): unknown[] {
    const changedData = [];
    data.forEach(row => {
      const newObject = {
        endLocationId: row.endLocation?.id,
        startLocationId: row?.startLocation?.id,
        startTime: row.startTime,
        endTime: row.endTime,
        id: row.id,
        vehicleId: row?.vehicle?.id,
      };
      changedData.push(newObject);
    });

    return changedData;
  }

  onSave() {
    const transformed = this.transformData(this.data);
    if (!this.templateName) {
      this.hasTemplateName = false;
      return;
    }
    this.hasTemplateName = true;

    if (this.id) {
      this.vehicleTemplateService.updateTemplate(this.templateName, this.id, transformed).then(res => {
        if (res.id) {
          this.currentData = [];
          this.router.navigate(['/vehicles/templates']);
        }
      }).catch(err => {
        console.log({ err });
      });
    } else {
      this.vehicleTemplateService.createTemplate(this.templateName, transformed).then(res => {
        if (res.id) {
          this.currentData = [];
          this.router.navigate(['/vehicles/templates']);
        }
      }).catch(err => {
        console.log({ err });
      });
    }
  }

  onBulkDataChanges(changes) {
    this.bulkChanges = changes;
  }

  onApplyBulk() {
    const recent = JSON.parse(JSON.stringify(this.data));
    this.selected.forEach(vehicle => {
      const _vehicle = recent.find(v => v?.vehicle?.id === vehicle?.vehicle?.id);
      if (_vehicle) {
        _vehicle['startTime'] = this.bulkChanges['startTime'] ? this.bulkChanges['startTime'] as string : _vehicle['startTime'];
        _vehicle['endTime'] = this.bulkChanges['endTime'] ? this.bulkChanges['endTime'] as string : _vehicle['endTime'];
        _vehicle['startLocation'] = this.bulkChanges['startLocation'] ? this.bulkChanges['startLocation'] as Factory : _vehicle['startLocation'];
        _vehicle['endLocation'] = this.bulkChanges['endLocation'] ? this.bulkChanges['endLocation'] as Factory : _vehicle['endLocation'];
        _vehicle['isSelected'] = false;
      }
    });
    this.data = [...recent];
    this.onPaginate();
  }

  onCancel() {
    this.router.navigate(['/vehicles/templates']);
  }

  onSelectItem(event) {
    this.selected = event;
  }
}
