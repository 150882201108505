import { Component, Inject, OnInit, Input } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { ApiOptions } from '@ov-suite/ui';
import { environment } from '@ov-suite/helpers-shared';
import { Domain } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.scss'],
})
export class UserProfileWidgetComponent implements OnInit {
  userAvatar: string;

  userFullName = 'Loading';

  @Input() open: boolean;

  @Input() hover: boolean;

  domains: Domain[] = [];

  constructor(@Inject('OPTIONS') private readonly options: ApiOptions, private readonly ovAutoService: OvAutoService) {}

  ngOnInit() {
    Auth.currentUserInfo().then(user => {
      if (user) {
        this.userAvatar = user.attributes.picture;
        this.userFullName =
          user.attributes['name'] || user.attributes['family_name']
            ? `${user.attributes['name']} ${user.attributes['family_name']}`
            : 'Not Available';
      } else {
        this.userFullName = 'Not Available';
      }

      this.getDomains();
    });
  }

  navigate() {
    window.location.assign(`${environment.webUrl.idm}/user`);
  }

  getDomains() {
    Auth.currentSession().then(session => {
      const currentUserTokenInfo = session.getIdToken().decodePayload();

      const domainsList: number[] = JSON.parse(currentUserTokenInfo['lambdaDomainIds']);
      if (domainsList?.length) {
        this.ovAutoService
          .getAll(Domain, domainsList, 'adminlink') // todo: Replicate Domains to all APIs and make this 'shared'
          .then(result => {
            const raw = result.sort((a, b) => a.name.localeCompare(b.name));
            const parents = raw.filter(r => r.parent === null);
            this.domains = this.buildDomainTree(parents, raw);
          });
      }
    });
  }

  buildDomainTree(parents, list) {
    parents.forEach((p, pi) => {
      p.children.forEach((c, ci) => {
        const children = list.filter(l => l.id === c.id);

        if (children.length > 0) {
          [parents[pi].children[ci]] = children;
        }
      });
    });

    return parents;
  }
}
