<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
  <div class="main-container">
  <ov-suite-table-top-tab-menu [tabs]="tableTabs" (tabClick)="onTabClick($event)">
  </ov-suite-table-top-tab-menu>

    <ov-suite-hierarchy-table
      [title]="'Vehicle Resources'"
      [formClass]="formClass"
      [ovAutoService]="ovAutoService"
      [hasBulkUpload]="false"
      [excludeColumns]="4"
      [showTopBar]="false"
      [reFetch]="reFetch"
      [selectableRows]="true"
      [editableRows]="true"
      [emptyComponent]="empty"
      [bulkActionComponent]="bulkActions"
      [dropdownData]="dropdownData"
      (editedItems)="editChanges($event)"
      (itemSelect)="onSelectItem($event)"
      [hideAddButton]="true"
      [hasEditPermission]="canUpdate"
    >
    </ov-suite-hierarchy-table>

    <ng-template #empty>
      <ov-suite-empty-vehicle-list> </ov-suite-empty-vehicle-list>
    </ng-template>

    <ng-template #bulkActions>
      <ov-suite-vehicle-table-bulk-action
        (dataChange)="onBulkDataChanges($event)"
        (bulkActionApply)="onApplyBulk()"
        [dropdownData]="dropdownData">
      </ov-suite-vehicle-table-bulk-action>
    </ng-template>

  <div *ngIf="currentData.length" class="d-flex flex-row justify-content-between align-content-between">
    <span> </span>
    <button type="button" *ngIf="currentData.length"
            class="btn btn-primary"
            (click)="onSaveChanges()"> Update & Save </button>
  </div>
  </div>
</div>
