import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  envName: 'production',
  cognito: {
    region: 'eu-west-1',
    clientId: '3tl5krif3thuhp94hvkd42r2jd',
    identityPoolId: 'eu-west-1:213130b9-8a6b-456c-9c4b-3eab110a3a24',
    userPoolId: 'eu-west-1_uYkqdJswL',
    bucketId: 'ovsuitebucketdevelop-develop',
    oauth: {},
    passwordPolicyText: 'Password must contain at least 8 characters, 1 upper case, 1 lower case character and a number',
  },
  apiUrl: {
    idm: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
    admin: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
    warehouse: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
    yard: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
    yardWs: 'wss://truda.api.yard.ovsuite.com',
    execution: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
    ceramicPortal: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
    order: 'https://4429ndt9q1.execute-api.af-south-1.amazonaws.com/default',
  },
  webUrl: {
    idm: 'https://truda.account.ovsuite.com',
    admin: 'https://truda.admin.ovsuite.com',
    warehouse: 'https://truda.warehouse.ovsuite.com',
    yard: 'https://truda.yard.ovsuite.com',
    execution: 'https://truda.execution.ovsuite.com',
    ceramicPortal: 'https://truda.ceramic-portal.ovsuite.com',
    order: 'https://truda.order.ovsuite.com',
  },
  appId: {
    idm: 8,
    admin: 9,
    warehouse: 1,
    yard: 6,
    execution: 5,
    ceramicPortal: 7,
    order: 3,
  },
  other: {
    mapApiKey: 'AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs',
    awsFileServiceApi: 'https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev',
  },
  inhanceWorker: {
    authUrl: '',
    appClientId: '',
    appClientSecret: '',
    apiUrl: '',
  },
  redis: {},
};
