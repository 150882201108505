import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVSearchable,
  OVEntity,
} from '@ov-suite/ov-metadata';
import { MapLocation } from '@ov-suite/models-helper';
import { Area } from './area.model';
import { Domain } from './domain.model';

@OVEntity('CustomerStatus', 'shared')
export class CustomerStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Customer', 'shared')
@OVForm([
  ['#Customer Details'],
  ['customerCode', 'name'],
  ['description'],
  ['mobileNumber', 'primaryEmail'],
  ['area'],
  ['map'],
  ['specialInstructions'],
  ['domains'],
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Customer>([
  {
    key: 'customerCode',
    title: 'Customer ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Customer Name',
    type: 'string',
  },
])
export class Customer {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Customer Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Customer ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  customerCode: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Customer Mobile Number' })
  mobileNumber?: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Customer Email Address' })
  primaryEmail?: string;

  @OVField({ type: 'map', required: false })
  map: MapLocation;

  @OVField({ type: 'string', title: 'Special Instructions' })
  specialInstructions?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => [Domain],
    selectionType: 'multiple',
    title: 'Domains',
    required: true,
  })
  domains: Domain[];

  @OVField({
    type: () => Area,
    tooltip: 'Add a single area for this customer.',
    selectionType: 'single',
    title: 'Area',
    required: true,
  })
  area: Area;

  @OVField({
    type: () => CustomerStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: CustomerStatus;
}
