import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnData } from '@ov-suite/helpers-shared';
import { FieldMetadata, getFieldMetadata } from '@ov-suite/ov-metadata';
import { Vehicle } from '@ov-suite/models-admin';
import { TemplateVehicleLine, VehicleOverride } from '@ov-suite/models-warehouse';
import { TemplateVehicleLineSelector } from './template-vehicle-line-selector.model';
import { ovFilterByKeyValue, ovSortByValue } from '../../../../helpers/table.helpers';

@Component({
  selector: 'ov-suite-template-vehicle-line-selector',
  templateUrl: './template-vehicle-line-selector.component.html',
  styleUrls: ['./template-vehicle-line-selector.component.scss'],
})
export class TemplateVehicleLineSelectorComponent implements OnInit {
  data: TemplateVehicleLineSelector[] = [];

  columnData: ColumnData<TemplateVehicleLineSelector>[];

  dropdownData: Record<string, unknown>;

  pageData: TemplateVehicleLineSelector[] = [];

  totalCount = 1;

  pageSize = 10;

  currentPage = 0;

  metadata: FieldMetadata<TemplateVehicleLineSelector>;

  filter: Record<string, unknown[]>;

  selected: Vehicle[];

  formClass = TemplateVehicleLineSelector;

  @Input() vehicleList: TemplateVehicleLine[];

  @Output() selectedItems = new EventEmitter();

  // this is prob a popup right, with a list of cars to add to the main list or not
  constructor(private readonly ovAutoService: OvAutoService, private readonly modalService: NgbModal) {}

  AddVehicle() {
    this.selectedItems.emit(this.selected);
    this.modalService.dismissAll();
  }

  onSelectItem(event) {
    this.selected = event;
  }

  ngOnInit(): void {
    this.metadata = getFieldMetadata<TemplateVehicleLineSelector>(this.formClass);

    // lets pull all the resources then after that we exclude the ones already on the template
    this.ovAutoService
      .list<VehicleOverride>({
        search: {},
        filter: {},
        query: {},
        entity: VehicleOverride,
        specifiedApi: null,
        limit: 1000,
        offset: null,
        orderDirection: 'ASC',
        orderColumn: 'id',
        keys: ['id', 'startTime', 'code', 'registration', 'startLocation', 'endTime', 'endLocation', 'class'],
      })
      .then(res => {
        // we already have that data so lets load it up.
        this.data = this.mergeData(res.data, this.vehicleList || []);
        this.totalCount = this.data.length;
        this.onPaginate();
      });
  }

  onSort(event) {
    this.data = ovSortByValue(this.data, event);
    this.onPaginate();
  }

  onFilter(event) {
    const { filter } = event;
    let _data = JSON.parse(JSON.stringify(this.data));
    for (const key in filter) {
      if (key && String(key) !== 'id') {
        const [filterValue] = filter[key];
        _data = ovFilterByKeyValue(_data, key.toString(), filterValue);
      }
    }
    this.data = _data;
    this.onPaginate();
  }

  mergeData(resources: VehicleOverride[], vehicles: TemplateVehicleLine[]): TemplateVehicleLineSelector[] {
    // get non intersecting ones
    const results = resources.filter(resource => !(vehicles.find(y => y.vehicle.id === resource.id) != null));
    return results.map(vehicle => ({
      id: vehicle.id,
      vehicle,
    }));
  }

  onPaginate() {
    const begin = this.currentPage * this.pageSize;
    const end = begin + this.pageSize;
    this.pageData = this.data.slice(begin, end);
  }

  changePage(event) {
    this.currentPage = event;
    this.onPaginate();
  }

  onCloseModal() {
    this.modalService.dismissAll('');
  }
}
