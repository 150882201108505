import { OVField, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { ProductSku } from './product-sku.model';
import { InventoryContainer } from './inventory-container.model';

@OVEntity('Inventory', 'warehouselink')
@OVTable<Inventory>([
  {
    key: 'id',
    title: 'ID',
    type: 'number',
  },
  {
    title: 'SKU',
    type: 'other',
    action: item => item.productSku?.sku,
    keys: ['productSku.sku'],
  },
  {
    title: 'Name',
    type: 'other',
    action: item => item.productSku?.name,
    keys: ['productSku.name'],
  },
  {
    title: 'Weight',
    type: 'other',
    action: item => (item.productSku?.weight ? `${item.productSku?.weight} g` : ''),
    keys: ['productSku.weight'],
  },
  {
    title: 'Dimensions',
    type: 'other',
    action: item => {
      const message = `${item.productSku?.length} cm x ${item.productSku?.height} cm x ${item.productSku?.width} cm`;
      return message.includes('null') ? '' : message;
    },
    keys: ['productSku.length', 'productSku.height', 'productSku.width'],
  },
])
export class Inventory {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => 'ProductSku',
    title: 'Product Sku',
    selectionType: 'single',
    keys: ['sku', 'name', 'weight', 'length', 'width', 'height'],
  })
  productSku: ProductSku;

  @OVField({ type: () => InventoryContainer, generated: true })
  container: InventoryContainer;
}
