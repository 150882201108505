import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';

export interface FlowablePage<T> {
  data: T[];
  total: number;
  start: number;
  sort: string;
  order: string;
  size: number;
}

@OVEntity('ProcessDefinition', 'warehouselink')
@OVTable<ProcessDefinition>([
  {
    key: 'id',
    title: 'ID',
    type: 'string'
  },
  {
    key: 'key',
    title: 'Process Key',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string'
  },
])
export class ProcessDefinition {
  @OVField({ type: 'number' } )
  id: string;

  @OVField({ type: 'string' })
  key: string;

  @OVField({ type: 'string' })
  url: string;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  category: string;

  @OVField({ type: 'string' , required: false})
  description?: string;

  @OVField({ type: 'number' })
  version: number;

  @OVField({ type: 'boolean' })
  suspended: boolean;

  @OVField({ type: 'string' })
  deploymentId: string;

  @OVField({ type: 'string' })
  deploymentUrl: string;

  @OVField({ type: 'string' })
  graphicalNotationDefined: string;

  @OVField({ type: 'string' })
  resource: string;

  @OVField({ type: 'string' })
  diagramResource: string;

  @OVField({ type: 'boolean' })
  startFormDefined: boolean;
}
