<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
  <div class="main-container">

 <ov-suite-table-top-tab-menu [tabs]="tableTabs" (tabClick)="onTabClick($event)">
 </ov-suite-table-top-tab-menu>

    <ov-suite-hierarchy-table
      [title]="'Vehicle Exception'"
      [formClass]="formClass"
      [ovAutoService]="ovAutoService"
      [hasBulkUpload]="false"
      [excludeColumns]="1"
      [hideAddButton]="false"
    >
    </ov-suite-hierarchy-table>

  </div>
</div>
