import { EventEmitter, Injectable } from '@angular/core';
import { CdkDragDrop, CdkDragRelease, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrderAllocation, SingleCombo, VehicleAllocation } from './load-allocation.interface';
import { LoadAllocationDataService } from './load-allocation.data.service';

/**
 * This service is used to manage interaction between panels on load-allocation.
 *
 * This typically means user actions like hovering and selecting
 */

@Injectable()
export class LoadAllocationActionService {
  vehicleSelected: SingleCombo<VehicleAllocation> = {
    value: null,
    observable: new EventEmitter<VehicleAllocation>(),
  };

  orderSelected: SingleCombo<OrderAllocation> = {
    value: null,
    observable: new EventEmitter<OrderAllocation>(),
  };

  orderHoverStart = new EventEmitter<OrderAllocation>();

  orderHoverEnd = new EventEmitter<OrderAllocation>();

  ordersChangeTracker: Record<number, { destination: string; origin: string }>;

  constructor(private readonly data: LoadAllocationDataService) {}

  selectOrder(order: OrderAllocation) {
    this.orderSelected.value = order;
    this.orderSelected.observable.emit(order);
  }

  selectVehicle(vehicle: VehicleAllocation) {
    this.vehicleSelected.value = vehicle;
    this.vehicleSelected.observable.emit(vehicle);
  }

  releaseDirty(event: CdkDragRelease<OrderAllocation>, veh: VehicleAllocation): void {
    this.data.makeDirty(veh);
  }

  dropDirty(event: CdkDragDrop<OrderAllocation[]>, veh: VehicleAllocation) {
    this.drop(event);
    this.data.makeDirty(veh);
    this.data.commitAllDirty();
  }

  drop(event: CdkDragDrop<OrderAllocation[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.vehicleSelected.observable.emit(this.vehicleSelected.value);
  }

  changesDetector(event: CdkDragDrop<OrderAllocation[]>) {
    // TODO add more logic
    if (event.previousContainer.id !== event.container.id) {
      this.ordersChangeTracker = {
        ...this.ordersChangeTracker,
        [Number(event.item.data?.order?.id)]: {
          destination: event.container.id,
          origin: event.previousContainer.id,
        },
      };
    }
  }
}
