import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Resource } from '@ov-suite/models-warehouse';
import { WarehousePermission } from '../../helpers/permissions.helpers';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Resource,
      api: 'warehouselink',
      createTitle: 'Resource',
      route: ['resources'],
      tableTitle: 'Resources',
      hideImport: true,
      permissionId: WarehousePermission.resources,
    }),
  ],
})
export class ResourceModule {}
