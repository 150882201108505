<nav #navbar class="navbar navbar-expand-lg navbar-absolute" [ngClass]="{ 'bg-white': open === true , 'navbar-transparent': open === false}">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <span
          id="backButton"
          (click)="navigateBack()"
        >
          <ov-suite-icon class="text-dark mr-2" [name]="'arrow-left'"> </ov-suite-icon>
        </span>
      </div>
      <a *ngIf="listTitles" class="navbar-brand" href="javascript:void(0)">{{ getTitle() }}</a>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="open = !open"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav navbar-tools">

        <li
          class="nav-item btn-rotate dropdown"
          [ngClass]="{ show: toggleDomains }"
          (click)="(toggleDomains = !toggleDomains) && (toggleApps && (toggleApps = !toggleApps))"
        >
          <a
            class="nav-link btn-magnify cursor-pointer dropdown-toggle domain-selected"
            role="button"
            id="dropdownMenuDomain"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i aria-hidden="true" class="fa fa-globe domain-icon"></i>
            <div class="domain-label">{{selectedDomain}}</div>
          </a>

          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuDomain"
          >
            <div class="dropdown-header">
              Domains
            </div>
            <div *ngFor="let domain of domains">
              <button
                class="dropdown-item"
                [ngClass]="{'dropdown-item-selected': selectedDomainId === domain.id}"
                (click)="setDomain(domain.id)"
              >
                {{domain.name}}
              </button>
            </div>
            <button class="dropdown-item" *ngIf="domains.length === 0">
              No Domains Available...
            </button>
          </div>
        </li>
        <li
          class="nav-item btn-rotate dropdown"
          [ngClass]="{ show: toggleApps }"
          (click)="(toggleApps = !toggleApps) && (toggleDomains && (toggleDomains = !toggleDomains))"
        >
          <a
            class="nav-link btn-magnify cursor-pointer dropdown-toggle domain-selected"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i aria-hidden="true" class="fa fa-link fa-2x"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuLink"
          >
            <div class="dropdown-header">
              App Switcher
            </div>
            <div *ngFor="let app of navbarApps">
              <button class="dropdown-item" *ngIf="app.hasPermission" (click)="navigate(app.url)">
                {{app.title}}
              </button>
            </div>
            <button class="dropdown-item" *ngIf="navbarApps.length === 0">
              No Apps Available...
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
