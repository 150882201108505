<div class="user">
  <div class="photo">
    <img *ngIf="!userAvatar" src="./assets/img/placeholder.jpg" alt="avatar" />
    <img *ngIf="!!userAvatar" [src]="userAvatar" alt="avatar" />
  </div>
  <div class="info">
    <a
      id="user-profile-link"
      (click)="navigate()"
    >
     <p class="collapsed navbar-username" [ngClass]="{ 'hidden' : !open && !hover }"> {{ userFullName }} </p>
    </a>
    <div class="clearfix"></div>
    <div class="domain-switcher">
      <i class="ovico-horizontal-swap" aria-hidden="true"></i>
      <div class="domain-selected" *ngFor="let d of domains"> {{ d.name }}</div>
    </div>
  </div>
</div>
