type BaseRecurrence = BaseRecurrenceNever | BaseRecurrenceOn | BaseRecurrenceAfter;

export enum WeekDay {
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7
}

interface BaseRecurrenceNever {
  endType: 'never';
}

interface BaseRecurrenceOn {
  endType: 'on';
  endDate: Date;
}

interface BaseRecurrenceAfter {
  endType: 'after'
  endCount: number;
}

interface DailyRecurrence {
  type: 'daily';
}

interface WeeklyRecurrence {
  type: 'weekly';
  days: number;
}

interface MonthlyRecurrence {
  type: 'monthly';
  day: number;
  weekNumber?: number;
}

interface YearlyRecurrence {
  type: 'yearly';
}

interface DefaultRecurrence {
  repeat: number;
}

export type Recurrence = (DailyRecurrence | WeeklyRecurrence | MonthlyRecurrence | YearlyRecurrence) & DefaultRecurrence & BaseRecurrence;

export const DayMapper: Record<number, string> = {
  64: "Sun",
  32: "Mon",
  16: "Tue",
  8: "Wed",
  4: "Thu",
  2: "Fri",
  1: "Sat",
}

export const getNumberWithOrdinal = (n: number) :string => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
