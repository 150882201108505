import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { FormioModel } from '@ov-suite/helpers-shared';

@OVEntity('Form', 'shared')
export class FormModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  key: string;

  @OVField({ type: 'json' })
  json: FormioModel;
}
