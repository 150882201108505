import { OVField, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('InventoryContainerType', 'shared')
export class InventoryContainerType {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  value: string;

  @OVField({ type: 'string' })
  key: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('InventoryContainer', 'shared')
export class InventoryContainer {
  @OVField({ type: 'string', generated: true })
  id: number;

  @OVSearchable()
  @OVField({ type: 'string' })
  name: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  description: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  createdBy?: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  barcodeType?: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  barcodeData?: string;

  @OVField({ type: () => InventoryContainerType })
  type: InventoryContainerType;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;
}
