import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataDropdown, ColumnDataString } from '@ov-suite/helpers-shared';

// Todo: Fix Permission code on cell
// [disabled]="userPermissions[col.permissionId]
//   ? !userPermissions[col.permissionId][col.permissionType]
//   : false"

@Component({
  selector: 'ov-suite-dropdown-cell',
  template: `
    <span *ngIf="!col.editable">
       {{ item[col?.key] ? (item[col?.key] | displayKey: 'name') : ''}}
    </span>

    <ov-suite-input
      *ngIf="col.editable"
      type="dropdown"
      [label]="col?.displayKeys"
      [style]="{'height': '38px'}"
      [data]="dropdownData[col.key.split('.').pop()]"
      [ngModel]="(col.editable ? (item | displayKeyUpdate: col.key) : (item | displayKey: col.key))"
      (ngModelChange)="setValueByKey($event, col?.key, item)"
    ></ov-suite-input>
  `,
  // styleUrls: ['./basic-table.component.scss'],
})
export class DropdownCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataDropdown<T>;
  @Output() itemChange = new EventEmitter<T>();

  // Todo: Rework to use ColumnData
  @Input() dropdownData = {};

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }

  setValueByKey(item: unknown, key: string, data: T) {
    const keys = key.split('.');
    const [firstKey] = keys;
    const tempObject = {};

    if (keys.length < 2) {
      data[firstKey] = item;
    } else {
      for (let i = 1; i < keys.length; i++) {
        tempObject[keys[keys.length - i]] = item;
        data[firstKey] = { ...data[firstKey], ...tempObject };
      }
    }
    this.itemChange.emit(data);
  }
}
