import {
  OVField,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';

@OVEntity('WorkflowProcess', 'warehouselink')
@OVTable<WorkflowProcess>([
  {
    key: 'id',
    title: 'Instance ID',
    type: 'number'
  },
  {
    key: 'processDefinitionName',
    title: 'Definition Name',
    type: 'string'
  },
  {
    key: 'processDefinitionId',
    title: 'Process Identification',
    type: 'string'
  },
  {
    key: 'startTime',
    title: 'Starting Time',
    type: 'string'
  },
  {
    key: 'ended',
    title: 'Ended',
    type: 'string'
  },
  {
    key: 'completed',
    title: 'Completed',
    type: 'string'
  },
  {
    keys: [],
    title: 'Action',
    type: 'buttons',
    buttons: [
      {
        routerLink: r => ['/', 'workflow-processes', 'view', r.id],
        title: 'Detail'
      }
    ]
  }
])
export class WorkflowProcess {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: false,
    generated: true,
    readonly: true
  })
  id: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Suspended',
    required: true
  })
  suspended: boolean;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Ended',
    required: true
  })
  ended: boolean;

  @OVField({
    type: 'string',
    title: 'Process Definition ID',
    required: true
  })
  processDefinitionId: string;

  @OVField({
    type: 'string',
    title: 'Process Definition Url',
    required: true
  })
  processDefinitionUrl: string;

  @OVField({
    type: 'string',
    title: 'Process Definition Name',
    placeholder: 'Required',
    required: true
  })
  processDefinitionName: string;

  @OVField({
    type: 'string',
    title: 'Process Definition Description',
    required: true
  })
  processDefinitionDescription: string;

  @OVField({
    type: 'string',
    title: 'Activity ID',
    required: true
  })
  activityId: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Start User ID',
    required: true
  })
  startUserId: string;

  @OVField({
    type: 'string',
    title: 'Start Time',
    required: true
  })
  startTime: Date;

  @OVField({
    type: 'string',
    title: 'Callback ID',
    required: true
  })
  callbackId: string;

  @OVField({
    type: 'string',
    title: 'Callback Type',
    required: true
  })
  callbackType: string;

  @OVField({
    type: 'string',
    title: 'Tenant ID',
    required: true
  })
  tenantId: string;

  @OVField({
    type: 'boolean',
    title: 'Completed',
    required: true
  })
  completed: boolean;
}
