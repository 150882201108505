import { Component, Input, OnInit } from '@angular/core';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { Slot, VehicleAllocation } from '../load-allocation.interface';
import { LoadAllocationVehicleLayoutService } from '../load-allocation.vehicle-layout.service';
import { LoadAllocationDataService } from '../load-allocation.data.service';
import { LoadAllocationActionService } from '../load-allocation.action.service';

@Component({
  selector: 'ov-suite-load-allocation-vehicle-wave',
  templateUrl: './vehicle-wave.component.html',
  styleUrls: ['./vehicle-wave.component.scss', '../load-allocation.module.scss'],
})
export class VehicleWaveComponent implements OnInit {
  @Input() allocation: VehicleAllocation;

  @Input() slot: Slot;

  constructor(
    public base: LoadAllocationBaseService,
    public layout: LoadAllocationVehicleLayoutService,
    public action: LoadAllocationActionService,
    public data: LoadAllocationDataService,
  ) {}

  ngOnInit(): void {}

  getId(): string {
    const prefix = this.allocation.load.isExternalVehicle() ? 'e' : 'i';
    return `veh_${this.allocation.load.getVehicle().id}_${this.slot.id}_${prefix}`;
  }
}
