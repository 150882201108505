import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RouteInfo } from '../sidebar/sidebar.model';
import { NavbarComponent } from '../navbar/navbar.component';
import { AdminLayoutParams } from './admin-layout.types';

@Component({
  selector: 'ov-suite-app-layout',
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {
  public routes: RouteInfo[] = [];

  public projectName = '';

  private lastPoppedUrl: string;

  private readonly yScrollStack: number[] = [];

  @ViewChild('sidebar', { static: false }) sidebar;

  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;

  constructor(private readonly router: Router, private readonly location: Location, @Inject('SIDEBAR_OPTIONS') options: AdminLayoutParams) {
    this.routes = options.sidebarRoutes;
    this.projectName = options.projectName;
  }

  ngOnInit() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    const html = document.getElementsByTagName('html')[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      html.classList.add('perfect-scrollbar-on');
    } else {
      html.classList.add('perfect-scrollbar-off');
    }
  }

  ngAfterViewInit() {
    const elemMainPanel = document.querySelector('.main-panel');
    const elemSidebar = document.querySelector('.sidebar .sidebar-wrapper');
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      }
    });
  }

  isMac(): boolean {
    const platform = navigator.platform.toUpperCase();
    return platform.includes('MAC') || platform.includes('IPAD');
  }
}
