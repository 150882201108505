import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { ProductSku } from '@ov-suite/models-admin';

@OVEntity({ name: 'OrderItem' })
export class OrderItem {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({
    type: () => ProductSku,
    keys: ['id', 'sku', 'name'],
  })
  productSku: ProductSku;
}
