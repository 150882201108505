import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OvAutoService, OvAutoServiceMultipleMutationParams } from '@ov-suite/services';
import { VehicleException } from '@ov-suite/models-warehouse';
import { FieldMetadata, getFieldMetadata } from '@ov-suite/ov-metadata';
import { getUpdate } from '@ov-suite/graphql-helpers';

@Component({
  selector: 'ov-suite-vehicle-exception-add-or-edit',
  templateUrl: './vehicle-exception-add-or-edit.component.html',
  styleUrls: ['./vehicle-exception-add-or-edit.component.scss'],
})
export class VehicleExceptionAddOrEditComponent implements OnInit {
  formClass = VehicleException;

  dataSources: {};

  metadata: FieldMetadata<VehicleException>;

  id: number;

  data: VehicleException;

  originalData: VehicleException;

  constructor(private readonly route: ActivatedRoute, private readonly router: Router, public readonly ovAutoService: OvAutoService) {
    this.dataSources = { ...this.dataSources };
  }

  ngOnInit(): void {
    this.metadata = getFieldMetadata<VehicleException>(this.formClass);

    this.route.queryParamMap.subscribe(response => {
      if (response.has('id')) {
        this.id = Number(response.get('id'));
        this.ovAutoService.get<VehicleException>(VehicleException, '', this.id).then(res => {
          console.log(res);
          this.data = res;
          this.originalData = { ...res } as VehicleException;
        });
      }
    });
  }

  onSave(event: VehicleException) {
    if (this.id != null) {
      this.updateException(event);
    } else {
      this.createExceptions(event);
    }
  }

  createExceptions(data: VehicleException) {
    let exceptions: OvAutoServiceMultipleMutationParams = {};
    data.vehicles.forEach(vehicle => {
      const { vehicles, reason, ...exception } = data;
      exception['reasonId'] = reason?.id;
      exception['vehiclesIdList'] = [vehicle.id];
      exceptions = {
        ...exceptions,
        [`vehicleException${vehicle.id}`]: {
          type: 'create',
          entity: VehicleException,
          item: exception,
        },
      };
    });

    this.ovAutoService.multipleMutation(exceptions).then(createResponse => {
      if (createResponse) {
        this.router.navigate(['/vehicles/exception']);
      }
    });
  }

  updateException(data: VehicleException) {
    console.log(data, this.originalData, 'Updating Records');
    this.ovAutoService
      .update<VehicleException>({ item: getUpdate<VehicleException>(data, this.originalData), entity: VehicleException })
      .then(updateResponse => {
        if (updateResponse) {
          this.router.navigate(['/vehicles/exception']);
        }
      });
  }
}
