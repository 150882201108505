import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HierarchyTab } from './hierarchy-tabs.model';

@Component({
  selector: 'ov-suite-hierarchy-tabs',
  templateUrl: './hierarchy-tabs.component.html',
  styleUrls: ['./hierarchy-tabs.component.scss'],
})
export class HierarchyTabsComponent implements OnInit {
  active: string;

  @Input() tabs: HierarchyTab[] = [];

   highlightColor = '#009de6';

  constructor(private readonly router: Router) {}

  ngOnInit() {
    // set initial value
    this.active = this.router.url.split('?')[0];

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.active = event.url.split('?')[0];
      }
    });

    // dynamic highlight color changer
    // @ts-ignore
    const [element] = document.getElementsByClassName('sidebar');
    if (element) {
      const className = element.getAttribute('data-active-color');
      const style = getComputedStyle(document.body);
      const hexColor = style.getPropertyValue(`--${className}`).trim();
      if (hexColor) {
        this.highlightColor = hexColor;
      }
    }
  }
}
