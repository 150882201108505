import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryProductSkuListComponent } from './list/inventory-product-sku-list.component';
import { InventoryProductSkuRoutes } from './inventory-product-sku.routing';
import { SetTriggersComponent } from './modals/set-triggers/set-triggers.component';
import { OvAutoService } from "@ov-suite/services";

// TODO temporary popup eager loading fix
const load = SetTriggersComponent;

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(InventoryProductSkuRoutes),
    FormsModule,
    UiModule,
    NgbAlertModule,
  ],
  declarations: [
    InventoryProductSkuListComponent,
    SetTriggersComponent,
  ],
  providers: [OvAutoService],
})
export class InventoryProductSkuModule {}
