import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataDate } from '@ov-suite/helpers-shared';
import moment from 'moment';

@Component({
  selector: 'ov-suite-date-cell',
  template: `
    <span *ngIf="!col.editable">
      {{ getDate(item[col?.key], col) }}
    </span>
    <ov-suite-input
      *ngIf="col.editable"
      type="date"
      [style]="{'height': '38px'}"
      [(ngModel)]="item[col?.key]"
      (ngModelChange)="itemChanged(item)"
    ></ov-suite-input>
  `,
})
export class DateCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataDate<T>;
  @Output() itemChange = new EventEmitter<T>();

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }

  getDate(date?: Date, column?: ColumnDataDate<T>): string {
    if (!date) {
      return '';
    }
    return moment(date).format(column.format ?? 'DD MMM YYYY');
  }
}
