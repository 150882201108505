import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';

@OVEntity('TagStatus', 'adminlink')
export class TagStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Tag', 'adminlink')
@OVForm([['Tag Details'], ['tagId', 'name']])
@OVSidebar([
  // ['image'],
  ['status'],
  ['id']
])
@OVTable<Tag>([
  {
    key: 'tagId',
    title: 'Tag ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Tag Name',
    type: 'string'
  }
])
export class Tag {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Tag Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Tag ID',
    placeholder: 'Required',
    required: true
  })
  tagId: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  // @SidebarField({ type: 'image', title: 'Tag Image' })
  imageUrl?: string;

  // @SidebarField({ type: 'dropdown', title: 'Status' })
  @OVField({
    type: () => TagStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: TagStatus;
}
