import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from '@ov-suite/helpers-shared';
import Auth from '@aws-amplify/auth';
import { Hub, HubPayload } from '@aws-amplify/core';

export async function refreshSession(): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      UserPoolId: environment.cognito.userPoolId,
      ClientId: environment.cognito.clientId,
    });

    Auth.currentSession()
      .then(session => {
        if (session) {
          userPool.getCurrentUser().refreshSession(session.getRefreshToken(), (err, res) => {
            if (err) {
              reject(err);
            } else {
              const payload: HubPayload = {
                data: {},
                event: 'refresh',
                message: 'Token Refreshed',
              };
              Hub.dispatch('token', payload);
              resolve(res);
            }
          });
        }
      })
      .catch(reject);
  });
}
