import { ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { AgmGeocoder, GeocoderResult, MapsAPILoader } from '@agm/core';
import { google } from 'google-maps';
import { latLng, MapOptions, tileLayer, marker } from 'leaflet';
import { MapLocation } from '@ov-suite/models-helper';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

@Component({
  selector: 'ov-suite-agm-map',
  templateUrl: './agm-map.component.html',
  styleUrls: ['./agm-map.component.scss'],
  providers: [MakeProvider(AgmMapComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AgmMapComponent extends AbstractValueAccessor<MapLocation> implements OnInit {
  @Input() id: string;

  @Input() danger: boolean;

  @Input() disabled: boolean;

  @ViewChild('mapSearch')
  public searchElementRef: ElementRef;

  latitude = -29;

  longitude = 24;

  zoom = 5;

  center = latLng(this.latitude, this.longitude);

  pin = marker([this.latitude, this.longitude], { draggable: true });

  address: string;

  private geoCoder: AgmGeocoder;

  private searchTimeout = null;

  options: MapOptions = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })],
    doubleClickZoom: false,
  };

  constructor(private readonly mapsAPILoader: MapsAPILoader, private readonly ngZone: NgZone, private readonly cdRef: ChangeDetectorRef) {
    super();
  }

  writeValue(value: MapLocation) {
    this.val = value;
    this.onChange(value);
    if (value) {
      this.address = value.address;
      this.latitude = value.latitude;
      this.longitude = value.longitude;
      this.center = latLng(this.latitude, this.longitude);
      if (!this.latitude) {
        this.pin = null;
      } else {
        this.pin = marker(this.center, { draggable: true })
          .bindTooltip(this.address)
          .on('dragend', event => {
            this.markerDragEnd({ coords: event.target['_latlng'] });
          });
      }
      this.zoom = 12;
    }
  }

  valueChange(item: MapLocation) {
    this.writeValue(item);
  }

  ngOnInit() {
    this.initialiseMap();
  }

  initialiseMap() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new AgmGeocoder(this.mapsAPILoader);

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: {
          country: ['za', 'zw', 'ls', 'sz', 'mz', 'bw', 'na'],
        },
      });
      autocomplete.addListener('place_changed', () => {
        if (autocomplete.getPlace()) {
          this.ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            // verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            // set latitude, longitude and zoom
            this.address = place.formatted_address;
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
            this.emitCoOrdinates();
          });
        }
      });
    });
  }

  locationChange() {
    this.searchTimeout = null;
    this.searchTimeout = setTimeout(() => {
      this.getAddress(this.latitude, this.longitude);
    }, 500);
  }

  emitCoOrdinates() {
    const map = new MapLocation();
    map.address = this.address;
    map.longitude = this.longitude;
    map.latitude = this.latitude;
    this.valueChange(map);
  }

  markerDragEnd(event) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }).subscribe((results: GeocoderResult[]) => {
      if (results && results.length > 0) {
        this.zoom = 12;
        this.address = results[0].formatted_address;
      } else {
        this.address = '';
      }
      this.emitCoOrdinates();
      this.cdRef.detectChanges();
    });
  }
}
