import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { LoadAllocationDataService } from '../load-allocation.data.service';
import { LoadAllocationActionService } from '../load-allocation.action.service';

@Component({
  selector: 'ov-suite-load-allocation-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss', '../load-allocation.module.scss'],
})
export class OrderComponent implements OnInit {
  @ViewChild('dateRange', { static: false }) dateRange;

  constructor(
    public base: LoadAllocationBaseService,
    public dataService: LoadAllocationDataService,
    public actionService: LoadAllocationActionService,
    public data: LoadAllocationDataService,
  ) {}

  ngOnInit(): void {}

  onColumnHeaderClick(column: string) {
    if (this.data.sortingData.column === column) {
      this.data.changeSortingDirection();
      this.data.sortOrderAllocations();
    } else {
      this.data.changeSortingColumn(column);
      this.data.sortOrderAllocations();
    }
  }

  clearOrderAllocationsFilter() {
    this.data.orderAllocationsFilter.masterRoute = null;
    this.data.orderAllocationsFilter.customerSearchTerm = null;
    this.data.orderDateRange = null;
    this.dateRange.clearDateRange();
    this.data.fetchData();
  }
}
