import {
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewEncapsulation,
  Optional,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  NgZone
} from '@angular/core';
import { FormioAppConfig } from './formio.config';
import {
  FormioForm,
  FormioOptions
} from './formio.common';
import { FormBuilder, Utils } from 'formiojs';
import { assign } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { CustomTagsService } from './custom-tags.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'form-builder',
  templateUrl: './formbuilder.component.html',
  styleUrls: ['./formbuilder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormBuilderComponent implements OnInit, OnChanges, OnDestroy {
  public ready: Promise<object>;
  public readyResolve: Function;
  public formio;
  public builder: FormBuilder;
  public componentAdding = false;
  private refreshSubscription: Subscription;
  @Input() form?: FormioForm;
  @Input() options?: FormioOptions;
  @Input() formbuilder?: typeof FormBuilder;
  @Input() noeval ? = false;
  @Input() refresh?: Observable<void>;
  @Input() rebuild?: Observable<object>;
  // tslint:disable-next-line:no-output-native
  @Output() change?: EventEmitter<object>;
  @ViewChild('builder', { static: true }) builderElement?: ElementRef;

  constructor(
    private readonly ngZone: NgZone,
    @Optional() private readonly config: FormioAppConfig,
    @Optional() private readonly customTags?: CustomTagsService
  ) {
    if (this.config) {
      // Formio.setApiUrl(this.config.apiUrl);
      // Formio.setBaseUrl(this.config.apiUrl);
      // Formio.setProjectUrl(this.config.appUrl);
      // Formio.setBaseUrl('ignore');
      // Formio.setProjectUrl('ignore');
    } else {
      console.warn('You must provide an AppConfig within your application!');
    }

    this.change = new EventEmitter();
    this.ready = new Promise((resolve) => {
      this.readyResolve = resolve;
    });
  }

  ngOnInit() {
    Utils.Evaluator.noeval = this.noeval;

    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => {
        this.ngZone.runOutsideAngular(() => {
          this.buildForm(this.form);
        });
      });
    }

    if (this.rebuild) {
      this.rebuild.subscribe((options) => {
        this.ngZone.runOutsideAngular(() => {
          this.rebuildForm(this.form, options);
        });
      });
    }
  }

  setInstance(instance) {
    this.formio = instance;
    instance.off('addComponent');
    instance.off('saveComponent');
    instance.off('updateComponent');
    instance.off('removeComponent');
    instance.on('addComponent', (component, parent, path, index, isNew) => {
      this.ngZone.run(() => {
        if (isNew) {
          this.componentAdding = true;
        } else {
          this.change.emit({
            type: 'addComponent',
            builder: instance,
            form: instance.schema,
            component: component,
            parent: parent,
            path: path,
            index: index
          });
          this.componentAdding = false;
        }
      });
    });
    instance.on('saveComponent', (component, original, parent, path, index, isNew) => {
      this.ngZone.run(() => {
        this.change.emit({
          type: this.componentAdding ? 'addComponent' : 'saveComponent',
          builder: instance,
          form: instance.schema,
          component: component,
          originalComponent: original,
          parent: parent,
          path: path,
          index: index,
          isNew: isNew || false
        });
        this.componentAdding = false;
      });
    });
    instance.on('updateComponent', (component) => {
      this.ngZone.run(() => {
        this.change.emit({
          type: 'updateComponent',
          builder: instance,
          form: instance.schema,
          component: component
        });
      });
    });
    instance.on('removeComponent', (component, parent, path, index) => {
      this.ngZone.run(() => {
        this.change.emit({
          type: 'deleteComponent',
          builder: instance,
          form: instance.schema,
          component: component,
          parent: parent,
          path: path,
          index: index
        });
      });
    });
    this.ngZone.run(() => {
      this.readyResolve(instance);
    });
    return instance;
  }

  setDisplay(display: string) {
    return this.builder.setDisplay(display).then(instance => this.setInstance(instance));
  }

  buildForm(form) {
    if (!form || !this.builderElement || !this.builderElement.nativeElement) {
      return;
    }

    if (this.builder) {
      return this.setDisplay(form.display).then(() => {
        this.builder.form = form;
        this.builder.instance.form = form;
        return this.builder.instance;
      });
    }

    return this.rebuildForm(form);
  }

  rebuildForm(form, options?: object) {
    const Builder = this.formbuilder || FormBuilder;
    const extraTags = this.customTags ? this.customTags.tags : [];
    this.builder = new Builder(
      this.builderElement.nativeElement,
      form,
      assign({
        icons: 'fontawesome',
        sanitizeConfig: {
          addTags: extraTags
        }
      }, options || this.options || {})
    );
    return this.builder.ready.then(instance => this.setInstance(instance));
  }

  ngOnChanges(changes) {
    Utils.Evaluator.noeval = this.noeval;

    if (changes.form && changes.form.currentValue) {
      this.ngZone.runOutsideAngular(() => {
        this.buildForm(changes.form.currentValue || {components: []});
      });
    }
  }

  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    if (this.formio) {
      this.formio.destroy();
    }
  }
}
