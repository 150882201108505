import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { Customer, Factory } from '@ov-suite/models-admin';

@OVEntity('MasterRoute')
@OVTable<MasterRoute>([
  {
    key: 'id',
    title: 'ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'createdDate',
    title: 'Date Created',
    type: 'date',
    format: 'DD MMM yyyy',
  },
  {
    type: 'other',
    title: 'Customers',
    action: t => t.customers.map(i => i.name).join(', '),
    keys: ['customers.name'],
  },
  {
    type: 'other',
    title: 'Source',
    action: t => t.source.name,
    keys: ['source.name'],
  },
  {
    type: 'other',
    title: 'Destination',
    action: t => t.destination.name,
    keys: ['destination.name'],
  },
])
export class MasterRoute {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({
    type: () => [Customer],
    keys: ['id', 'name']
  })
  customers: Customer[];

   @OVField({ type: 'numbers' })
   customerIds: number[];

  @OVField({ type: () => Factory })
  source: Factory;

   @OVField({ type: 'number' })
   sourceId: number;

  @OVField({ type: () => Factory })
  destination: Factory;

   @OVField({ type: 'number' })
   destinationId: number;

  @OVField({ type: 'date' })
  createdDate: Date;
}
