import { AutoService } from '@ov-suite/services';
import { Form } from './form.model';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { mapToClass } from '@ov-suite/ov-metadata';
import gql from 'graphql-tag';

@Injectable()
export class FormService extends AutoService<Form> {
  constructor(private readonly apollo: Apollo) {
    super(apollo.use('warehouselink'), Form, 'Form', 'Forms','warehouselink');
  }

  async getByKey(key: string): Promise<Form> {
    const query = gql(`
      query GetFormByKey($key: String!) {
        getFormByKey(key: $key) {
          id
          key
          json
        }
      }
    `);

    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .query({
          query,
          variables: { key },
          fetchPolicy: 'no-cache'
        })
        .subscribe(response => {
          const rawData = response.data['getFormByKey'];
          const data = mapToClass<Form>(Form, rawData);
          resolve(data);
        }, reject);
    });
  }
}
