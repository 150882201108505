import {
  AfterViewInit,
  Component, HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { SplitComponent } from 'angular-split';
import { map } from 'rxjs/operators';
import { merge, Observable, Subscription } from 'rxjs';
import { MapComponent } from '../map/map.component';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { ComponentCanDeactivate } from '../../../guards/pending-changes-guard';
import { LoadAllocationActionService } from '../load-allocation.action.service';

@Component({
  selector: 'ov-suite-load-allocation-main',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements AfterViewInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild('leftSide', { static: false }) leftSide: SplitComponent;

  @ViewChild('rightSide', { static: false }) rightSide: SplitComponent;

  @ViewChild('vertical', { static: false }) vertical: SplitComponent;

  @ViewChild('map') mapComponent: MapComponent;

  sub: Subscription;

  vSub: Subscription;

  constructor(
    public base: LoadAllocationBaseService,
    public action: LoadAllocationActionService,
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.action.ordersChangeTracker == null) {
      return true;
    }

    const keys = Object.keys(this.action.ordersChangeTracker);

     if (keys.length < 1) {
       return true;
     }

     return false;
  }

  ngAfterViewInit() {
    this.sub = merge(
      this.leftSide.dragProgress$.pipe(map((data) => ({ name: 'leftSide', data }))),
      this.rightSide.dragProgress$.pipe(map((data) => ({ name: 'rightSide', data }))),
    ).subscribe((d) => {
      if (d.name === 'leftSide') {
        this.rightSide.setVisibleAreaSizes(this.leftSide.getVisibleAreaSizes());
      } else if (d.name === 'rightSide') {
        this.leftSide.setVisibleAreaSizes(this.rightSide.getVisibleAreaSizes());
      }
      this.mapComponent.updateMap();
    });
    this.vSub = this.vertical.dragProgress$.pipe(map((data) => ({ name: 'rightSide', data }))).subscribe(() => {
      this.mapComponent.updateMap();
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
