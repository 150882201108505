export enum OrderEventType {
  OrderCreated = 'orderCreated',
  OrderSyncedFromSage = 'orderSyncedFromSage',
  OrderApproved = 'orderApproved',
  OrderAllocatedToLoad = 'orderAllocatedToLoad',
  OrderUnAllocatedToLoad = 'orderUnAllocatedToLoad',
  OrderReleased = 'orderReleased',
  OrderPickingStarted = 'orderPickingStarted',
  OrderPickingFinished = 'orderPickingFinished',
  OrderLoadingStarted = 'orderLoadingStarted',
  OrderLoadingFinished = 'orderLoadingFinished',
  OrderShipped = 'orderShipped'
}
