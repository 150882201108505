import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { InventoryContainer } from '@ov-suite/models-warehouse';
import { WarehousePermission } from '../../helpers/permissions.helpers';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: InventoryContainer,
      api: 'warehouselink',
      createTitle: 'Inventory Container',
      route: ['inventory-container'],
      tableTitle: 'Inventory Container',
      hideImport: true,
      permissionId: WarehousePermission.resources,
    }),
  ],
})
export class InventoryContainerModule {}
