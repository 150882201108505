<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Vehicles List</a>
    <ng-template ngbNavContent>
      <div>
        <ov-suite-hierarchy-table
          [title]="'Vehicles'"
          [formClass]="internalVehicleFormClass"
          [ovAutoService]="ovAutoService"
          [hasBulkUpload]="false"
          (itemSelect)="onSelectInternalVehicle($event)"
          [excludeColumns]="4"
          [selectableRows]="true"
          [hideAddButton]="true"
        >
        </ov-suite-hierarchy-table>
        <button type="button" (click)="activeModal.close()" class="btn btn-outline-primary ml-4 mb-4 float-left">
         Close
        </button>
        <button type="button" (click)="onAddInternalVehicle()" class="btn btn-outline-dark mr-4 mb-4 float-right">
          Replace Vehicle <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
        </button>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>External Vehicles List</a>
    <ng-template ngbNavContent>
      <div>
        <ov-suite-hierarchy-table
          [title]="'Vehicles'"
          [formClass]="externalVehicleFormClass"
          [ovAutoService]="ovAutoService"
          [hasBulkUpload]="false"
          (itemSelect)="onSelectExternalVehicle($event)"
          [excludeColumns]="4"
          [selectableRows]="true"
          [hideAddButton]="true"
        >
        </ov-suite-hierarchy-table>
        <button type="button" (click)="activeModal.close()" class="btn btn-outline-primary ml-4 mb-4 float-left">
          Close
        </button>
        <button type="button" (click)="onAddExternalVehicle()" class="btn btn-outline-dark mr-4 mb-4 float-right">
          Replace Vehicle <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
        </button>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

