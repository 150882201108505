<div *ngIf="type !== 'checkbox'" class="form-group">
  <ng-container [ngSwitch]="type">
    <label *ngIf="inputLabel || title" class="mt-2" [for]="id"
      >{{ inputLabel || title }}
      <span
        *ngIf="tooltip"
        aria-hidden="true"
        class="fa fa-info-circle"
        placement="right-top"
        [ngbTooltip]="!!tooltip ? tipContent : ''"
      ></span>
      <ng-template #tipContent><div [innerHTML]="tooltip"></div></ng-template>
    </label>
    <div
      [ngClass]="{
        'input-parent': type !== 'map',
        'input-parent-disabled': disabled,
        'border-danger': danger
      }"
      [ngStyle]="style"
    >
      <div *ngSwitchCase="'date'" class="date">
        <div class="form-group">
          <div class="input-group">
            <input [id]="id" class="form-control" placeholder="yyyy-mm-dd" [disabled]="disabled"
                   (ngModelChange)="setDate($event)"
                   name="dp" [ngModel]="getDate()" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate">
            <div class="input-group-append">
              <button class="btn-clear fa fa-calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'time'">
        <div class="form-group">
          <ov-suite-time
            [id]="id"
            [ngModel]="value"
            (ngModelChange)="valueChange($event)"
            [disabled]="disabled"
          ></ov-suite-time>
        </div>
      </div>

      <div *ngSwitchCase="'date-time'">
        <div class="form-group">
          <ov-suite-date-time
            [id]="id"
            [ngModel]="value"
            (ngModelChange)="valueChange($event)"
            [disabled]="disabled"
            [showTime]="showTime"
          ></ov-suite-date-time>
        </div>
      </div>

      <div *ngSwitchCase="'date-range'">
        <ov-suite-date-range
          #dateRange
          [hideClearButton]="hideClearButton"
          [id]="id"
          [placeholder]="placeholder"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)">
        </ov-suite-date-range>
      </div>

      <div *ngSwitchCase="'text'">
        <input
          [id]="id"
          [type]="type"
          class="form-control"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        />
      </div>
      <div *ngSwitchCase="'password'">
        <input
          [id]="id"
          [type]="type"
          class="form-control"
          autocomplete="new-password"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        />
      </div>
      <div *ngSwitchCase="'email'">
        <input
          [id]="id"
          [type]="type"
          class="form-control"
          [disabled]="disabled"
          [placeholder]="!!placeholder ? placeholder : ''"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        />
      </div>
      <div *ngSwitchCase="'number'">
        <input
          [id]="id"
          [type]="type"
          [step]="step"
          class="form-control"
          [placeholder]="placeholder"
          [ngModel]="value"
          [disabled]="disabled"
          (ngModelChange)="valueChange(+$event)"
        />
      </div>
      <div *ngSwitchCase="'dropdown'">
        <div
          aria-hidden="true"
          class="fa fa-circle status-icon"
          *ngIf="!!statusColor"
          [ngStyle]="{ color: statusColor }"
        ></div>
        <select
          [id]="id"
          class="form-control"
          [compareWith]="compare"
          [ngModel]="value || null"
          [disabled]="disabled"
          (ngModelChange)="valueChange($event)"
        >
          <option *ngIf="!!placeholder" value="null" [disabled]="true" [selected]="true">{{
            placeholder
          }}</option>
          <option *ngIf="!placeholder" value="null" [hidden]="true" [disabled]="true" [selected]="true">
          </option>
          <option *ngFor="let item of data" [ngValue]="item">
            <span *ngIf="label.length === 0">
              {{item['name']}}
            </span>
            <span *ngFor="let key of label">
              {{ item | displayKey: key}}
            </span>
          </option>
        </select>
      </div>
      <div *ngSwitchCase="'map'">
        <ov-suite-agm-map
          [id]="id"
          [disabled]="disabled"
          [danger]="danger"
          [ngModel]="value"
          (ngModelChange)="valueChange($event)"
        ></ov-suite-agm-map>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="type === 'checkbox'" class="ov-checkbox">
  <input [id]="id" type="checkbox" [checked]="value" (change)="check()" />
  <label [for]="id">{{ inputLabel || title }}</label>
</div>
