/* eslint-disable no-bitwise */
export interface CRUD {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

type ColumnValue = 1 | 2 | 4 | 8;

export function verifyPermission(value: number, column: ColumnValue): boolean {
  if (!value) {
    return false;
  }
  return (value | column) === value;
}

export function getCrud(value: number): CRUD {
  return {
    create: verifyPermission(value, 8),
    read: verifyPermission(value, 4),
    update: verifyPermission(value, 2),
    delete: verifyPermission(value, 1),
  };
}

export enum PermissionType {
  create,
  read,
  update,
  delete,
}
