<div>
        <input
          name="dueTime"
          class="form-control custom-time-input"
          [disabled]="disabled"
          type="time"
          [(ngModel)]="time"
          [value]="time"
          (ngModelChange)="valueChange()"
          [ngModelOptions]="{standalone: true}"
          required>
</div>
