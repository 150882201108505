import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataButtons, ColumnDataString } from '@ov-suite/helpers-shared';

// Todo: Rework permissions into ColumnData?
// Todo: Rework Button?
// (click)="
// button.action ? button.action(item, {
//     ngbModal: ngbModal,
//     apiService: service,
//     columnAction: columnItemAction(item, button.actionType)
//   })
//   : routerLink(button, item)
// "

@Component({
  selector: 'ov-suite-buttons-cell',
  template: `
    <div class="btn-group" role="group" aria-label="Basic example">
      <ng-container *ngFor="let button of col?.buttons">
<!--        *ngIf="!button.permissionId || userPermissions[button.permissionType]"-->
      <button
        (click)="onClick(button, item)"
        type="button"
        class="btn btn-secondary table-button action-btn-tooltip"
        [style.display]="
          button.hide
            ? button.hide(item)
              ? 'none'
              : 'block'
            : 'block'
        "
        [ngClass]="(getObjectType(button.classes) === 'function') ? button.classes(item) : button.classes"
      >
        {{ getObjectType(button?.title) === 'function' ? button?.title(item) : button?.title }}
        <i *ngIf="button?.icon" [class]="button.icon" aria-hidden="true"></i>
        <span *ngIf="button.tooltip" class="action-btn-tooltip-text">{{button.tooltip}}</span>
      </button>
      </ng-container>
    </div>
  `,
  styleUrls: ['../../basic-table.component.scss'],
})
export class ButtonsCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataButtons<T>;
  @Output() click = new EventEmitter();

  getObjectType(type) {
    return typeof type;
  }

  onClick(event, data) {
    this.click.emit({ event, data });
  }
}
