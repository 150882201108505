import { Component } from '@angular/core';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';

@Component({
  selector: 'ov-suite-wave-allocation-planner',
  templateUrl: './wave-allocation-planner.component.html',
  styleUrls: ['./wave-allocation-planner.component.scss'],
})
export class WaveAllocationPlannerComponent {
  constructor(public dataService: WaveAllocationDataService, public readonly baseService: WaveAllocationBaseService) {}

  drag(event: MouseEvent, body: HTMLDivElement, leftColumn: HTMLDivElement, topRow: HTMLDivElement) {
    body.style.cursor = 'grabbing';
    body.style.userSelect = 'none';

    const dragPos = {
      left: body.scrollLeft,
      top: body.scrollTop,
      x: event.clientX,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - dragPos.x;
      const dy = e.clientY - dragPos.y;

      body.scrollTop = dragPos.top - dy;
      body.scrollLeft = dragPos.left - dx;
      leftColumn.scrollTop = dragPos.top - dy;
      topRow.scrollLeft = dragPos.left - dx;
    };

    const mouseUpHandler = this.getMouseUpHandler(body, mouseMoveHandler);

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  getMouseUpHandler(element: HTMLDivElement, mouseMoveHandler: (e: MouseEvent) => void): () => void {
    const output = () => {
      element.style.cursor = 'grab';
      element.style.removeProperty('user-select');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', output);
    };
    return output;
  }
}
