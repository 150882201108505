import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { WaveConfig } from './wave-config.model';

@OVEntity({ name: 'WaveInstance' })
export class WaveInstance {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({ type: () => WaveConfig })
  waveConfig: WaveConfig;

  @OVField({ type: 'date' })
  startDate: string;
}
