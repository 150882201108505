<div class="map-panel">
  <div class="leaflet"
       leaflet
       [leafletFitBounds]="bounds"
       [leafletOptions]="options"
       (leafletMapReady)="onMapReady($event)"
       (change)="updateBounds()"
  >
    <div [leafletLayers]="lines"></div>
    <div [leafletLayers]="orderPinsArray"></div>
  </div>
</div>
