import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';
import { Memo } from '@ov-suite/helpers-shared';

@Pipe({ name: 'waveDate' })
export class WaveAllocationDatePipe implements PipeTransform {
  @Memo()
  transform(value: Date | string): string {
    return moment(value).format('dddd YYYY-MM-DD');
  }
}
