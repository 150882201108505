<div class="main-content">
  <div class="main-container mt-4">
  <div>
    <input [(ngModel)]="formKey" (change)="dirty()" />
    <button class="btn btn-primary" [disabled]="!activeForm" (click)="onSave()">
      Save
    </button>
  </div>
  <form-builder [form]="form.json" (change)="onChange($event)"></form-builder>
  <h3 class="text-center text-muted">As JSON Schema</h3>
  <div class="well jsonviewer">
    <pre id="json"><code class="language-json" #json></code></pre>
  </div>
  </div>
</div>
