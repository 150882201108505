import { Component, OnInit } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared';
import { Router } from '@angular/router';
import { ProcessDefinition } from '@ov-suite/models-warehouse';
import { ProcessDefinitionService } from '../process-definition.service';

@Component({
  selector: 'ov-suite-process-definitions',
  templateUrl: './process-definition.component.html',
  styleUrls: ['./process-definition.component.scss'],
})
export class ProcessDefinitionComponent implements OnInit {
  flowableModels: ProcessDefinition[] = [];

  apiUrl: string;

  constructor(private readonly router: Router, private readonly processDefinitionService: ProcessDefinitionService) {
    this.apiUrl = environment.apiUrl.warehouse;
  }

  ngOnInit(): void {
    this.getModels();
  }

  getModels(params: Object = {}) {
    this.processDefinitionService.listProcessDefinitions(params).then(response => {
      this.flowableModels = response.data;
    });
  }

  viewDetails(model: ProcessDefinition) {
    this.router.navigate(['workflow-processes', model.key]);
  }
}
