import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TableTopTabMenuModule } from '../../components/table-top-tab-menu/table-top-tab-menu.module';
import { VehicleTemplateService } from './vehicle-template.service';
import { VehiclesRoutes } from './vehicles.routing';
import { VehicleResourceListComponent } from './vehicle-resource-list/vehicle-resource-list.component';
import { VehicleTemplateListComponent } from './vehicle-template-list/vehicle-template-list.component';
import { VehicleTemplateCreateOrEditComponent } from './vehicle-template-create-or-edit/vehicle-template-create-or-edit.component';
import { VehicleExceptionListComponent } from './vehicle-exception-list/vehicle-exception-list.component';
import { VehicleExceptionAddOrEditComponent } from './vehicle-exception-add-or-edit/vehicle-exception-add-or-edit.component';
import { TemplateVehicleLineSelectorComponent } from './vehicle-template-create-or-edit/template-vehicle-line-selector/template-vehicle-line-selector.component';
import { VehicleExceptionViewModalComponent } from './vehicle-exception-view-modal/vehicle-exception-view-modal.component';
import { VehicleTableBulkActionComponent } from '../../components/vehicle-table-bulk-action/vehicle-table-bulk-action.component';
import { EmptyVehicleListComponent } from './empty-vehicle-component/empty-vehicle-list.component';

// TODO to be refactored
const temp = VehicleExceptionViewModalComponent;

@NgModule({
  imports: [CommonModule, RouterModule.forChild(VehiclesRoutes), FormsModule, UiModule, TableTopTabMenuModule, NgbDropdownModule],
  declarations: [
    VehicleResourceListComponent,
    VehicleTemplateListComponent,
    VehicleTemplateCreateOrEditComponent,
    TemplateVehicleLineSelectorComponent,
    VehicleExceptionListComponent,
    VehicleExceptionAddOrEditComponent,
    VehicleExceptionViewModalComponent,
    VehicleTableBulkActionComponent,
    EmptyVehicleListComponent,
  ],
  providers: [VehicleTemplateService],
})
export class VehiclesModule {}
