import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { WavesListComponent } from './list/waves-list.component';
import { WavesRoutes } from './waves.routing';
import { TableTopTabMenuModule } from '../../components/table-top-tab-menu/table-top-tab-menu.module';
import { CreateWaveModalComponent } from './create-wave-modal/create-wave-modal.component';
import { WaveAddOrEditComponent } from './add-or-edit/wave-add-or-edit.component';
import { WaveService } from './wave.service';
import { EmptyWaveListComponent } from './empty-wave-component/empty-wave-list.component';
import { WarehouseIconsModule } from '../../warehouse-icons.module';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(WavesRoutes), FormsModule, UiModule, TableTopTabMenuModule, WarehouseIconsModule],
  declarations: [WavesListComponent, CreateWaveModalComponent, WaveAddOrEditComponent, EmptyWaveListComponent],
  exports: [EmptyWaveListComponent],
  providers: [WaveService],
})
export class WavesModule {}
