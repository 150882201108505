import { Routes } from '@angular/router';
import { WavesListComponent } from './list/waves-list.component';
import { WaveAddOrEditComponent } from './add-or-edit/wave-add-or-edit.component';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { WarehousePermission } from '../../helpers/permissions.helpers';

export const WavesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: WarehousePermission.waves } },
        canActivate: [AuthGuard],
        path: '',
        component: WavesListComponent,
      },
      {
        data: { feature: { id: WarehousePermission.waves } },
        canActivate: [AuthGuard],
        path: 'add',
        component: WaveAddOrEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.waves } },
        canActivate: [AuthGuard],
        path: 'edit',
        component: WaveAddOrEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.waves } },
        canActivate: [AuthGuard],
        path: 'clone',
        component: WaveAddOrEditComponent,
      },
    ],
  },
];
