import { Component } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { InventoryCountModel } from '../models/inventory-count.model';

@Component({
  selector: 'ov-suite-inventory-product-sku',
  templateUrl: './inventory-product-sku-list.component.html',
  styleUrls: ['./inventory-product-sku-list.component.scss'],
})
export class InventoryProductSkuListComponent {
  parentId?: number;

  // Class - Required
  formClass = InventoryCountModel;

  tabs: HierarchyTab[] = [
    { title: 'Products', iconClass: '', path: '/inventory-product' },
    { title: 'Locations', iconClass: '', path: '/inventory-locations' },
  ];
}
