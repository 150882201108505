<div
  cdkDropList
  [id]="getId()"
  class="plan"
  [style]="{ left: layout.getLeftXPosition(slot.startTime) + 'px', right: layout.getRightXPosition(slot.startTime, slot.endTime) + 'px' }"
  cdkDropListOrientation="horizontal"
  [cdkDropListDisabled]="allocation.load.commitDate || !base.permissions.create"
  [cdkDropListData]="slot.pins"
  (cdkDropListDropped)="action.dropDirty($event, allocation)"
  [cdkDropListConnectedTo]="base.vehicleLists"
>
  <div *ngFor="let pin of slot.pins"
       [cdkDragData]="pin"
       class="plan-pin"
       cdkDrag
       [ngClass]="{'selected-customer': action.orderSelected.value === pin}"
       (cdkDragReleased)="action.releaseDirty($event, allocation)"
       (click)="action.selectOrder(pin)"
  >
    <span>{{pin.order.id}} {{ pin.order.customer.name }}</span>
    <span *ngIf="!allocation.load.commitDate" class="fa fa-times" (click)="data.resetOrder(pin, allocation)"></span>
  </div>
  <div class="line"></div>
  <div class="left-arrow"></div>
  <div class="right-arrow"></div>
</div>
