import {
 ChangeDetectorRef, Component, NgZone, OnInit,
} from '@angular/core';
import {
  latLng,
  LatLngBounds,
  MapOptions,
  tileLayer,
  circleMarker,
  CircleMarker,
  Map,
  control,
  LatLngTuple,
  LatLng, Polyline, polyline,
} from 'leaflet';
import { OvAutoService, OvAutoServiceMultipleParams } from '@ov-suite/services';
import { ActivatedRoute, Router } from '@angular/router';
import { getUpdate, getCreate } from '@ov-suite/graphql-helpers';
import { PageReturn } from '@ov-suite/ov-metadata';
import { CdkDragDrop, CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { MasterRoute } from '@ov-suite/models-warehouse';
import { Customer, Factory } from '@ov-suite/models-admin';
import { environment } from '@ov-suite/helpers-shared';

enum Filter {
  selected,
  unselected,
  unassigned,
  all
}

// interface CustomerMR extends CustomerOverride {
//   selected: boolean;
// }

@Component({
  selector: 'ov-suite-process-definitions',
  templateUrl: './master-route-add-edit.component.html',
  styleUrls: ['./master-route-add-edit.component.scss'],
})
export class MasterRouteAddEditComponent implements OnInit {
  styles = {
    factorySize: 6,
    orderSize: 9,
    highlightedCustomerSize: 13,

    displayOpacity: 1,
    hiddenOpacity: 0.1,
    hoverOpacity: 0.2,

    sourceFactoryColor: '#50E3C2',
    destinationFactoryColor: '#5F59F7',
    unusedFactoryColor: 'grey',
    highlightedCustomerColor: '#bf73de',
    selectedCustomerColor: '#FCD861',
    unselectedCustomerColor: '#A9E5FF',
    noRouteCustomerColor: '#FC5153',
    greyButtonColor: '#888888',
  };

  // Leaflet Map
  map: Map;

  origin: MasterRoute;

  data: MasterRoute = new MasterRoute();

  // List of All Customers
  customersMap: Record<number, Customer> = {};

  customersArray: Customer[] = [];

  // List of All Displayed Customers
  displayedCustomersMap: Record<number, Customer> = {};

  displayedCustomersArray: Customer[] = [];

  // Filters
  filter: Filter = Filter.all;

  searchTerm = '';

  // List of All Factories
  factories: Factory[] = [];

  // Source Factory
  set source(input: Factory) {
    // A setter is used for better integration with ngModel
    this.setSource(input);
  }

  get source() {
    return this.data.source;
  }

  // Destination Factory
  set destination(input: Factory) {
    // A setter is used for better integration with ngModel
    this.setDestination(input);
  }

  get destination() {
    return this.data.destination;
  }

  // Map of Customers Ticked
  tickedCustomersMap: Record<string, Customer> = {};

  allTicked = false;

  // Array and Corresponding Map of Selected Customers for current Route
  selectedCustomersMap: Record<string, Customer> = {};

  selectedCustomersArray: Customer[] = [];

  // Factory Pin Management
  factoryPinMap: Record<number, CircleMarker> = {};

  factoryPinArray: CircleMarker[] = [];

  // Customer Pin Management
  customerPinMap: Record<number, CircleMarker> = {};

  customerPinArray: CircleMarker[] = [];

  // Line Management
  lineArray: Polyline[] = [];

  previewArray: Polyline[] = null;

  // Used to control what the map shows, Set when factories are loaded
  bounds: LatLngBounds = null;

  dragArray: Customer[] = [];

  dragData: Customer = null;

  dragHover: Customer = null;

  // Leaflet Options
  options: MapOptions = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...', minZoom: 4 }),
    ],
    // center: latLng(0, 0),
    // zoom: 2,
    zoomControl: false,
    doubleClickZoom: false,
  };

  topBarStyle = {
    'background-color': 'red',
    'border-width': 1,
    'border-color': 'red',
  };

  constructor(
    private readonly ngZone: NgZone,
    private readonly cdRef: ChangeDetectorRef,
    private readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  bringSourceAndDestinationToFront(): void {
    if (this.destination) {
      this.factoryPinMap[this.destination.id].bringToFront();
    }
    if (this.source) {
      this.factoryPinMap[this.source.id].bringToFront();
    }
  }

  ngOnInit() {
    this.initialize();
  }

  initialize(): void {
    const multipleParams: OvAutoServiceMultipleParams = {
      factory: {
        type: 'list',
        entity: Factory,
        limit: 10000,
        keys: ['id', 'name', 'map'],
      },
      customer: {
        type: 'list',
        entity: Customer,
        limit: 10000,
        keys: ['id', 'name', 'map', 'customerCode', 'masterRoutes.id'],
        orderColumn: 'name',
      },
    };

    this.route.queryParamMap.subscribe(params => {
      if (params.has('id')) {
        multipleParams.masterRoute = {
          type: 'get',
          entity: MasterRoute,
          id: Number(params.get('id')),
          keys: ['id', 'name', 'customerIds', 'sourceId', 'destinationId'],
        };
      }
      this.ovAutoService.multipleFetch(multipleParams).then(response => {
        this.setCustomers((response.customer as PageReturn<Customer>).data);
        this.displayedCustomersArray = [...this.customersArray];
        this.setFactories((response.factory as PageReturn<Factory>).data);
        if (response.masterRoute) {
          this.origin = { ...response.masterRoute as MasterRoute };
          this.setData(response.masterRoute as MasterRoute);
        }
        this.sortSelected();
        this.updateBounds();
      });
    });
  }

  // Setters
  setData(input: MasterRoute): void {
    input.source = this.factories.find(i => i.id === input.sourceId);
    input.destination = this.factories.find(i => i.id === input.destinationId);
    input.customers = input.customerIds?.map(id => this.customersMap[id]) ?? [];
    this.selectedCustomersMap = {};
    this.selectedCustomersArray = [...input.customers];
    input.customers.forEach(i => this.selectedCustomersMap[i.id] = i);
    this.data = input;
    this.updatePinsForCustomers();
  }

  setCustomers(input: Customer[]): void {
    this.customersMap = {};
    input.forEach(i => this.customersMap[i.id] = i);
    this.customersArray = input;
    this.setupPinsForCustomers();
  }

  setFactories(input: Factory[]): void {
     this.factories = input;
     this.setupPinsForFactories();
  }

  setSource(input: Factory): void {
    if (this.data.source) {
      if (this.data.source === this.data.destination) {
        this.factoryPinMap[this.data.source.id].setStyle({ fillColor: this.styles.destinationFactoryColor });
      } else {
        this.factoryPinMap[this.data.source.id].setStyle({ fillColor: this.styles.unusedFactoryColor });
      }
    }
    this.data.source = input;
    this.data.sourceId = input?.id ?? null;
    if (input) {
      this.factoryPinMap[input.id].setStyle({ fillColor: this.styles.sourceFactoryColor });
    }
    this.bringSourceAndDestinationToFront();
    this.drawLines();
    this.updateBounds();
  }

  setDestination(input: Factory): void {
    if (this.data.destination && this.data.destination !== this.data.source) {
      this.factoryPinMap[this.data.destination.id].setStyle({ fillColor: this.styles.unusedFactoryColor });
    }
    this.data.destination = input;
    this.data.destinationId = input?.id ?? null;
    if (input !== this.data.source) {
      this.factoryPinMap[input.id].setStyle({ fillColor: this.styles.destinationFactoryColor });
    }
    this.bringSourceAndDestinationToFront();
    this.drawLines();
    this.updateBounds();
  }

  setSelectedCustomers(input: Customer[]): void {
    this.selectedCustomersArray = input;
    this.selectedCustomersMap = {};
    input.forEach(i => this.selectedCustomersMap[i.id] = i);
    this.data.customers = input;
  }

  // Selected Customers
  addSelectedCustomer(input: Customer | Customer[]): void {
    this.ngZone.run(() => {
      const evaluate = (customer: Customer) => {
        if (!this.selectedCustomersMap[customer.id]) {
          this.selectedCustomersMap[customer.id] = customer;
          this.selectedCustomersArray.push(customer);
          this.data.customers = this.selectedCustomersArray;
          const color = this.getCustomerPinColor(customer.id);
          this.customerPinMap[customer.id].setStyle({ fillColor: color });
        }
      };

      if (Array.isArray(input)) {
        input.forEach(evaluate);
      } else {
        evaluate(input);
      }
      this.sortSelected();
      this.drawLines();
    });
  }

  removeSelectedCustomer(input: Customer | Customer[]): void {
    this.ngZone.run(() => {
      const evaluate = (customer: Customer) => {
        if (this.selectedCustomersMap[customer.id]) {
          delete this.selectedCustomersMap[customer.id];
          this.selectedCustomersArray = this.selectedCustomersArray.filter(i => i.id !== customer.id);
          this.data.customers = this.selectedCustomersArray;
          const color = this.getCustomerPinColor(customer.id);
          this.customerPinMap[customer.id].setStyle({ fillColor: color });
        }
      };

      if (Array.isArray(input)) {
        input.forEach(evaluate);
      } else {
        evaluate(input);
      }
      this.sortSelected();
      this.drawLines();
    });
  }

  // Sets the boundary for the map to Either: Source + Destination + Selected Customers or All Factories
  updateBounds() {
    this.ngZone.run(() => {
      const setBounds = () => {
        const selectedPins: [number, number][] = this.selectedCustomersArray.map(i => {
          const { longitude, latitude } = i.map;
          return [latitude, longitude];
        });
        const { longitude: sLong, latitude: sLat } = this.source.map;
        if (this.source === this.destination) {
          this.bounds = new LatLngBounds([[sLat, sLong], ...selectedPins]);
        } else {
          const { longitude: dLong, latitude: dLat } = this.destination.map;
          this.bounds = new LatLngBounds([[sLat, sLong], [dLat, dLong], ...selectedPins]);
        }
      };

      if (this.source && this.destination) {
        setBounds();
      } else if (!this.source && !this.destination && this.factoryPinArray.length) {
        const bounds: [number, number][] = this.factoryPinArray.map(i => {
          if(!i.getLatLng()) {
            return [null, null];
          }
          const { lat, lng } = i.getLatLng();
          return [lat, lng];
        });
        this.bounds = new LatLngBounds(bounds);
      }
    });
  }

  // Pin Setup Functions
  setupPinsForCustomers() {
    this.customersArray.forEach(customer => {
      this.customerPinMap[customer.id] = this.createPinForCustomer(customer);
    });
    this.customerPinArray = Object.values(this.customerPinMap);
  }

  setupPinsForFactories() {
    this.factories.forEach(factory => {
      this.factoryPinMap[factory.id] = this.createPinForFactory(factory);
    });
    this.factoryPinArray = Object.values(this.factoryPinMap);
  }

  updatePinsForCustomers() {
    this.data.customers.forEach(i => {
      this.customerPinMap[i.id].setStyle({ fillColor: this.styles.selectedCustomerColor });
    });
  }

  // Individual Pin Setup Functions
  createPinForCustomer(customer: Customer): CircleMarker {
    const color = this.getCustomerPinColor(customer.id, false);
    const { latitude, longitude } = customer?.map;
    return circleMarker([latitude, longitude], {
      color: 'black',
      fillColor: color,
      fillOpacity: this.styles.displayOpacity,
      opacity: 0.2,
      radius: this.styles.orderSize,
      weight: 1,
    })
      .bindTooltip(customer.name)
      .on('click', () => this.toggleCustomer(customer));
  }

  createPinForFactory(factory: Factory): CircleMarker {
    let color = this.styles.unusedFactoryColor;
    if (factory === this.source) color = this.styles.sourceFactoryColor;
    if (factory === this.destination) color = this.styles.destinationFactoryColor;
    const { latitude, longitude } = factory.map;
    return circleMarker([latitude, longitude], {
 color: 'white', fillColor: color, fillOpacity: this.styles.displayOpacity, radius: this.styles.factorySize, weight: 1,
})
      .bindTooltip(factory.name);
  }

  // Fires when map is loaded
  onMapReady(map: Map): void {
    this.map = map;
    map.addControl(control.zoom({ position: 'bottomright' }));
  }

  // Fired on SidePanel Click
  focusCustomer(event: MouseEvent, customer: Customer): void {
    event.stopPropagation();
    const target = latLng(customer.map.latitude, customer.map.longitude);
    this.map.panTo(target, { animate: true });
  }

  // Hover Actions
  customerOnHover(customer: Customer) {
    this.customerPinMap[customer.id]
      .setStyle({
        className: 'pin-hover',
        weight: 5,
      })
      .setRadius(this.styles.highlightedCustomerSize);
  }

  customerOffHover(customer: Customer) {
    this.customerPinMap[customer.id]
      .setStyle({
        weight: 1,
      })
      .setRadius(this.styles.orderSize);
  }

  // Click Pin or Double Click Table
  toggleCustomer(customer: Customer): void {
    if (this.selectedCustomersMap[customer.id]) {
      this.removeSelectedCustomer(customer);
    } else {
      this.addSelectedCustomer(customer);
    }
  }

  // Styles
  getHoverOpacity(id: number, hovering = false): number {
    if (hovering) {
      return this.styles.hoverOpacity;
    }
    if (this.displayedCustomersMap[id]) {
      return this.styles.displayOpacity;
    }
    return this.styles.hiddenOpacity;
  }

  getCustomerPinColor(id: number, hovering = false): string {
    if (hovering) {
      return this.styles.highlightedCustomerColor;
    }
    if (this.selectedCustomersMap[id]) {
      return this.styles.selectedCustomerColor;
    }
    // if (this.customersMap[id]?.masterRoutes?.length) {
    //   return this.styles.unselectedCustomerColor;
    // }
      return this.styles.noRouteCustomerColor;
  }

  tickCustomer(customer: Customer) {

    if (this.tickedCustomersMap[customer.id]) {
      this.removeSelectedCustomer(customer);
      delete this.tickedCustomersMap[customer.id];
    } else {
      this.tickedCustomersMap[customer.id] = customer;
      this.addSelectedCustomer(customer);
    }
    this.allTicked = Object.keys(this.tickedCustomersMap).length === this.customersArray.length;
  }

  customerCheckBoxClick(event: MouseEvent, customer: Customer): void {
    event.stopPropagation();
    this.tickCustomer(customer);
  }

  customerDoubleClick(event: MouseEvent, customer: Customer): void {
    event.stopPropagation();
    this.toggleCustomer(customer);
  }

  save() {
    const onSuccess = () => {
      this.router.navigate(['/master-route']);
    };
    const keys = ['id', 'name', 'customerIds', 'sourceId', 'destinationId'];
    if (this.data.id) {
      this.ovAutoService.update({ entity: MasterRoute, item: getUpdate(this.data, this.origin), keys }).then(onSuccess);
    } else {
      this.ovAutoService.create({ entity: MasterRoute, item: getCreate(this.data), keys }).then(onSuccess);
    }
  }

  // Bulk Actions
  // bulkSelect(): void {
  //   this.addSelectedCustomer(Object.values(this.tickedCustomersMap));
  // }
  //
  // bulkDeselect(): void {
  //   this.removeSelectedCustomer(Object.values(this.tickedCustomersMap));
  // }

  bulkFocus() {
    const tickedPins: [number, number][] = Object.values(this.tickedCustomersMap).map(i => {
      const { longitude, latitude } = i.map;
      return [latitude, longitude];
    });
    this.bounds = new LatLngBounds(tickedPins);
  }

  bulkClear() {
    this.tickedCustomersMap = {};
  }

  resetDisplayedCustomers(): void {
    this.displayedCustomersArray = [...this.customersArray];
    this.sortSelected();
    this.displayedCustomersMap = {};
    this.displayedCustomersArray.forEach(customer => {
      this.displayedCustomersMap[customer.id] = customer;
    });
    Object.entries(this.customerPinMap).forEach(([id, customerPin]) => {
      const customer = this.customersMap[id];
      customerPin.setStyle({ fillOpacity: this.styles.displayOpacity })
        .off()
        .bindTooltip(customer.name)
        .on('click', () => this.toggleCustomer(customer));
    });
  }

  onSearch(): void {
    this.applyFilters();
  }

  applyFilters(): void {
    if (!this.searchTerm && this.filter === Filter.all) {
      this.updateBounds();
      this.tickedCustomersMap = {};
      this.allTicked = false;
      return this.resetDisplayedCustomers();
    }
    const newBounds: LatLngTuple[] = [];
    this.displayedCustomersArray = this.customersArray.filter(customer => {
      let isFound = true;

      if (this.filter !== Filter.all) {
        if (this.selectedCustomersMap[customer.id]) {
          isFound = this.filter === Filter.selected;
        }
        // else if (customer.masterRoutes.length) {
        //   isFound = this.filter === Filter.unselected;
        // }
        else {
          isFound = this.filter === Filter.unassigned;
        }
      }

      if (this.searchTerm && isFound) {
        const searchTerm = this.searchTerm.toUpperCase();
        isFound = customer.name.toUpperCase().includes(searchTerm) || customer.customerCode.includes(searchTerm);
      }
      const pin = this.customerPinMap[customer.id];
      if (isFound) {
        const { lat, lng } = pin?.getLatLng();
        newBounds.push([lat, lng]);
        this.enablePin(pin, customer);
      } else {
        this.disablePin(pin);
      }
      return isFound;
    });
    this.sortSelected();
    if (newBounds.length) {
      this.map.panInsideBounds(new LatLngBounds(newBounds));
    }
    this.tickedCustomersMap = {};
    this.allTicked = false;
  }

  // Enable And Disable Customer Pins
  disablePin(pin: CircleMarker): CircleMarker {
    return pin.setStyle({ fillOpacity: this.styles.hiddenOpacity }).off();
  }

  enablePin(pin: CircleMarker, customer: Customer): CircleMarker {
    return pin.setStyle({ fillOpacity: this.styles.displayOpacity })
      .off()
      .bindTooltip(customer.name)
      .on('click', () => this.toggleCustomer(customer));
  }

  // Filter Actions
  filterSelected(): void {
    this.filter = Filter.selected;
    this.applyFilters();
  }

  filterUnselected(): void {
    this.filter = Filter.unselected;
    this.applyFilters();
  }

  filterUnAssigned(): void {
    this.filter = Filter.unassigned;
    this.applyFilters();
  }

  filterClear(): void {
    this.filter = Filter.all;
    this.applyFilters();
  }

  toggleAll() {
    this.allTicked = !this.allTicked;
    if (this.allTicked) {
      this.displayedCustomersArray.forEach(customer => this.tickedCustomersMap[customer.id] = customer);
    } else {
      this.tickedCustomersMap = {};
    }
  }

  drop(event: CdkDragDrop<Customer[]>) {
    const maxIndex = this.selectedCustomersArray.length - 1;
    const currentIndex = event.currentIndex > maxIndex ? maxIndex : event.currentIndex;
    if (currentIndex !== event.previousIndex) {
      moveItemInArray(this.displayedCustomersArray, event.previousIndex, currentIndex);
      this.selectedCustomersArray = this.displayedCustomersArray.slice(0, maxIndex + 1);
      this.data.customers = this.selectedCustomersArray;
    }
    this.drawLines();
  }

  drawLines(): void {
    const coords: LatLng[] = [];
    if (this.source) {
      coords.push(this.factoryPinMap[this.source.id].getLatLng());
    }

    this.data.customers?.forEach(customer => {
      coords.push(this.customerPinMap[customer.id].getLatLng());
    });

    if (this.destination) {
      coords.push(this.factoryPinMap[this.destination.id].getLatLng());
    }

    this.lineArray = [];
    coords.forEach((set, index) => {
      if (index < coords.length - 1) {
        this.lineArray.push(
          polyline([set, coords[index + 1]], { color: '#0091FF', opacity: 0.6 }),
        );
      }
    });

    setTimeout(() => this.lineArray.forEach(i => i.bringToBack()), 1);
  }

  sortSelected(): void {
    this.ngZone.run(() => {
      const baseOrder = {};
      this.selectedCustomersArray.forEach((item, index) => {
        baseOrder[item.id] = index;
      });
      this.displayedCustomersArray.sort((a, b) => {
        const selectedA = this.selectedCustomersMap[a.id];
        const selectedB = this.selectedCustomersMap[b.id];
        if (selectedA && selectedB) {
          return baseOrder[a.id] - baseOrder[b.id];
        } if (selectedA) {
          return -1;
        } if (selectedB) {
          return 1;
        }
          if (a.name > b.name) {
            return 1;
          } if (b.name > a.name) {
            return -1;
          }
            return 0;
      });
      this.drawLines();
    });
  }

  onStartDrag(customer: Customer): void {
    this.dragData = customer;
    this.dragArray = [...this.displayedCustomersArray.slice(0, this.data.customers.length)];
  }

  onEndDrag(): void {
    this.dragData = null;
    this.previewArray = null;
  }

  onDragEnter(event: CdkDragSortEvent<Customer[]>): void {
    this.ngZone.run(() => {
      moveItemInArray(this.dragArray, event.previousIndex, event.currentIndex);

      const coords: LatLng[] = [];
      if (this.source) {
        coords.push(this.factoryPinMap[this.source.id].getLatLng());
      }

      this.dragArray?.forEach(customer => {
        coords.push(this.customerPinMap[customer.id].getLatLng());
      });

      if (this.destination) {
        coords.push(this.factoryPinMap[this.destination.id].getLatLng());
      }

      this.previewArray = [];
      coords.forEach((set, index) => {
        if (index < coords.length - 1) {
          this.previewArray.push(
            polyline([set, coords[index + 1]], { color: 'red', opacity: 0.3 }),
          );
        }
      });
    });
  }

  OpenAdminLink() {
    // navigate to Admin Link - customers
    window.open(`${environment.webUrl.admin}/customer`, '_blank');
  }

  cancel(): void {
    window.history.back();
  }
}
