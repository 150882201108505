import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function releaseForPickingGql(): DocumentNode {
  return gql(
    `mutation ReleaseForPicking($loads: [LoadAllocationUpdateInput!]!) {
      releaseForPicking(loads: $loads) { ok }
      }`,
  );
}
