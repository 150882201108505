import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { AngularSplitModule } from 'angular-split';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableTopTabMenuModule } from '../../components/table-top-tab-menu/table-top-tab-menu.module';
import { WaveAllocationRoutes } from './wave-allocation.routing';
import { WaveAllocationLoadsComponent } from './wave-allocation-loads/wave-allocation-loads.component';
import { WaveAllocationMainComponent } from './wave-allocation-main/wave-allocation-main.component';
import { WaveAllocationDataService } from './wave-allocation.data.service';
import { WaveAllocationPlannerComponent } from './wave-allocation-planner/wave-allocation-planner.component';
import { WaveAllocationWaveBayComponent } from './wave-allocation-wave-bay/wave-allocation-wave-bay.component';
import { WaveAllocationBaseService } from './wave-allocation.base.service';
import { WaveAllocationActionService } from './wave-allocation.action.service';
import { WaveAllocationDatePipe } from './wave-allocation.date-pipe';
import { WaveAllocationDetailComponent } from './wave-allocation-detail/wave-allocation-detail.component';
import { WaveAllocationVehicleChangeComponent } from './wave-allocation-vehicle-change/wave-allocation-vehicle-change.component';
import { WaveAllocationLoadSheetComponent } from './wave-allocation-load-sheet/wave-allocation-load-sheet.component';

@NgModule({
  imports: [
    AngularSplitModule.forRoot(),
    CommonModule,
    RouterModule.forChild(WaveAllocationRoutes),
    FormsModule,
    UiModule,
    TableTopTabMenuModule,
    DragDropModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbPopoverModule,
  ],
  declarations: [
    WaveAllocationLoadsComponent,
    WaveAllocationMainComponent,
    WaveAllocationPlannerComponent,
    WaveAllocationWaveBayComponent,
    WaveAllocationDatePipe,
    WaveAllocationDetailComponent,
    WaveAllocationVehicleChangeComponent,
    WaveAllocationLoadSheetComponent
  ],
  providers: [OvAutoService, WaveAllocationDataService, WaveAllocationBaseService, WaveAllocationActionService],
})
export class WaveAllocationModule {}
