import {
  Component,
} from '@angular/core';
import { environment } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-empty-vehicle-list',
  templateUrl: './empty-vehicle-list.component.html',
  styleUrls: ['./empty-vehicle-list.component.scss'],
})
export class EmptyVehicleListComponent {
  constructor() {}

  OpenAdminLink() {
    // navigate to Admin Link - vehicles
    window.open(`${environment.webUrl.admin}/vehicle`, '_blank');
  }
}
