import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { VehicleTemplateListComponent } from './vehicle-template-list/vehicle-template-list.component';
import { VehicleResourceListComponent } from './vehicle-resource-list/vehicle-resource-list.component';
import { VehicleTemplateCreateOrEditComponent } from './vehicle-template-create-or-edit/vehicle-template-create-or-edit.component';
import { VehicleExceptionListComponent } from './vehicle-exception-list/vehicle-exception-list.component';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { VehicleExceptionAddOrEditComponent } from './vehicle-exception-add-or-edit/vehicle-exception-add-or-edit.component';

export const VehiclesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: '',
        component: VehicleResourceListComponent,
      },
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: 'templates/add',
        component: VehicleTemplateCreateOrEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: 'templates/edit',
        component: VehicleTemplateCreateOrEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: 'templates',
        component: VehicleTemplateListComponent,
      },
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: 'exception',
        component: VehicleExceptionListComponent,
      },
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: 'exception/add',
        component: VehicleExceptionAddOrEditComponent,
      },
      {
        data: { feature: { id: WarehousePermission.vehicles } },
        canActivate: [AuthGuard],
        path: 'exception/edit',
        component: VehicleExceptionAddOrEditComponent,
      },
    ],
  },
];
