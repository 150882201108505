import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { ProductCategory } from './product-category.model';

@OVEntity({ name: 'ProductStatus', tableName: 'product_status' })
export class ProductStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'Product' })
@OVForm([
  ['#Product Details'],
  ['productId', 'name'],
  ['description'],
  ['category'],
  ['#Measurements'],
  ['weight', '', ''],
  ['length', 'height', 'width'],
  ['barcodeType', 'barcodeData'],
  ['qrPreview'],
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Product>([
  {
    key: 'productId',
    title: 'Product ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Product Name',
    type: 'string',
  },
  {
    title: 'Category',
    type: 'other',
    action: item => item?.category?.name ?? 'none',
    routerLink: () => ['/product-category/edit'],
    keys: ['category.name'],
    queryParams: item => ({ id: item?.category?.id }),
  },
])
export class Product {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Product Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Product ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  productId: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => ProductStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: ProductStatus;

  @OVField({
    type: () => ProductCategory,
    tooltip: 'Add a single category for this product.',
    selectionType: 'single',
    title: 'Category',
    required: true,
  })
  category: ProductCategory;

  @OVField({
    type: 'number',
    title: 'Weight',
    step: '0.0001',
  })
  weight: number;

  @OVField({
    type: 'number',
    title: 'Length',
    step: '0.0001',
  })
  length: number;

  @OVField({
    type: 'number',
    title: 'Height',
    step: '0.0001',
  })
  height: number;

  @OVField({
    type: 'number',
    title: 'Width',
    step: '0.0001',
  })
  width: number;

  @OVField({
    type: 'string',
    title: 'Barcode Type',
  })
  barcodeType: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Value',
  })
  barcodeData: string;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;
}
