<div class="loads">
  <div class="header-bar">
    <div class="header-left">
      <h5> Loads </h5>
    </div>
    <div class="header-right">
      <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button id="wave-allocation-filter-btn" class="fa fa-filter btn-primary mr-3" ngbDropdownToggle></button>
        <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="filter-dropdown">
          <div class="p-2 text-center">
            <span class="ml-2">Customer Name or Code</span>
            <ov-suite-input
              type="text" placeholder="Search"
              [(ngModel)]="dataService.loadsFilter.customerSearchTerm"
              (change)="filterChange()">
            </ov-suite-input>
            <div>
              <span class="ml-2">Date Range</span>
              <ov-suite-input
                #dateRange
                type="date-range"
                placeholder="Date Range"
                [(ngModel)]="dataService.loadsDateRange"
                (ngModelChange)="filterChange()"
                hideClearButton="true">
              </ov-suite-input>
            </div>
          </div>
          <div class="text-center">
            <button (click)="clearLoadsFilter()" class="btn-sm btn-dark mb-3">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-striped">
    <thead>
      <tr>
        <th *ngFor="let col of dataService.loadPlanColumns" scope="col" (click)="onColumnHeaderClick(col.name)">
          {{ col.name }}
          <i
            *ngIf="dataService.sortingData.column !== col.name"
            class="fa fa-fw fa-sort" aria-hidden="true"
          ></i>
          <i
            *ngIf="dataService.sortingData.column === col.name"
            class="fa fa-fw"
            [ngClass]="{
              'fa-sort-asc': dataService.sortingData.direction === 'ASC',
              'fa-sort-desc': dataService.sortingData.direction === 'DESC'
            }" aria-hidden="true"
          ></i>
        </th>
      </tr>
    </thead>
    <tbody
      cdkDropList
      id="load-list"
      class="wave-bay-plan"
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="dataService.loads.value"
      [cdkDropListConnectedTo]="baseService.lists"
      (cdkDropListDropped)="actionService.drop($event, null, null)"
      [cdkDropListDisabled]="!baseService.permissions.create"
    >
      <tr *ngFor="let loadPlan of dataService.loads.value"
          [cdkDragData]="loadPlan"
          (click)="actionService.selectLoad(loadPlan.load)"
          class="wave-bay-pin"
          cdkDrag>
        <td>{{ loadPlan.load.id }}</td>
        <td>{{ loadPlan.load.getVehicle()?.registration }}</td>
        <td>{{ loadPlan.load.date }}</td>
        <td>{{ loadPlan.load.orders.length }}</td>
      </tr>
    </tbody>
  </table>
</div>
