import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';

@OVTable<InventoryLocationCountModel>([
  {
    title: 'Name',
    type: 'string',
    key: 'name',
  },
  {
    title: 'description',
    type: 'string',
    key: 'description',
  },
  // {
  //   title: 'Last Updated',
  //   type: 'date-time',
  //   key: 'timestamp',
  // },
  {
    title: 'Pallet Count',
    type: 'number',
    key: 'palletCount'
  },
  // {
  //   title: 'Total Count',
  //   type: 'number',
  //   key: 'totalCount',
  // },
  // {
  //   title: 'Promise Count',
  //   type: 'number',
  //   key: 'promiseCount',
  // },
  // {
  //   title: 'On Hand',
  //   type: 'other',
  //   action: (val) => `${val.totalCount - val.promiseCount}`,
  //   keys: ['totalCount', 'promiseCount'],
  // },
  // {
  //   title: 'Status',
  //   type: 'other',
  //   action: val => {
  //     switch (val.rag) {
  //       case Rag.amber:
  //         return 'amber';
  //       case Rag.green:
  //         return 'green'
  //       case Rag.red:
  //         return 'red'
  //       default:
  //         return 'missing'
  //     }
  //   },
  //   keys: ['rag']
  // }
])
@OVEntity({ name: 'InventoryLocationCount' })
export class InventoryLocationCountModel {

  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description: string;

  @OVField({ type: 'number' })
  palletCount: number;

  // @OVField({ type: 'number' })
  // totalCount: number;
}
