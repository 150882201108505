import { Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalVehicle, LoadAllocation } from '@ov-suite/models-warehouse';
import { getCreate } from '@ov-suite/graphql-helpers';
import moment from 'moment';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { VehicleAllocation } from '../load-allocation.interface';
import { LoadAllocationDataService } from '../load-allocation.data.service';

@Component({
  selector: 'ov-suite-external-vehicle-create',
  templateUrl: './external-vehicle-create.component.html',
  styleUrls: ['./external-vehicle-create.component.scss'],
})
export class ExternalVehicleCreateComponent implements OnInit {
  formClass = ExternalVehicle;

  active = 1;

  today: string;

  selected: ExternalVehicle[] = [];

  externalVehicle: ExternalVehicle;

  loading: boolean;

  constructor(
    public readonly ovAutoService: OvAutoService,
    public readonly activeModal: NgbActiveModal,
    public readonly base: LoadAllocationBaseService,
    public readonly data: LoadAllocationDataService,
  ) {}

  ngOnInit() {
    this.today = moment().format('yyyy-MM-DD');
  }

  onCancel() {
    this.activeModal.close();
  }

  onSelect(items) {
    this.selected = items;
  }

  onAddToLoad() {
    const vehicleAllocations = [];
    this.selected.forEach(vehicle => {
      const load = LoadAllocation.fromExternalVehicle(vehicle, this.today);
      const vehicleAllocation = VehicleAllocation.fromLoadAllocation(load);
      vehicleAllocation.load = load;
      vehicleAllocations.push(vehicleAllocation);
    });
    // todo: Move this logic to Data Service
    this.data.vehicleAllocation.value = this.data.vehicleAllocation.value.concat(...vehicleAllocations);
    this.base.updateLists();
    this.activeModal.close();
  }

  onSave(event: ExternalVehicle) {
    this.loading = true;
    const keys = ['id', 'model', 'make', 'vehicleClass', 'startTime', 'endTime', 'weightLimit', 'dimensions'];
    this.ovAutoService.create({ entity: ExternalVehicle, item: getCreate<ExternalVehicle>(event), keys }).then(response => {
      this.loading = false;
      event.id = response.id;
      this.selected = [event];
      // add to load
      this.onAddToLoad();
      this.activeModal.dismiss();
    });
  }
}
