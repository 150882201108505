import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FieldMetadata } from '@ov-suite/ov-metadata';
import { ApiOptions } from '@ov-suite/ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface UploadResponse {
  success: boolean;
  error: string;
  other: unknown;
}

@Component({
  selector: 'ov-suite-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
})
export class BulkUploadComponent implements OnInit {
  @Input() exportFunc = () => {
    console.log('test');
    this.exportAll('/api/bulk-upload/export', 'Export').then();
  };

  @Input() metadata: FieldMetadata;

  @Input() fileName: string;

  @ViewChild('content') content;

  @Input() set trigger(trigger: number) {
    if (trigger) this.open(this.content);
  }

  @Input() set callExport(trigger: number) {
    if (trigger) this.exportAll('/api/bulk-upload/export', 'Export').then();
  }

  @Input() set filterQuery(query: Record<string, (string | number)[]>) {
    if (query) this.queryFilterOn = query;
  }

  @Input() set searchQuery(query: Record<string, (string | number)[]>) {
    if (query) this.querySearchOn = query;
  }

  @Output() hasUploaded = new EventEmitter();

  queryFilterOn: Record<string, (string | number)[]>;

  querySearchOn: Record<string, (string | number)[]>;

  currentFiles: FileList;

  errors = [];

  loading: boolean;

  uploaded: boolean;

  closeResult: string;

  constructor(private readonly modalService: NgbModal, @Inject('OPTIONS') private readonly options: ApiOptions, protected http: HttpClient) {}

  ngOnInit() {}

  getDismissReason(reason: unknown): string {
    this.hasUploaded.emit(this.uploaded);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    }
    if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    }
    return `with: ${reason}`;
  }

  open(content: unknown) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  async downloadTemplate() {
    if (!this.metadata) {
      return;
    }
    await this.exportAll('/api/bulk-upload/template', 'Template');
  }

  async exportAll(apiUrl: string, templateName: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    await this.http
      .post<Blob>(
        this.options.apiUrl + apiUrl,
        { className: this.metadata.name, query: this.queryFilterOn, search: this.querySearchOn },
        { headers, responseType: 'blob' as 'json' },
      )
      .subscribe(
        blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);
          a.href = url;
          a.download = `${this.metadata.name + templateName}.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
        },
        err => console.log({ err }),
      );
  }

  onFileChange(event: Event) {
    const target: DataTransfer = (event.target as unknown) as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot upload multiple files.');
    }
    this.currentFiles = (event?.target as HTMLInputElement)?.files;
  }

  onFileUploadClick() {
    document.getElementById('xlxs-file-reader').click();
  }

  onFileSave() {
    const form = new FormData();
    form.append('file', this.currentFiles.item(0));
    form.append('data', JSON.stringify({ className: this.metadata.name }));
    this.loading = true;
    this.uploaded = false;

    this.http.post(`${this.options.apiUrl}/api/bulk-upload/upload`, form).subscribe(
      (res: UploadResponse) => {
        if (res.success) {
          this.uploaded = true;
        } else {
          this.errors = JSON.parse(res.error);
        }
        this.loading = false;
      },
      err => {
        if (err.error && err.error.error) {
          this.errors = JSON.parse(err.error.error);
        }
        this.loading = false;
      },
    );
  }

  back() {
    this.hasUploaded.emit(this.uploaded);
    this.modalService.dismissAll();
  }
}
