import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { ProductConfiguration } from './product-configuration.model';
import { ProductSkuConfiguration } from './product-sku-configuration.model';

@OVEntity({ name: 'ProductSkuStatus', tableName: 'product_sku_status' })
export class ProductSkuStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'ProductSku', tableName: 'product_sku' })
@OVForm([
  ['#Sku Details'],
  ['sku', 'name'],
  ['description'],
  ['salesUnit', 'storageUnit', ''],
  ['#Packing Measurements'],
  ['weight', ''],
  ['length', 'height', 'width'],
  ['#Products & SKUs'],
  ['productConfigurations'],
  ['productSkuConfigurations'],
  ['barcodeType', 'barcodeData'],
  ['qrPreview'],
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<ProductSku>([
  {
    key: 'sku',
    title: 'SKU',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    title: 'Weight',
    type: 'other',
    action: item => (item.weight ? `${item.weight} kg` : ''),
    keys: ['weight'],
  },
  {
    title: 'Dimensions',
    type: 'other',
    action: item => {
      const message = `${item.length} mm x ${item.height} mm x ${item.width} mm`;
      return message.includes('null') ? '' : message;
    },
    disableFiltering: true,
    keys: ['length', 'height', 'width'],
  },
  {
    title: 'Barcode',
    type: 'string',
    key: 'barcodeData',
  },
])
export class ProductSku {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'SKU',
    tooltip: 'Stock Keeping Unit.',
    placeholder: 'Required',
    required: true,
  })
  sku: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => ProductSkuStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: ProductSkuStatus;

  @OVField({
    type: 'number',
    title: 'Weight',
    step: '0.0001',
    tooltip: 'grams'
  })
  weight: number;

  @OVField({
    type: 'number',
    title: 'Length',
    step: '0.0001',
    tooltip: 'centimeters'
  })
  length: number;

  @OVField({
    type: 'number',
    title: 'Height',
    step: '0.0001',
    tooltip: 'centimeters'
  })
  height: number;

  @OVField({
    type: 'number',
    title: 'Width',
    step: '0.0001',
    tooltip: 'centimeters'
  })
  width: number;

  @OVField({
    type: 'boolean',
    title: 'Sales Unit',
  })
  salesUnit: boolean;

  @OVField({
    type: 'boolean',
    title: 'Storage Unit',
  })
  storageUnit: boolean;

  @OVField({
    type: () => [ProductConfiguration],
    subType: () => 'Product',
    title: 'Products',
    tooltip: 'Add multiple products where necessary.',
    withQuantity: true,
    quantityKey: 'product',
    selectionType: 'multiple',
    flat: true,
    dropdownLimit: 1000,
    keys: ['id', 'quantity', 'product.id', 'product.name'],
  })
  productConfigurations: ProductConfiguration[];

  @OVField({
    type: () => [ProductSkuConfiguration],
    subType: () => 'ProductSku',
    title: 'SKUs',
    tooltip: 'Add multiple storage keeping units where necessary.',
    withQuantity: true,
    quantityKey: 'productSku',
    selectionType: 'multiple',
    flat: true,
    dropdownLimit: 1000,
    keys: ['id', 'quantity', 'productSku.id', 'productSku.name'],
  })
  productSkuConfigurations: ProductSkuConfiguration[];

  @OVField({
    type: 'string',
    title: 'Barcode Type',
  })
  barcodeType: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Value',
  })
  barcodeData: string;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;
}
