import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalVehicle, LoadAllocation, VehicleOverride } from '@ov-suite/models-warehouse';
import { getUpdate } from '@ov-suite/graphql-helpers';
import moment from 'moment';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';
import { Vehicle } from '@ov-suite/models-admin';

@Component({
  selector: 'ov-suite-wave-allocation-vehicle-change',
  templateUrl: './wave-allocation-vehicle-change.component.html',
  styleUrls: ['./wave-allocation-vehicle-change.component.scss'],
})
export class WaveAllocationVehicleChangeComponent implements OnInit {
  internalVehicleFormClass = Vehicle;
  externalVehicleFormClass = ExternalVehicle;

  load: { value: LoadAllocation };

  active = 1;

  today: string;

  selectedExternalVehicle: ExternalVehicle[] = [];
  selectedInternalVehicle: Vehicle[] = [];

  updatedLoad: LoadAllocation;
  @Output() submitted: EventEmitter<boolean> = new EventEmitter();
  loading: boolean;

  constructor(
    public readonly ovAutoService: OvAutoService,
    public readonly activeModal: NgbActiveModal,
    public readonly base: WaveAllocationBaseService,
    public readonly data: WaveAllocationDataService,
  ) {}

  ngOnInit() {
    this.today = moment().format('yyyy-MM-DD');
    console.log("Current Load: ", this.load);
  }

  onCancel() {
    this.activeModal.close();
  }

  onSelectExternalVehicle(items) {
    this.selectedExternalVehicle = items;
  }

  onSelectInternalVehicle(items) {
    this.selectedInternalVehicle = items;
  }

  onAddInternalVehicle(){
    if(this.selectedInternalVehicle.length > 1){
      alert('Cannot select multiple vehicles');
      return;
    }
    const updatedLoad: LoadAllocation = new LoadAllocation();
    updatedLoad.id = this.load.value.id;
    updatedLoad.setExternalVehicle(null);
    updatedLoad.setVehicle(this.selectedInternalVehicle[0] as unknown as VehicleOverride);
    this.onSave(updatedLoad, this.load.value);
  }

  onAddExternalVehicle() {
    if(this.selectedExternalVehicle.length > 1){
      alert('Cannot select multiple vehicles');
      return;
    }

    const updatedLoad: LoadAllocation = new LoadAllocation();
    updatedLoad.id = this.load.value.id;
    updatedLoad.setVehicle(null);
    updatedLoad.setExternalVehicle(this.selectedExternalVehicle[0]);
    this.onSave(updatedLoad, this.load.value);
  }

  onSave(event: LoadAllocation, original: LoadAllocation) {
    this.loading = true;
    const updatedLoad = getUpdate<LoadAllocation>(event, original);
    const keys = ['id'];
    this.ovAutoService.update({ entity: LoadAllocation, item: updatedLoad, keys }).then(response => {
      this.loading = false;
      this.submitted.emit(true);
      this.activeModal.dismiss();
    });
  }
}
