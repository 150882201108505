import {
  OVField,
  OVForm,
  OVSearchable,
  OVEntity, OVTable,
} from '@ov-suite/ov-metadata';

@OVEntity('ExternalVehicle', 'shared')
@OVTable<ExternalVehicle>([
  {
    key: 'registration',
    title: 'Vehicle Reg.',
    type: 'string',
  },
  {
    key: 'vehicleClass',
    title: 'Vehicle Class',
    type: 'string',
  },
  {
    key: 'make',
    title: 'Vehicle Make',
    type: 'string',
  },
  {
    key: 'model',
    title: 'Vehicle Model',
    type: 'string',
  },
  {
    key: 'startTime',
    startHidden: true,
    title: 'Start Time',
    type: 'string',
  },
  {
    key: 'endTime',
    startHidden: true,
    title: 'End Time',
    type: 'string',
  },
  {
    key: 'weightLimit',
    startHidden: true,
    title: 'Weight Limit',
    type: 'string',
  },
  {
    title: 'Dimension',
    type: 'other',
    startHidden: true,
    disableFiltering: true,
    keys: ['height', 'width', 'length'],
    action: (item) => String(item?.height * item?.width * item?.length),
  },
])
@OVForm([['#External Vehicle'],
  ['vehicleClass', 'registration'],
  ['make', 'model'],
  ['weightLimit'],
  ['height', 'width', 'length'],
  ['startTime', 'endTime'],
])
export class ExternalVehicle {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: false,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Vehicle Make',
    tooltip: 'Vehicle Make',
    placeholder: 'Isuzu',
    required: true,
  })
  make: string;

  @OVField({
    type: 'string',
    title: 'Vehicle Model',
    tooltip: 'Vehicle Model',
    placeholder: 'Model Name',
    required: true,
  })
  model: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Registration',
    placeholder: '444GC0GP',
    required: true,
  })
  registration: string;

  @OVField({
    type: 'string',
    title: 'Vehicle Class',
    placeholder: '8 Ton Truck',
    required: false,
  })
  vehicleClass: string;

  @OVField({
    type: 'number',
    title: 'Length',
    placeholder: 'Length',
    required: false,
  })
  length?: number;

  @OVField({
    type: 'number',
    title: 'Height',
    placeholder: 'Height',
    required: false,
  })
  height?: number;

  @OVField({
    type: 'number',
    title: 'Width',
    placeholder: 'Width',
    required: false,
  })
  width?: number;

  @OVField({
    type: 'number',
    title: 'Weight Limit',
    placeholder: '1000kg',
    required: false,
  })
  weightLimit?: number;

  @OVField({
    type: 'time',
    title: 'Start Time',
    placeholder: 'Required',
    required: true,
  })
  startTime: string;

  @OVField({
    type: 'time',
    title: 'End Time',
    placeholder: 'Required',
    required: true,
  })
  endTime: string;
}
