<div class="main-content">
  <div class="main-container">
  <ov-suite-hierarchy-table
    [title]="'Forms'"
    [formClass]="formClass"
    [service]="formService"
    [excludeColumns]="true"
  >
  </ov-suite-hierarchy-table>
  </div>
</div>
