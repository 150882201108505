<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">Exceptions</h6>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="p-2">
  <ov-suite-table
    [showIndex]="false"
    [striped]="true"
    [selectableRows]="false"
    [showFiller]="true"
    [data]="data || []"
    [loading]="false"
    [columnData]="metadata?.table"
    [totalCount]="data?.length"
    [pageSize]="pageSize"
    (changePage)="changePage($event)"
    [service]="autoService"
    [hideColumnKeys]="[]"
    [keyStore]="metadata?.name"
  ></ov-suite-table>
</div>
