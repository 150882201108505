<div class="main-content">
  <div class="outer">
    <div class="split-container-1">
      <div class="split-container-2">
        <as-split direction="horizontal" restrictMove="true" [useTransition]="true" #vertical>
          <as-split-area size="70">
            <as-split direction="vertical" restrictMove="true" #leftSide>
              <as-split-area>
                <ov-suite-load-allocation-map #map></ov-suite-load-allocation-map>
              </as-split-area>
              <as-split-area>
                <ov-suite-load-allocation-vehicle #vehicles></ov-suite-load-allocation-vehicle>
              </as-split-area>
            </as-split>
          </as-split-area>
          <as-split-area size="30">
            <as-split direction="vertical" restrictMove="true" #rightSide>
              <as-split-area size="50" class="orders-container">
                <ov-suite-load-allocation-order #orders></ov-suite-load-allocation-order>
              </as-split-area>
              <as-split-area size="50" *ngIf="action.orderSelected.value">
                <ov-suite-load-allocation-detail #details></ov-suite-load-allocation-detail>
              </as-split-area>
            </as-split>
          </as-split-area>
        </as-split>
      </div>
    </div>
  </div>
</div>
