import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataString } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-string-cell',
  template: `
    <span *ngIf="!col.editable">
                {{ item[col?.key] }}
    </span>
    <!--      [id]="item.id + 'row' + i + 'col' + j"-->
    <ov-suite-input
      *ngIf="col.editable"
      type="text"
      [disabled]="col?.key === 'id'"
      [style]="{'height': '38px'}"
      [(ngModel)]="item[col?.key]"
      (ngModelChange)="itemChanged(item)"
    ></ov-suite-input>
  `,
  // styleUrls: ['./basic-table.component.scss'],
})
export class StringCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataString<T>;
  @Output() itemChange = new EventEmitter<T>();

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }
}
