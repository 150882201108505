import { EventEmitter, Injectable } from '@angular/core';
import moment from 'moment';
import { Order } from '@ov-suite/models-order';
import { LoadAllocation, MasterRoute, VehicleOverride, VehicleTemplate } from '@ov-suite/models-warehouse';
import { PageReturn } from '@ov-suite/ov-metadata';
import { VehicleClass } from '@ov-suite/models-admin';
import { OvAutoService, OvAutoServiceMultipleMutationParams } from '@ov-suite/services';
import { getCreate } from '@ov-suite/graphql-helpers';
import * as _ from 'lodash';
import { DateRange } from '@ov-suite/ui';
import { ListCombo, OrderAllocation, SingleCombo, VehicleAllocation } from './load-allocation.interface';
import { commitLoadAllocationGql, unCommitLoadAllocationGql } from './load-allocation.grahpql';

/**
 * This Services is used for Fetching, Manipulating and Saving Data.
 *
 * This should be the only service on load-allocation with ovAutoService. Please be sure to manage observables correctly
 */

interface VehicleFilter {
  searchTerm: string;
  vehicleClassFilter: VehicleClass;
  activeFilter: LoadFilter;
}

interface OrderAllocationFilter {
  customerSearchTerm: string;
  masterRoute: MasterRoute;
}

interface DirtyLoad {
  updates: Record<number, VehicleAllocation>;
  creates: VehicleAllocation[];
}

export enum LoadFilter {
  All,
  Planning,
  Confirmed,
  Processed,
}

interface SortableColumn {
  name: string;
  getSortValue: (orderAllocation: OrderAllocation) => unknown;
}

@Injectable()
export class LoadAllocationDataService {
  date: SingleCombo<Date> = {
    value: new Date(),
    observable: new EventEmitter<Date>(),
  };

  orderAllocations: ListCombo<OrderAllocation> = {
    value: [],
    map: {},
    all: [],
    observable: new EventEmitter<OrderAllocation[]>(),
  };

  orderAllocationsFilter: OrderAllocationFilter = {
    customerSearchTerm: null,
    masterRoute: null,
  };

  customerIdsByMasterRoute: number[] = [];

  vehicleAllocation: ListCombo<VehicleAllocation> = {
    value: [],
    map: {},
    all: [],
    observable: new EventEmitter<VehicleAllocation[]>(),
  };

  vehicleIdToAllocationMap: Record<number, VehicleAllocation> = {};

  vehicleTemplates: ListCombo<VehicleTemplate> = {
    value: [],
    observable: new EventEmitter<VehicleTemplate[]>(),
  };

  vehicleClasses: ListCombo<VehicleClass> = {
    value: [],
    observable: new EventEmitter<VehicleClass[]>(),
  };

  vehicleFilter: VehicleFilter = {
    searchTerm: null,
    vehicleClassFilter: null,
    activeFilter: LoadFilter.Planning,
  };

  dirty: DirtyLoad = {
    updates: {},
    creates: [],
  };

  allocatedOrderIds: Record<number, boolean> = {};

  masterRoutes: MasterRoute[] = [];

  _orderDateRange: DateRange;

  set orderDateRange(input: DateRange) {
    this._orderDateRange = input;
  }

  get orderDateRange(): DateRange {
    return this._orderDateRange;
  }

  orderAllocationColumns: SortableColumn[] = [
    { name: 'Order ID', getSortValue: this.getId },
    { name: 'Customer ID', getSortValue: this.getCustomerId },
    // { name: 'Region', getSortValue: this.getAddress },
    { name: 'Weight', getSortValue: this.getCapacity1 },
    { name: 'Volume', getSortValue: this.getCapacity2 },
  ];

  sortingData: { column: string; direction: 'ASC' | 'DESC' } = {
    column: 'id',
    direction: 'ASC',
  };

  constructor(private readonly ovAutoService: OvAutoService) {
  }

  onInit() {
    this.fetchData();
  }

  debounceSave = _.debounce(() => this.saveAll(), 1000, { leading: false, trailing: true, maxWait: 3000 });

  async saveAll(): Promise<void> {
    this.dirty.creates = this.vehicleAllocation.all.filter(i => !i.load.id);
    const updatePrefix = 'update_';
    const createPrefix = 'create_';

    // ¯\_(ツ)_/¯
    const params: OvAutoServiceMultipleMutationParams = {};

    Object.values(this.dirty.updates).forEach(u => {
      params[`${updatePrefix}${u.load.id}`] = {
        type: 'update',
        entity: LoadAllocation,
        item: getCreate(u.load),
        keys: ['id'],
      };
    });

    this.dirty.creates.forEach((c, i) => {
      params[`${createPrefix}${i}`] = {
        type: 'create',
        entity: LoadAllocation,
        item: getCreate(c.load),
        keys: ['id'],
      };
    });

    if (!Object.values(params).length) {
      return;
    }

    this.ovAutoService.multipleMutation(params).then(response => {
      const indexesCleared: number[] = [];
      Object.entries(response).forEach(([key, value]) => {
        if (key.startsWith(createPrefix)) {
          const index = Number(key.slice(createPrefix.length));
          indexesCleared.push(index);
          this.dirty.creates[index].load.id = Number(value.id);
        } else if (key.startsWith(updatePrefix)) {
          const index = Number(key.slice(updatePrefix.length));
          delete this.dirty.updates[index];
        }
      });
      this.dirty.creates = this.dirty.creates.filter((c, i) => !indexesCleared.includes(i));
    });
  }

  saveLoad(load: LoadAllocation) {
    this.ovAutoService.apollo
      .use('warehouselink')
      .mutate({
        mutation: load.commitDate ? commitLoadAllocationGql() : unCommitLoadAllocationGql(),
        fetchPolicy: 'no-cache',
        variables: {
          load: getCreate(load)
        },
      })
      .toPromise()
      .catch(error => {
        throw error;
      });
  }

  async fetchData(): Promise<void> {
    const customerIds = this.orderAllocationsFilter.masterRoute?.customerIds ?? [];
    const date = moment(this.date.value).format('yyyy-MM-DD');
    const customerSearchTerm = this.orderAllocationsFilter.customerSearchTerm ? [this.orderAllocationsFilter.customerSearchTerm] : [''];
    this.ovAutoService
      .multipleFetch({
        orders: {
          entity: Order,
          type: 'list',
          query: {
            dueDate: [this.orderDateRange ?? moment(new Date().toDateString()).format('yyyy-MM-DD')],
          },
          filter: {
            'customer.id': customerIds,
          },
          search: {
            'customer.name': customerSearchTerm,
            'customer.customerCode': customerSearchTerm,
          },
          orderDirection: this.sortingData.direction ?? 'ASC',
          orderColumn: this.sortingData.column ?? 'id',
          keys: [
            'id',
            'location',
            'priority',
            'customer.id',
            'customer.name',
            'customer.customerCode',
            'customer.description',
            'customer.map',
            'orderItems.id',
            'orderItems.productSku.id',
            'orderItems.productSku.sku',
            'orderItems.productSku.length',
            'orderItems.productSku.width',
            'orderItems.productSku.height',
            'orderItems.productSku.weight',
            'fulfilmentDate',
            'orderDate',
            'orderCode',
            'dueDate',
            'orderItems.quantity',
          ],
        },
        vehicles: {
          entity: VehicleOverride,
          type: 'list',
          keys: [
            'id',
            'name',
            'registration',
            'class.id',
            'class.name',
            'class.weightLoadAllowed',
            'class.length',
            'class.width',
            'class.height',
            'class.volumeRedPercentage',
            'class.volumeOrangePercentage',
            'class.weightRedPercentage',
            'class.weightOrangePercentage',
            'resource.startTime',
            'resource.endTime',
          ],
        },
        loads: {
          entity: LoadAllocation,
          type: 'list',
          keys: [
            'id',
            'date',
            'commit',
            'commitDate',
            'releaseDate',
            'vehicle.id',
            'externalVehicle.id',
            'externalVehicle.vehicleClass',
            'externalVehicle.model',
            'externalVehicle.make',
            'externalVehicle.weightLimit',
            'externalVehicle.width',
            'externalVehicle.length',
            'externalVehicle.height',
            'externalVehicle.startTime',
            'externalVehicle.endTime',
            'orders.id',
            'orders.location',
            'orders.priority',
            'orders.customer.id',
            'orders.customer.name',
            'orders.customer.customerCode',
            'orders.customer.description',
            'orders.orderItems.id',
            'orders.orderItems.productSku.id',
            'orders.orderItems.productSku.sku',
            'orders.orderItems.productSku.length',
            'orders.orderItems.productSku.width',
            'orders.orderItems.productSku.height',
            'orders.orderItems.productSku.weight',
            'orders.orderItems.quantity',
          ],
          query: {
            date: [date],
          },
        },
        templates: {
          entity: VehicleTemplate,
          type: 'list',
          keys: ['id', 'name', 'vehicleLines.id', 'vehicleLines.vehicle.id', 'vehicleLines.startTime', 'vehicleLines.endTime'],
        },
        masterRoutes: {
          entity: MasterRoute,
          type: 'list',
          keys: ['id', 'name', 'customerIds'],
        },
      })
      .then(response => {
        const templates = (response.templates as PageReturn<VehicleTemplate>).data;
        const loads = (response.loads as PageReturn<LoadAllocation>).data;
        const orders = (response.orders as PageReturn<Order>).data;
        const vehicles = (response.vehicles as PageReturn<VehicleOverride>).data;
        const masterRoutes = (response.masterRoutes as PageReturn<MasterRoute>).data;

        this.setTemplates(templates);
        this.createOrderAllocations(orders, loads);
        this.createVehicleAllocations(vehicles, loads, date);
        this.sortLoadAllocations();
        this.filterVehicles();
        this.setMasterRoutes(masterRoutes);
      })
      .catch(e => {
        console.log({ e });
      });
  }

  setDate(date: Date): void {
    if (date !== this.date.value) {
      this.date.value = date;
      this.date.observable.emit(date);
      this.fetchData();
    }
  }

  setTemplates(templates: VehicleTemplate[]): void {
    this.vehicleTemplates.value = templates;
    this.vehicleTemplates.observable.emit(templates);
  }

  createOrderAllocations(orders: Order[], loads: LoadAllocation[]): void {
    this.allocatedOrderIds = {};
    loads.forEach(l => {
      l.orders.forEach(o => {
        this.allocatedOrderIds[o.id] = true;
      });
    });

    this.orderAllocations.map = {};
    orders.forEach(o => {
      this.orderAllocations.map[o.id] = OrderAllocation.fromOrder(o);
    });

    this.orderAllocations.all = Object.values(this.orderAllocations.map);
    this.setOrderAllocations(this.orderAllocations.all);
  }

  createVehicleAllocations(vehicles: VehicleOverride[], loads: LoadAllocation[], date: string): void {
    const allocated: Record<number, LoadAllocation> = {};
    loads.forEach(p => {
      allocated[p.getVehicle().id] = p;
    });
    const loadAllocations = vehicles.map(v => {
      if (allocated[v.id]) {
        const allocation = allocated[v.id];
        allocation.setVehicle(v);
        return allocation;
      }
      return LoadAllocation.fromVehicle(v, date);
    });

    this.vehicleAllocation.all = loadAllocations.map(l => VehicleAllocation.fromLoadAllocation(l));
    const vehicleClassesMap: Record<number, VehicleClass> = {};

    this.vehicleAllocation.all.forEach(allocation => {
      this.vehicleIdToAllocationMap[allocation.load.getVehicle().id] = allocation;
      vehicleClassesMap[allocation.load.getVehicle().class.id] = allocation.load.getVehicle().class;
    });

    this.vehicleClasses.value = Object.values(vehicleClassesMap);
    this.vehicleClasses.observable.emit(this.vehicleClasses.value);

    this.setVehicles(this.vehicleAllocation.all);
  }

  setVehicles(vehicleAllocations: VehicleAllocation[]): void {
    this.vehicleAllocation.value = vehicleAllocations;
    this.vehicleAllocation.observable.emit(vehicleAllocations);
  }

  setOrderAllocations(orderAllocations: OrderAllocation[]): void {
    this.orderAllocations.value = orderAllocations.filter(o => !this.allocatedOrderIds[o.order.id]);
    this.orderAllocations.observable.emit(this.orderAllocations.value);
  }

  sortLoadAllocations() {
    this.vehicleAllocation.all = this.vehicleAllocation.all.sort((a, b) => {
      if (a.load.isExternalVehicle() && !b.load.isExternalVehicle()) {
        return 1;
      }
      if (a.load.isExternalVehicle() && b.load.isExternalVehicle()) {
        return 1;
      }
      return -1;
    });
  }

  updateLoadsFromTemplate(template: VehicleTemplate): void {
    const updates = template.vehicleLines;
    updates.forEach(update => {
      const vehicle = this.vehicleIdToAllocationMap[update.vehicle.id];
      if (vehicle && vehicle.slots.length) {
        const [slot] = vehicle.slots;
        slot.startTime = update.startTime;
        slot.endTime = update.endTime;
      }
    });
    this.vehicleAllocation.observable.emit(this.vehicleAllocation.value);
  }

  filterVehicles(term?: string): void {
    if (term !== undefined) {
      this.vehicleFilter.searchTerm = term ?? null;
    }
    const searchTerm = this.vehicleFilter.searchTerm?.toLowerCase();
    const vehicles = this.vehicleAllocation.all.filter(allocation => {
      const vehicle = allocation.load.getVehicle();
      let planningFilter: boolean;
      let searchFilter = true;
      let classFilter = true;

      if (this.vehicleFilter.vehicleClassFilter) {
        classFilter = this.vehicleFilter.vehicleClassFilter.id === allocation.load.getVehicle().class.id;
      }

      if (searchTerm) {
        searchFilter =
          vehicle.name.toLowerCase().includes(searchTerm) ||
          vehicle.registration.toLowerCase().includes(searchTerm) ||
          vehicle.class.name.toLowerCase().includes(searchTerm);
      }

      switch (this.vehicleFilter.activeFilter) {
        case LoadFilter.All:
          planningFilter = true;
          break;
        case LoadFilter.Confirmed:
          planningFilter = !!allocation.load.commit;
          break;
        case LoadFilter.Planning:
          planningFilter = !allocation.load.commit;
          break;
        case LoadFilter.Processed:
          // Todo: Put in logic for processed Loads
          break;
        default:
      }

      return classFilter && planningFilter && searchFilter;
    });
    this.setVehicles(vehicles);
  }

  resetOrder(allocation: OrderAllocation, source: VehicleAllocation): void {
    source.slots.forEach(slot => {
      slot.pins = slot.pins.filter(i => i.order.id !== allocation.order.id);
    });
    this.orderAllocations.value.push(allocation);
    this.orderAllocations.observable.emit(this.orderAllocations.value);
    this.makeDirty(source);
  }

  makeDirty(veh: VehicleAllocation): void {
    if (veh.load.id) {
      this.dirty.updates[veh.load.id] = veh;
    } else {
      this.dirty.creates.push(veh);
    }
    veh.commit();
    this.debounceSave();
  }

  makeDirtyAndSave(veh: VehicleAllocation): void {
    if (veh.load.id) {
      this.dirty.updates[veh.load.id] = veh;
    } else {
      this.dirty.creates.push(veh);
    }
    veh.commit();
    this.saveLoad(veh.load);
  }

  commitAllDirty(): void {
    this.dirty.creates.forEach(c => c.commit());
    Object.values(this.dirty.updates).forEach(u => u.commit());
  }

  setMasterRoutes(masterRoutes: MasterRoute[]): void {
    this.masterRoutes = masterRoutes;
  }

  getId(orderAllocation: OrderAllocation): number {
    return orderAllocation.order.id;
  }

  getCustomerId(orderAllocation: OrderAllocation): number {
    return orderAllocation.order?.customer?.id;
  }

  getAddress(orderAllocation: OrderAllocation): string {
    return orderAllocation.order?.map?.address;
  }

  getCapacity1(orderAllocation: OrderAllocation): number {
    return orderAllocation.capacity1;
  }

  getCapacity2(orderAllocation: OrderAllocation): number {
    return orderAllocation.capacity2;
  }

  changeSortingColumn(column: string): void {
    this.sortingData = { column, direction: 'ASC' };
  }

  changeSortingDirection() {
    this.sortingData.direction = this.sortingData.direction === 'ASC' ? 'DESC' : 'ASC';
  }

  sortOrderAllocations() {
    const sortingColumn = this.orderAllocationColumns.find(item => item.name === this.sortingData.column);
    const { getSortValue } = sortingColumn;
    if (typeof getSortValue(this.orderAllocations.value[0]) === 'number') {
      this.orderAllocations.value.sort((a, b) => {
        return (getSortValue(a) as number) - (getSortValue(b) as number);
      });
    } else if (typeof getSortValue(this.orderAllocations.value[0]) === 'string') {
      this.orderAllocations.value.sort((a, b) => {
        return (getSortValue(a) as string).localeCompare(getSortValue(b) as string);
      });
    }

    if (this.sortingData.direction === 'DESC') {
      this.orderAllocations.value.reverse();
    }
  }

  updateOrderDateRange(dateRange: DateRange) {
    this.orderDateRange = dateRange;
    this.fetchData();
  }
}
