import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import 'rxjs/operator/filter';
import { environment } from '@ov-suite/helpers-shared';
import Auth from '@aws-amplify/auth';
import { SessionStorage } from '@ov-suite/helpers-angular';
import { OvAutoService } from '@ov-suite/services';
import { Domain } from '@ov-suite/models-admin';
import { Hub } from '@aws-amplify/core';
import { RouteInfo } from '../sidebar/sidebar.model';

interface INavbarDomains {
  id: number;
  title: string;
  url: string;
  hasPermission: boolean;
}

@Component({
  selector: 'ov-suite-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  toggleApps = false;

  toggleDomains = false;

  @Input() routes: RouteInfo[];

  listTitles: RouteInfo[];

  location: Location;

  public open = false;

  navbarApps: INavbarDomains[] = [];

  domains: Domain[] = [];

  selectedDomainId: number;

  selectedDomain: string;

  @ViewChild('ov-suite-navbar', { static: false }) button;

  sidebarVisible = true;

  constructor(
    location: Location,
    private readonly router: Router,
    private readonly ovAutoService: OvAutoService,
  ) {
    this.location = location;
    Hub.listen('token', () => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.generateNavbarDomains();
    this.listTitles = this.routes;
  }

  generateNavbarDomains() {
    // todo Fix this: Domains Parse Issue
    Auth.currentSession().then(session => {
      this.setDefaultApps();
      const currentUserTokenInfo = session.getIdToken().decodePayload();
      const apps = 'lambdaAppIds';
      const domains = 'lambdaDomainIds';

      currentUserTokenInfo[apps] = JSON.parse(currentUserTokenInfo[apps]);
      if (!!currentUserTokenInfo[apps] && currentUserTokenInfo[apps].length > 0) {
        currentUserTokenInfo[apps].forEach(appId => {
          this.navbarApps.forEach(app => {
            if (app.id === appId) {
              app.hasPermission = true;
            }
          });
        });
      }

      const domainsList: number[] = JSON.parse(currentUserTokenInfo[domains]);
      if (domainsList?.length) {
        this.ovAutoService
          .getAll(Domain, domainsList, 'adminlink') // todo: Replicate Domains to all APIs and make this 'shared'
          .then(result => {
            this.domains = [...result].sort((a, b) => a.name.localeCompare(b.name));
            const selectedDomain = SessionStorage.getSelectedDomain();

            if (!selectedDomain && this.domains.length > 0) {
              SessionStorage.setSelectedDomain(this.domains[0].id.toString());
            }
            this.selectedDomainId = +SessionStorage.getSelectedDomain();
            this.selectedDomain = this.domains.filter(d => +SessionStorage.getSelectedDomain() === d.id)[0]?.name;
          });
      }
    });
  }

  setDefaultApps() {
    this.navbarApps = [
      {
        id: environment.appId.admin,
        title: 'Admin Link',
        url: environment.webUrl.admin,
        hasPermission: false,
      },
      {
        id: environment.appId.execution,
        title: 'Execution Link',
        url: environment.webUrl.execution,
        hasPermission: false,
      },
      {
        id: environment.appId.idm,
        title: 'Account Link',
        url: environment.webUrl.idm,
        hasPermission: false,
      },
      {
        id: environment.appId.ceramicPortal,
        title: 'Invoice Portal',
        url: environment.webUrl.ceramicPortal,
        hasPermission: false,
      },
      {
        id: environment.appId.order,
        title: 'Order Link',
        url: environment.webUrl.order,
        hasPermission: false,
      },
      {
        id: environment.appId.warehouse,
        title: 'Warehouse Link',
        url: environment.webUrl.warehouse,
        hasPermission: false,
      },
      {
        id: environment.appId.yard,
        title: 'Yard Link',
        url: environment.webUrl.yard,
        hasPermission: false,
      },
    ];
  }

  minimizeSidebar() {
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarOpen() {
    const html = document.getElementsByTagName('html')[0];
    setTimeout(() => {
    }, 500);

    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle(): string {
    let title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(1);
    }

    for (const parent of this.listTitles) {
      if (parent.path === title) {
        return parent.title;
      }

      if (parent.children) {
        let childrenFromUrl = title.split('/')[1];
        if (childrenFromUrl.includes('?')) {
          const [path] = childrenFromUrl.split('?');
          childrenFromUrl = path;
        }

        for (const current of parent.children) {
          if (current.path === childrenFromUrl) {
            return `${parent.title || ''} - ${current.title}`;
          }
        }
      }
    }
    return 'dashboard';
  }

  navigate(url: string) {
    window.location.assign(url);
  }

  setDomain(id: number) {
    SessionStorage.setSelectedDomain(id.toString());
    window.location.reload();
  }

  navigateBack() {
    this.location.back();
  }
}
