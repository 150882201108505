
<ov-suite-table
  [showIndex]="false"
  [striped]="true"
  [selectableRows]="true"
  [showFiller]="true"
  [data]="pageData"
  [loading]="false"
  [currentPage]="currentPage"
  [editableRows]="false"
  [dropdownData]="dropdownData"
  [pageChangeEnabled]="true"
  [columnData]="metadata?.table"
  [totalCount]="totalCount"
  [pageSize]="pageSize"
  [disableUrlParamsFiltering]="true"
  [keyStore]="metadata?.name"
  (orderChange)="onSort($event)"
  (itemSelect)="onSelectItem($event)"
  (filterChange)="onFilter($event)"
  (changePage)="changePage($event)"
></ov-suite-table>

  <div class="d-flex flex-row justify-content-between align-content-between">
    <button type="button" class="btn btn-outline-primary" (click)="onCloseModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="AddVehicle()">Add Vehicles to Template</button>
  </div>
