import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSplitModule } from 'angular-split';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { LoadAllocationRoutes } from './load-allocation.routing';
import { OrderComponent } from './order/order.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { MainComponent } from './main/main.component';
import { MapComponent } from './map/map.component';
import { DetailComponent } from './detail/detail.component';
import { LoadAllocationBaseService } from './load-allocation.base.service';
import { VehicleWaveComponent } from './vehicle-wave/vehicle-wave.component';
import { ExternalVehicleCreateComponent } from './external-vehicle/external-vehicle-create.component';
import { PendingChangesGuard } from '../../guards/pending-changes-guard';
import { LoadAllocationDataService } from './load-allocation.data.service';
import { LoadAllocationActionService } from './load-allocation.action.service';
import { LoadAllocationVehicleLayoutService } from './load-allocation.vehicle-layout.service';
import { VehiclePinComponent } from './vehicle-pin/vehicle-pin.component';
import { LoadAllocationPrintComponent } from './print/load-allocation-print.component';

@NgModule({
  imports: [
    AngularSplitModule.forRoot(),
    CommonModule,
    RouterModule.forChild(LoadAllocationRoutes),
    FormsModule,
    UiModule,
    DragDropModule,
    NgbDropdownModule,
    LeafletModule,
    NgbNavModule,
    QRCodeModule,
  ],
  declarations: [
    DetailComponent,
    MainComponent,
    MapComponent,
    OrderComponent,
    VehicleComponent,
    VehicleWaveComponent,
    VehiclePinComponent,
    ExternalVehicleCreateComponent,
    LoadAllocationPrintComponent,
  ],
  providers: [
    LoadAllocationActionService,
    LoadAllocationBaseService,
    LoadAllocationDataService,
    LoadAllocationVehicleLayoutService,
    PendingChangesGuard,
  ],
})
export class LoadAllocationModule {}
