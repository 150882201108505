import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AutoService } from '@ov-suite/services';
import gql from 'graphql-tag';
import { VehicleTemplate } from '@ov-suite/models-warehouse';

@Injectable()
export class VehicleTemplateService extends AutoService<VehicleTemplate> {
  constructor(private readonly apollo: Apollo) {
    super(
      apollo.use('warehouselink'),
      VehicleTemplate,
      'VehicleTemplate',
      'VehicleTemplates',
      'warehouselink',
    );
  }

  // TODO refactor this code please
  async createTemplate(templateName: string, changes: unknown[]): Promise<VehicleTemplate> {
    const name = 'createVehicleTemplate';
    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: VehicleTemplateCreateInput!) {
            ${name}(data: $data)
             {
              id
             }
             }`),
          variables: {
            data: {
              name: templateName,
              vehicleLinesObjectList: changes,
            },
          },
        })
        .subscribe(
          response => {
            resolve(response.data['createVehicleTemplate']);
          },
          reject,
        );
    });
  }

  async updateTemplate(
    templateName: string,
    id: number,
    changes: unknown[],
  ): Promise<VehicleTemplate> {
    const name = 'updateVehicleTemplate';
    return new Promise((resolve, reject) => {
      this.apollo
        .use('warehouselink')
        .mutate({
          mutation: gql(`mutation
            ${name}($data: VehicleTemplateUpdateInput!) {
            ${name}(data: $data) {
                id
              }
            }`),
          variables: {
            data: {
              id,
              name: templateName,
              vehicleLinesObjectList: changes,
            },
          },
        })
        .subscribe(
          response => {
            resolve(response.data['updateVehicleTemplate']);
          },
          reject,
        );
    });
  }
}
