import { Component, OnInit } from '@angular/core';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { LoadAllocationActionService } from '../load-allocation.action.service';
import moment from 'moment/moment';

@Component({
  selector: 'ov-suite-load-allocation-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  active = 1;

  orderPriority: Record<number, { text: string, color: string}> =
    {
      1: { text: 'Critical', color: 'badge-danger' },
      2: { text: 'High', color: 'badge-orange' },
      3: { text: 'Medium', color: 'badge-warning' },
      4: { text: 'Low', color: 'badge-success' },
    };

  constructor(
    public base: LoadAllocationBaseService,
    public action: LoadAllocationActionService,
  ) {}

  ngOnInit(): void {}

  getSLA(orderDate: string, fulfilDate: string): string {
    return `${moment(fulfilDate).diff(moment(orderDate), 'days')} days`;
  }
}
