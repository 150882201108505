import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { Order } from '@ov-suite/models-order';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Order,
      api: 'warehouselink',
      createTitle: 'Order',
      route: ['order'],
      tableTitle: 'Order',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      permissionId: WarehousePermission.inventoryList,
      defaultOrderDirection: 'DESC',
      defaultOrderColumn: 'orderDate'
    }),
  ],
})
export class OrdersModule {}
