import {
  OVField,
  OVForm,
  OVTable,
  OVSearchable,
  OVEntity,
  OVSidebar
} from '@ov-suite/ov-metadata';
import { VehicleClass } from './vehicle-class.model';

@OVEntity('VehicleStatus', 'shared')
export class VehicleStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Vehicle', 'shared')
@OVSidebar([['status'], ['id']])
@OVForm([['#Vehicle Details'], ['code', 'name'], ['registration', 'class']])
@OVTable<Vehicle>([
  {
    key: 'id',
    title: 'Vehicle ID',
    type: 'string'
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string'
  },
  {
    key: 'code',
    title: 'Code',
    type: 'string'
  },
  {
    key: 'registration',
    title: 'Registration',
    type: 'string'
  },
  {
    title: 'Class',
    type: 'other',
    action: e => e?.class?.name,
    keys: ['class.name']
  }
])
export class Vehicle {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Vehicle Code',
    tooltip: 'Code',
    placeholder: 'Required',
    required: true
  })
  code: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Name',
    placeholder: 'Required',
    required: true
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Registration',
    placeholder: 'Required',
    required: true
  })
  registration: string;

  @OVField({
    type: () => VehicleClass,
    title: 'Vehicle Class',
    placeholder: 'Required',
    required: false
  })
  class: VehicleClass;

  @OVField({
    type: () => VehicleStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true
  })
  status: VehicleStatus;
}
