import { OVField, OVForm, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('InventoryContainerType', 'warehouselink')
export class InventoryContainerType {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('InventoryContainer', 'warehouselink')
@OVForm([['#Container Details'], ['name', 'description'], ['type', ''], ['barcodeData', 'barcodeType'], ['qrPreview']])
@OVTable<InventoryContainer>([
  {
    key: 'id',
    title: 'ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Container Name',
    type: 'string',
  },
  {
    key: 'type',
    title: 'Container Type',
    type: 'other',
    keys: ['type', 'type.name'],
    action: inventoryContainer => inventoryContainer.type.name,
  },
])
export class InventoryContainer {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    required: false,
  })
  description: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Type',
    placeholder: 'Required',
    required: false,
  })
  barcodeType?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Data',
    placeholder: 'Required',
    required: true,
  })
  barcodeData?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Container Name',
    placeholder: 'Required',
    required: false,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Created By',
    placeholder: 'Required',
    required: false,
  })
  createdBy?: string;

  @OVField({
    type: () => InventoryContainerType,
    selectionType: 'simple',
    flat: true,
    keys: ['id', 'name'],
    title: 'Type',
    required: true,
  })
  type: InventoryContainerType;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;
}
