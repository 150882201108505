import {
  OVEntity,
  OVField,
  OVSearchable,
} from '@ov-suite/ov-metadata';

@OVEntity('VehicleExceptionReason', 'warehouselink')
export class VehicleExceptionReason {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Reason',
    placeholder: 'Required',
    required: false,
  })
  name: string;
}
