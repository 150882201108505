import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowProcessService } from '../workflow-process.service';
import { environment } from '@ov-suite/helpers-shared';
import { WorkflowProcess } from '@ov-suite/models-shared';

@Component({
  selector: 'ov-suite-process-view',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.css'],
})
export class ProcessViewComponent implements OnInit {
  id: string;

  data: WorkflowProcess;

  apiUrl: string;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected workflowProcessService: WorkflowProcessService,
  ) {
    this.apiUrl = environment.apiUrl.warehouse;
    this.route.params.subscribe(param => {
      this.id = param['id'];
    });
  }

  ngOnInit() {
    this.workflowProcessService.getById(this.id).subscribe(response => {
      this.data = response.data['getProcessInstance'] as WorkflowProcess;
    });
  }

  onDelete(id: string) {
    const agree = confirm('Are you sure you want to delete this process?');
    if (agree) {
      this.workflowProcessService.deleteProcess(id).subscribe(() => {
        this.router.navigate(['workflow-processes']);
      });
    }
  }
}
