import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataOther } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-other-cell',
  template: `
    <a
      *ngIf="col?.queryParams || col?.routerLink; else simple"
      [routerLink]="col?.routerLink(item)"
      [queryParams]="col?.queryParams(item)"
    >
      {{ col?.action(item) }}
    </a>
    <ng-template #simple>
      <div [innerHTML]="col?.action(item)"></div>
    </ng-template>
  `,
  // styleUrls: ['./basic-table.component.scss'],
})
export class OtherCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataOther<T>;
  @Output() itemChange = new EventEmitter<T>();

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }
}
