import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { PendingChangesGuard } from '../../guards/pending-changes-guard';
import { WarehousePermission } from '../../helpers/permissions.helpers';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { LoadAllocationPrintComponent } from './print/load-allocation-print.component';

export const LoadAllocationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: WarehousePermission.loadAllocation } },
        canActivate: [AuthGuard],
        path: '',
        component: MainComponent,
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'print/:id',
        component: LoadAllocationPrintComponent,
      },
    ],
  },
];
