import { NgModule } from '@angular/core';
import { ArrowLeft, ArrowRight, OvsuiteIconsModule } from '@ov-suite/styles';

// select icons to include
const icons = {
  ArrowLeft,
  ArrowRight,
};

@NgModule({
  imports: [OvsuiteIconsModule.pick(icons)],
  exports: [OvsuiteIconsModule],
})
export class WarehouseIconsModule {}
