<div class="planner">
  <div class="planner-table">
    <div class="planner-head" #topRow>
      <div class="planner-corner">
        Load Bays
      </div>
      <span *ngIf="dataService.waveDays?.value?.length" class="planner-row">
        <div class="planner-cell planner-cell-blank"></div>
        <div *ngFor="let waves of dataService.waveDays.value" class="planner-header-group">
          <div class="planner-header-date">
            <div>{{ waves[0].startDate | waveDate }}</div>
          </div>
          <div class="planner-header-wave">
            <div *ngFor="let wave of waves" class="planner-cell">
              <div>{{ wave.waveConfig.waveName }}</div>
              <button
                [disabled]="!baseService.permissions.create"
                class="btn btn-sm text-center"
                (click)="dataService.releaseWave(wave)">
                 <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </span>
      <span class="text-center" *ngIf="!dataService.waveDays?.value?.length">
        <h5 class="text-capitalize">No waves Available for this date range</h5>
      </span>
    </div>
    <div class='planner-lower'>
      <div class='planner-left' #leftColumn>
        <div *ngFor="let bay of dataService.loadBays.value" class='planner-cell'>
          {{ bay.name }}
        </div>
      </div>
      <div #body class="planner-body" (mousedown)="drag($event, body, leftColumn, topRow)">
      <span *ngFor="let bay of dataService.loadBays.value" class="planner-row">
        <ng-container *ngFor="let waves of dataService.waveDays.value">
          <div *ngFor="let wave of waves" class="planner-cell planner-cell-content">
            <ov-suite-wave-allocation-wave-bay
              [wave]="wave"
              [loadBay]="bay"
            >
            </ov-suite-wave-allocation-wave-bay>
          </div>
        </ng-container>
      </span>
      </div>
    </div>
  </div>
</div>
