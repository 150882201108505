export enum InventoryContainerEventType {
  PackingStarted = 'packingStarted',
  PackingFinished = 'packingFinished',
  PutAwayStarted = 'putAwayStarted',
  PutAwayFinished = 'putAwayFinished',
  PickingStarted = 'pickingStarted',
  PickingFinished = 'pickingFinished',
  SinBinDropOff = 'SinBinDropOff',
  SinBinCheckIn = 'sinBinCheckIn',
  SinBinCheckOut = 'sinBinCheckOut',
  LoadingStarted = 'loadingStarted',
  LoadingFinished = 'loadingFinished',
}
