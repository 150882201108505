import { NgModule, ModuleWithProviders, Optional } from '@angular/core';
import { OvsuiteIconsComponent } from './ovsuite-icons.component';
import { Icons } from './icons.provider';

@NgModule({
  declarations: [OvsuiteIconsComponent],
  exports: [OvsuiteIconsComponent],
})
export class OvsuiteIconsModule {
  constructor(@Optional() private readonly icons: Icons) {
    if (!this.icons) {
      throw new Error(`No icon provided. Make sure to use 'OvsuiteIconsModule.pick({ ... })' when importing the module`);
    }
  }

  static pick(icons: { [key: string]: string }): ModuleWithProviders<OvsuiteIconsModule> {
    return {
      ngModule: OvsuiteIconsModule,
      providers: [{ provide: Icons, multi: true, useValue: icons }],
    };
  }
}
