<div class="vehicle">
  <div class="vehicle-container">
    <div class="header-bar">
      <div class="header-left">
        <ov-suite-input type="date" [(ngModel)]="date" [minDate]="minDate" (ngModelChange)="onDateChange()"></ov-suite-input>
        <ov-suite-input type="text" placeholder="Search" (change)="searchVehicles($event)"></ov-suite-input>
      </div>
      <div class="header-center">
        <div>{{ layout.dateDisplay }}</div>
      </div>
      <div class="header-right">
        <div ngbDropdown class="d-inline-block" placement="bottom-right">
          <button id="load-allocation-filter-btn" class="btn btn-outline-primary" ngbDropdownToggle>Filter</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="filter-dropdown">
            <div>
              <ov-suite-input
                type="dropdown"
                [data]="filterStatuses"
                [label]="['label']"
                [placeholder]="'Status'"
                title="Status"
                [(ngModel)]="statusFilter"
                (change)="onStatusFilterChange()"
              ></ov-suite-input>
            </div>
            <div>
              <ov-suite-input
                type="dropdown"
                [data]="filterClasses"
                [label]="['name']"
                [placeholder]="'Vehicle Class'"
                title="Vehicle Class"
                [(ngModel)]="classFilter"
                (change)="onClassFilterChange()"
              ></ov-suite-input>
            </div>
          </div>
        </div>
        <ov-suite-input
          type="dropdown"
          [data]="templates"
          [label]="['name']"
          placeholder="Template"
          [(ngModel)]="template"
          (change)="onTemplateChange()"
        ></ov-suite-input>
        <button
          [disabled]="!base.permissions.create"
          id="external-vehicle-add-btn"
          class="btn btn-primary"
          (click)="onAddExternalVehicle()">
          <i class="fa fa-truck" aria-hidden="true"></i>&nbsp;
          </button>
      </div>
    </div>
    <div class="static-bar">
      <div class="left-static">
        <div class="v-cell v-select"><input type="checkbox"></div>
        <div class="v-cell v-name">Vehicle Reg.</div>
        <div class="v-cell v-class">Class</div>
        <div class="v-cell v-capacity">Capacity</div>
        <div class="v-cell v-button"></div>
      </div>
      <div #rightStatic class="right-static">
        <div [style]="{ width: layout.timeSlot.totalWidth + 'px' }">
          <div class="v-cell v-times">
            <div *ngFor="let time of layout.timeSlot.headings"
                 class="time-header"
                 [style]="{ left: time.left + 'px' }"
            >
              {{ time.name }}
            </div>
            <div class="header-pad" [style.left]="layout.timeSlot.totalWidth + 'px'"></div>
          </div>
        </div>
      </div>
      <div class="scale-bar">
        <button (click)="layout.zoomOut()">
          <span class="fa fa-minus"></span>
        </button>
        <button (click)="layout.zoomIn()">
          <span class="fa fa-plus"></span>
        </button>
      </div>
    </div>
    <div class="vehicle-inner" #vehicleInner>
      <div
        #leftTable
        class="left-table"
        (mousedown)="dragLeft($event, vehicleInner, leftTable)"
      >
        <div class="vehicle-body">
          <div *ngFor="let veh of data.vehicleAllocation.value"
               class="body-row"
               [ngClass]="{'vehicle-selected': action.vehicleSelected.value === veh, 'vehicle-committed': !!veh.load.commitDate }"
               (click)="action.selectVehicle(veh)"
          >
            <div class="capacity-bar">
              <div class="cap1" [ngStyle]="getCapacity1Style(veh)"></div>
              <div class="cap2" [ngStyle]="getCapacity2Style(veh)"></div>
            </div>
            <div class="v-cell v-select"><input type="checkbox"></div>
            <div class="v-cell v-name">{{veh.load.getVehicle()?.registration}}</div>
            <div class="v-cell v-class">{{veh.load.getVehicle()?.class.name}}</div>
            <div class="v-cell v-capacity">
              <div>{{ getCapacity1Display(veh) }}</div>
              <div>{{ getCapacity2Display(veh) }}</div>
            </div>
            <div class="v-cell v-button">
              <button
                class="btn-commit"
                (click)="commit($event, veh)"
                [disabled]="!base.permissions.create || loaders[veh.load.getVehicle().id] || !!veh.load.releaseDate"
                [ngClass]="{'commit-disabled': !!veh.load.releaseDate }">
                <span *ngIf="!veh.load.releaseDate && veh.load.commitDate" class="fa fa-arrow-left"></span>
                <span *ngIf="!veh.load.releaseDate && !veh.load.commitDate" class="fa fa-arrow-right"></span>
                <span *ngIf="!!veh.load.releaseDate" class="fa fa-times"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="scroll-filler"></div>
      </div>
      <div
        #rightTable
        class="right-table"
        (mousedown)="dragRight($event, vehicleInner, rightTable, rightStatic)"
      >
        <div [style]="{ 'max-width': layout.timeSlot.totalWidth + 'px', width: layout.timeSlot.totalWidth + 'px' }">
          <div class="vehicle-body">
            <div *ngFor="let veh of data.vehicleAllocation.value" class="body-row" [ngClass]="{'vehicle-selected': action.vehicleSelected.value === veh, 'vehicle-committed': !!veh.load.commitDate }">
              <div class="v-cell v-times">
                <ov-suite-load-allocation-vehicle-wave *ngFor="let slot of veh.slots" [allocation]="veh" [slot]="slot">
                </ov-suite-load-allocation-vehicle-wave>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template>

</ng-template>
