import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Inventory } from '@ov-suite/models-admin';
import { WarehousePermission } from '../../helpers/permissions.helpers';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Inventory,
      api: 'warehouselink',
      createTitle: 'Inventory',
      route: ['inventory'],
      tableTitle: 'Inventory',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      permissionId: WarehousePermission.inventoryList,
    }),
  ],
})
export class InventoryModule {}
