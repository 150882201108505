import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { apiEnvironment, environment } from '@ov-suite/helpers-shared';
import Amplify from '@aws-amplify/core';
import moment from 'moment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';

// Sentry.init({
//   dsn: 'https://dc7733db96a14be88929a5145d3ac5b4@sentry.openvantage.io/4',
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ['localhost', apiEnvironment.url.warehouse, apiEnvironment.url.admin, apiEnvironment.url.idm],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   release: 'warehouselink-web-1.2.3',
//   environment: process.env?.ENVIRONMENT ? process.env.ENVIRONMENT : 'dev',
//   tracesSampleRate: 1.0,
// });
// Sentry.setTag('Client', process.env?.CLIENT ?? process.env?.CUSTOMER ?? 'ov');

Date.prototype.toJSON = function () {
  return moment(this).format();
};

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  aws_project_region: environment.cognito.region,
  aws_cognito_identity_pool_id: environment.cognito.identityPoolId,
  aws_cognito_region: environment.cognito.region,
  aws_user_pools_id: environment.cognito.userPoolId,
  aws_user_pools_web_client_id: environment.cognito.clientId,
  oauth: environment.cognito.oauth,
  aws_user_files_s3_bucket: environment.cognito.bucketId,
  aws_user_files_s3_bucket_region: environment.cognito.region,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
