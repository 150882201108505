import { OVField, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity('UserTypeStatus', 'idmlink')
export class UserTypeStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}
