<div class="main-content" *ngIf="loadAllocation">
  <div class="main-container">
    <div class="hierarchy-table ov-shadow-sm">
      <div class="hierarchy-table-card card slide-in-right" #report>
        <div class="pt-2 pl-4">
          <div class="row">
            <div class="pt-2 pl-2 col-3">
              <qrcode [qrdata]="loadAllocation.id + ''" [width]="120"  [errorCorrectionLevel]="'M'"></qrcode>
              <h3 class="text-uppercase font-weight-bold">Loading Sheet</h3>
            </div>
            <div class="col-3">
              <div>
                <span class="font-weight-bold">Bay:</span> {{ loadAllocation.loadBay.name }}
              </div>
            </div>
            <div class="col-3">
              <div>
                <span class="font-weight-bold">Registration:</span> {{ loadAllocation.vehicle.registration }}
              </div>
            </div>
            <div class="col-3">
              <div>
                <span class="font-weight-bold">Date:</span> {{ loadAllocation.date }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <table class="table table-sm table-striped">
            <thead>
            <tr>
              <th>Product Code</th>
              <th>Product Description</th>
              <th>Quantity</th>
            </tr>
            </thead>
            <tbody *ngFor="let order of loadAllocation.orders">
            <tr *ngFor="let orderItem of order.orderItems">
              <td class="border-left">{{ orderItem.productSku.sku}}</td>
              <td class="border-left">{{ orderItem.productSku.name}}</td>
              <td class="border-left">{{ orderItem.quantity}}</td>
            </tr>
            </tbody>
            <tbody>
              <tr class="border-bottom">
                <td class="border-left"></td>
                <td class="border-left"></td>
                <td class="border-left"></td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
    <button class="btn btn-primary" (click)="print()">Print</button>
  </div>
</div>
