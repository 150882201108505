import { Component, ViewChild } from '@angular/core';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';
import { WaveAllocationActionService } from '../wave-allocation.action.service';

@Component({
  selector: 'ov-suite-wave-allocation-loads',
  templateUrl: './wave-allocation-loads.component.html',
  styleUrls: ['./wave-allocation-loads.component.scss'],
})
export class WaveAllocationLoadsComponent {
  @ViewChild('dateRange', { static: false }) dateRange;

  constructor(
    public dataService: WaveAllocationDataService,
    public baseService: WaveAllocationBaseService,
    public actionService: WaveAllocationActionService,
  ) {}

  onColumnHeaderClick(column: string) {
    if (this.dataService.sortingData.column === column) {
      this.dataService.changeSortingDirection();
    } else {
      this.dataService.changeSortingColumn(column);
    }
    this.dataService.sortLoads();
  }

  clearLoadsFilter() {
    this.dataService.loadsFilter.customerSearchTerm = null;
    this.dataService.loadsDateRange = null;
    this.dateRange.clearDateRange();
    this.dataService.fetchData();
  }

  filterChange() {
    this.dataService.fetchData();
  }
}
