<div class="order-list-container">
  <div class="header-bar">
    <div class="header-left">
      <h6> Orders </h6>
    </div>
    <div class="header-right">
      <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button id="load-allocation-filter-dropdown" class="fa fa-filter btn-primary" ngbDropdownToggle></button>
        <div ngbDropdownMenu class="filter-dropdown">
          <div>
            <span class="ml-2">Customer Name or Code</span>
            <ov-suite-input
              type="text" placeholder="Search"
              [(ngModel)]="dataService.orderAllocationsFilter.customerSearchTerm"
              (change)="dataService.fetchData()">
            </ov-suite-input>
            <div>
              <span class="ml-2">Date Range</span>
              <ov-suite-input
                type="date-range"
                placeholder="Date Range"
                [(ngModel)]="dataService.orderDateRange"
                (ngModelChange)="dataService.fetchData()"
                [hideClearButton]="true"
                #dateRange
              >
              </ov-suite-input>
            </div>
            <div class="form-group">
              <span class="ml-2">Master Route</span>
              <ov-suite-input
                [id]="'masterRoute'"
                name="masterRoute"
                [type]="'dropdown'"
                [label]="['name']"
                [data]="dataService.masterRoutes"
                [(ngModel)]="dataService.orderAllocationsFilter.masterRoute"
                (ngModelChange)="dataService.fetchData()"
                [placeholder]="'Select master route'"
              >
              </ov-suite-input>
            </div>
            <div class="text-center">
              <button (click)="clearOrderAllocationsFilter()" class="btn-sm btn-primary mb-2">Clear</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-sm" *ngIf="!!dataService.orderAllocations.all.length">
    <thead>
      <tr>
        <th><input type="checkbox"></th>
        <th *ngFor="let col of dataService.orderAllocationColumns" scope="col" (click)="onColumnHeaderClick(col.name)">
          {{ col.name }}
          <i
            *ngIf="dataService.sortingData.column !== col.name"
            class="fa fa-fw fa-sort" aria-hidden="true"
          ></i>
          <i
            *ngIf="dataService.sortingData.column === col.name"
            class="fa fa-fw"
            [ngClass]="{
              'fa-sort-asc': dataService.sortingData.direction === 'ASC',
              'fa-sort-desc': dataService.sortingData.direction === 'DESC'
            }" aria-hidden="true"
          ></i>
        </th>
      </tr>
    </thead>
    <tbody
      cdkDropList
      id="order-list"
      [cdkDropListData]="dataService.orderAllocations.value"
      [cdkDropListConnectedTo]="base.vehicleLists"
      (cdkDropListDropped)="actionService.drop($event)"
      [cdkDropListDisabled]="!base.permissions.create"
    >
      <tr *ngFor="let allocation of dataService.orderAllocations.value"
          class="border"
          cdkDrag
          [cdkDragData]="allocation"
          (click)="actionService.selectOrder(allocation)"
          (mouseenter)="actionService.orderHoverStart.emit(allocation)"
          (mouseleave)="actionService.orderHoverEnd.emit(allocation)"
          [ngClass]="{'selected-customer': actionService.orderSelected.value === allocation}">
        <td><input type="checkbox"></td>
        <td>{{ allocation.order.id }}</td>
        <td>{{ allocation.order.customer?.customerCode }}</td>
<!--        <td>{{ allocation.order.location?.address }}</td>-->
        <td>{{ allocation.capacity1 }} kg</td>
        <td>{{ allocation.capacity2 }} m³</td>
        <div *cdkDragPlaceholder></div>
        <div *cdkDragPreview class="plan-pin">
          <span>{{ allocation.order.id }}</span>
          <span>{{ allocation.order.customer.name }} </span>
          <span>{{ allocation.capacity1 }} kg</span>
          <span>{{ allocation.capacity2 }} m³</span>
        </div>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!dataService.orderAllocations.all.length" class="no-orders">
    <div>No orders found for {{ dataService.date.value | date }}</div>
  </div>
</div>
