import { Apollo } from 'apollo-angular';
import {
  deleteMutationKeys,
  getAncestorQueryKeys,
  getByIdsQueryKeys,
  getQueryKeys,
  listQueryKeys,
  multipleQueryKeys,
  updateMutationKeys,
  MultipleKeysParams,
  createMutationKeys,
  multipleMutationKeys,
} from '@ov-suite/graphql-helpers';
import { Constructor, FieldMetadata, getFieldMetadata, mapToClass } from '@ov-suite/ov-metadata';
import { Inject, Injectable } from '@angular/core';
import { DocumentNode } from 'graphql';
import * as _ from 'lodash';
import { QueryParams } from '@ov-suite/helpers-shared';

interface HasId {
  id: number | string;
}

interface PageReturn<T extends HasId> {
  data: T[];
  totalCount: number;
}

export interface OvAutoServiceListParams<T> {
  entity: Constructor<T>;
  specifiedApi?: string;
  limit?: number;
  offset?: number;
  orderColumn?: string;
  orderDirection?: 'ASC' | 'DESC';
  search?: Record<string, QueryParams[]>;
  filter?: Record<string, QueryParams[]>;
  keys?: string[];
  query?: Record<string, QueryParams[]>;
}

export interface OvAutoServiceGetParams<T> {
  entity: Constructor<T>;
  id: number;
  specifiedApi?: string;
  keys?: string[];
}

export interface OvAutoServiceCreateParams<T> {
  entity: Constructor<T>;
  specifiedApi?: string;
  item: Omit<T, 'id'>;
  keys?: string[];
  debounce?: boolean;
}

export interface OvAutoServiceUpdateParams<T> {
  entity: Constructor<T>;
  specifiedApi?: string;
  item: Partial<T> & HasId;
  keys?: string[];
  gql?: DocumentNode;
  debounce?: boolean;
}

export interface DirtyLoad<T extends HasId = HasId> {
  updates: Record<number, T>;
  creates: T[];
  callback?: (response: T[]) => void;
  entity?: Constructor<T>;
}

export type OvAutoServiceMultipleParams = Record<string, OvAutoServiceMultipleAll>;
export type OvAutoServiceMultipleMutationParams = Record<string, OvAutoServiceMultipleMutationAll>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OvAutoServiceMultipleAll = OvAutoServiceMultipleList<any> | OvAutoServiceMultipleGet<any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OvAutoServiceMultipleMutationAll = OvAutoServiceMultipleCreate<any> | OvAutoServiceMultipleUpdate<any>;
type OvAutoServiceMultipleList<T> = { type: 'list' } & OvAutoServiceListParams<T>;
type OvAutoServiceMultipleGet<T> = { type: 'get' } & OvAutoServiceGetParams<T>;
type OvAutoServiceMultipleCreate<T> = { type: 'create' } & OvAutoServiceCreateParams<T>;
type OvAutoServiceMultipleUpdate<T> = { type: 'update' } & OvAutoServiceUpdateParams<T>;

type OvAutoServiceMultipleResponse<T extends HasId> = Record<string, T | PageReturn<T>>;

@Injectable()
export class OvAutoService {
  constructor(public apollo: Apollo, @Inject('DEFAULT_API') private readonly defaultApi: string) {}

  // todo memoize
  getMetadata<T extends HasId>(entity: Constructor<T>): FieldMetadata<T> {
    return getFieldMetadata(entity);
  }

  async list<T extends HasId>(params: OvAutoServiceListParams<T>): Promise<PageReturn<T>> {
    const {
      entity,
      specifiedApi,
      offset = 0,
      limit = 100,
      query,
      search,
      filter,
      keys = [],
      orderColumn = 'id',
      orderDirection = 'ASC',
    } = params;
    const metadata = this.getMetadata(entity);
    const name = `list${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      const gqlQuery = listQueryKeys({
        name,
        input: entity,
        metadata,
        specificKeys: keys,
        api: this.defaultApi,
      });
      this.apollo
        .use(api)
        .query({
          query: gqlQuery,
          variables: {
            params: {
              limit,
              offset,
              orderColumn,
              orderDirection,
              search,
              filter,
              query,
            },
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'no-cache',
        })
        .subscribe(
          response => {
            const rawData = response.data[name].data;
            const { totalCount } = response.data[name];
            const data = rawData.map(item => mapToClass(entity, item));
            resolve({ data, totalCount });
          },
          // reject
          error => {
            console.log({ error });
            reject(error);
          },
        );
    });
  }

  async get<T extends HasId>(params: OvAutoServiceGetParams<T>): Promise<T>;
  async get<T extends HasId>(entity: Constructor<T>, specifiedApi: string, id: number): Promise<T>;
  async get<T extends HasId>(...params: unknown[]): Promise<T> {
    let entity: Constructor<T>;
    let specifiedApi: string;
    let id: number;
    let keys: string[];
    if (params.length > 1) {
      [entity, specifiedApi, id] = params as [Constructor<T>, string, number];
    } else {
      [{ entity, specifiedApi = 'shared', id, keys }] = params as OvAutoServiceGetParams<T>[];
    }
    const metadata = this.getMetadata(entity);
    const name = `get${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }

    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .query({
          query: getQueryKeys({
            name,
            input: entity,
            metadata,
            api: this.defaultApi,
            keys,
          }),
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(entity, rawData);
          resolve(data);
        }, reject);
    });
  }

  async getAll<T extends HasId>(entity: Constructor<T>, ids: number[], specifiedApi?: string): Promise<T[]> {
    const metadata = this.getMetadata(entity);
    const name = `get${metadata.name}ByIds`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .query({
          query: getByIdsQueryKeys({
            name,
            input: entity,
            metadata,
            api: this.defaultApi,
          }),
          variables: { ids },
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = rawData.map(item => mapToClass<T>(entity, item));
          resolve(data);
        }, reject);
    });
  }

  async create<T extends HasId>(params: OvAutoServiceCreateParams<T>): Promise<T>;
  async create<T extends HasId>(entity: Constructor<T>, specifiedApi: string, item: Omit<T, 'id'>): Promise<T>;
  async create<T extends HasId>(...params: unknown[]): Promise<T> {
    let entity: Constructor<T>;
    let specifiedApi: string;
    let item: Omit<T, 'id'>;
    let keys: string[];
    if (params.length > 1) {
      [entity, specifiedApi, item] = params as [Constructor<T>, string, Omit<T, 'id'>];
    } else {
      [{ entity, specifiedApi = 'shared', item, keys }] = params as OvAutoServiceCreateParams<T>[];
    }
    const metadata = this.getMetadata(entity);
    const name = `create${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }

    console.log({ item });
    return new Promise(resolve => {
      this.apollo
        .use(api)
        .mutate({
          mutation: createMutationKeys({
            name,
            input: entity,
            metadata,
            api: this.defaultApi,
            keys,
          }),
          variables: { data: item },
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(entity, rawData);
          resolve(data);
        });
    });
  }

  async update<T extends HasId>(params: OvAutoServiceUpdateParams<T>): Promise<T>;
  async update<T extends HasId>(entity: Constructor<T>, specifiedApi: string, item: Partial<T> & HasId): Promise<T>;
  async update<T extends HasId>(...params: unknown[]): Promise<T> {
    let entity: Constructor<T>;
    let specifiedApi: string;
    let item: Partial<T> & HasId;
    let keys: string[];
    let actualParams: OvAutoServiceUpdateParams<T>;
    if (params.length > 1) {
      [entity, specifiedApi, item] = params as [Constructor<T>, string, Partial<T> & HasId];
      actualParams = {
        entity,
        item,
      };
    } else {
      [{ entity, specifiedApi = 'shared', item, keys }] = params as OvAutoServiceUpdateParams<T>[];
      [actualParams] = params as OvAutoServiceUpdateParams<T>[];
    }
    const metadata = this.getMetadata(entity);
    const name = `update${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    const {
      gql = updateMutationKeys({
        name,
        input: entity,
        metadata,
        api: this.defaultApi,
        keys,
      }),
    } = actualParams;
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .mutate({
          mutation: gql,
          variables: { data: item },
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = mapToClass<T>(entity, rawData);
          resolve(data);
        }, reject);
    });
  }

  async delete<T extends HasId>(entity: Constructor<T>, specifiedApi: string, id: number | string): Promise<void> {
    const metadata = this.getMetadata(entity);
    const name = `delete${metadata.name}`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .mutate({
          mutation: deleteMutationKeys({ name }),
          variables: { id },
        })
        .subscribe(() => resolve(), reject);
    });
  }

  async listAncestors<T extends HasId>(entity: Constructor<T>, specifiedApi: string, id: number): Promise<T[]> {
    const metadata = this.getMetadata(entity);
    const name = `list${metadata.name}Ancestors`;
    let api = specifiedApi;
    if (!api || api === 'shared') {
      api = this.defaultApi;
    }
    return new Promise((resolve, reject) => {
      this.apollo
        .use(api)
        .query({
          query: getAncestorQueryKeys({
            name,
            input: entity,
            metadata,
            api: this.defaultApi,
          }),
          variables: { id },
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => {
          const rawData = response.data[name];
          const data = rawData.map(item => mapToClass<T>(entity, item));
          resolve(data);
        }, reject);
    });
  }

  async multipleFetch<T extends HasId>(params: OvAutoServiceMultipleParams): Promise<OvAutoServiceMultipleResponse<T>> {
    const queryParams: MultipleKeysParams<T> = {};
    const variables = {};

    Object.entries(params).forEach(([key, value]) => {
      const metadata = getFieldMetadata(value.entity);

      switch (value.type) {
        case 'list':
          variables[`${key}Data`] = {
            limit: value.limit ?? 100,
            offset: value.offset ?? 0,
            orderColumn: value.orderColumn ?? 'id',
            orderDirection: value.orderDirection ?? 'ASC',
            search: value.search,
            filter: value.filter,
            query: value.query,
          };
          queryParams[key] = {
            type: 'list',
            name: `list${metadata.name}`,
            api: value.specifiedApi,
            metadata,
            input: value.entity,
            keys: value.keys,
          };
          break;
        case 'get':
          variables[`${key}Data`] = value.id;
          queryParams[key] = {
            type: 'get',
            name: `get${metadata.name}`,
            api: value.specifiedApi ?? 'shared',
            metadata,
            input: value.entity,
            keys: value.keys,
          };
          break;
        default:
      }
    });

    const gqlQuery = multipleQueryKeys(queryParams);

    return new Promise((resolve, reject) => {
      this.apollo
        .use(this.defaultApi)
        .query({
          query: gqlQuery,
          variables,
          fetchPolicy: 'no-cache',
        })
        .subscribe(response => {
          const { data } = response;
          const output = {};
          Object.entries(data).forEach(([key, value]) => {
            const param = params[key];
            const { entity } = param;
            if (param.type === 'list') {
              output[key] = value;
              output[key].data = value.data.map(item => mapToClass<T>(entity, item));
            } else if (param.type === 'get') {
              output[key] = mapToClass<T>(entity, value);
            }
          });
          resolve(output);
          return output;
        }, reject);
    });
  }

  async multipleMutation<T extends HasId>(params: OvAutoServiceMultipleMutationParams): Promise<Record<string, T>> {
    const mutationParams: MultipleKeysParams<T> = {};
    const variables = {};

    const data = Object.entries(params);

    if (!data.length) {
      throw new Error('Cannot mutate without data');
    }

    data.forEach(([key, value]) => {
      const metadata = getFieldMetadata(value.entity);
      variables[`${key}Data`] = value['item'];
      mutationParams[key] = {
        type: value.type,
        name: `${value.type}${metadata.name}`,
        api: value.specifiedApi ?? 'shared',
        metadata,
        input: value.entity,
        keys: value.keys,
      };
    });

    const gqlMutation = multipleMutationKeys(mutationParams);

    return new Promise((resolve, reject) => {
      this.apollo
        .use(this.defaultApi)
        .mutate({ mutation: gqlMutation, variables })
        .subscribe(response => {
          const { data: dataResponse } = response;
          const output = {};
          Object.entries(dataResponse).forEach(([key, value]) => {
            const param = params[key];
            const { entity } = param;
            output[key] = mapToClass<T>(entity, value);
          });
          resolve(output);
          return output;
        }, reject);
    });
  }

  private dirty: Record<string, DirtyLoad> = {};

  isStillDirty<T extends HasId>(entity: Constructor<T>, id: number): boolean {
    const metadata = getFieldMetadata(entity);
    return !!this.dirty[metadata.name].updates[id];
  }

  async debounceUpdate<T extends HasId>(params: OvAutoServiceUpdateParams<T>, callback: (response: T[]) => void): Promise<void> {
    const metadata = getFieldMetadata(params.entity);
    if (!this.dirty[metadata.name]) {
      this.dirty[metadata.name] = {
        creates: [],
        updates: {},
      };
    }

    this.dirty[metadata.name].callback = callback;
    this.dirty[metadata.name].updates[`${params.item.id}`] = params.item;
    this.dirty[metadata.name].entity = params.entity;
    this.debouncedUpdate();
  }

  private readonly debouncedUpdate = _.debounce(() => this.triggerDebounceUpdate(), 1000, {
    leading: false,
    trailing: true,
    maxWait: 3000,
  });

  private triggerDebounceUpdate() {
    console.log('debounce Called');
    const updatePrefix = 'update';
    const createPrefix = 'create';

    // ¯\_(ツ)_/¯
    const params: OvAutoServiceMultipleMutationParams = {};

    Object.values(this.dirty).forEach(d => {
      const metadata = getFieldMetadata(d.entity);
      Object.values(d.updates).forEach(u => {
        params[`${updatePrefix}_${metadata.name}_${u.id}`] = {
          type: 'update',
          entity: d.entity,
          item: u,
          keys: ['id'],
        };
      });

      d.creates.forEach((c, i) => {
        params[`${createPrefix}_${metadata.name}_${i}`] = {
          type: 'create',
          entity: d.entity,
          item: c,
          keys: ['id'],
        };
      });
    });

    if (!Object.values(params).length) {
      return;
    }

    this.multipleMutation(params).then(response => {
      const responses: Record<string, unknown[]> = {};
      const indexesCleared: Record<string, number[]> = {};
      Object.entries(response).forEach(([key, value]) => {
        const [entity, indexStr] = key.split('_').slice(1);
        const index = Number(indexStr);
        if (key.startsWith(createPrefix)) {
          if (!indexesCleared[entity]) {
            indexesCleared[entity] = [];
          }
          indexesCleared[entity].push(index);
          this.dirty[entity].creates[index].id = Number(value.id);
        } else if (key.startsWith(updatePrefix)) {
          delete this.dirty[entity].updates[index];
        }
        if (!responses[entity]) {
          responses[entity] = [value];
        } else {
          responses[entity].push(value);
        }
      });
      Object.entries(indexesCleared).forEach(([key, value]) => {
        this.dirty[key].creates = this.dirty[key].creates.filter((c, i) => !value.includes(i));
      });

      Object.entries(responses).forEach(([key, value]) => {
        this.dirty[key].callback(value as HasId[]);
        delete this.dirty[key].callback;
      });
    });
  }
}
