<div class="main-content">
<div class="main-container">

<div class="slide-in-blurred-top">
  <div class="row">
    <div class="col-md-12">
      <div class="left-side">
        <div class="card left-side">
          <div class="card-body">
            <ng-container>
              <h5 class="card-title ml-3">
                {{ (data?.id && !isCloned) ? 'Edit Wave' : 'Create Picking Wave' }}
              </h5>
              <hr class="ml-3 mr-3" />
            </ng-container>
            <form class="col-md-6">
              <h6 class="pt-3 pb-0"> Details </h6>
              <div class="form-group">
                <ov-suite-input
                  [id]="'waveName'"
                  [name]="'waveName'"
                  [type]="'text'"
                  [inputLabel]="'Wave Name'"
                  [tooltip]="'Name'"
                  [(ngModel)]="data.waveName"
                  [placeholder]="''"
                  [danger]="requiredFields?.waveName"
                >
                </ov-suite-input>
                <small
                  *ngIf="requiredFields?.waveName"
                  class="form-text text-muted text-danger"
                >{{ 'Required' }}</small>
              </div>
              <div class="form-group">
                <ov-suite-input
                  [id]="'description'"
                  [name]="'description'"
                  [type]="'text'"
                  [inputLabel]="'Description'"
                  [tooltip]="'Description'"
                  [(ngModel)]="data.description"
                  [placeholder]="''"
                  [danger]="false"
                >
                </ov-suite-input>
                <small
                  *ngIf="false"
                  class="form-text text-muted text-danger"
                >{{ 'Required' }}</small>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="startDate">Wave Date</label>
                  <input type="date"
                         class="form-control custom-border-styling"
                         [(ngModel)]="data.startDate"
                         id="startDate"
                         name="startDate" placeholder="">
                  <small
                    *ngIf="requiredFields?.startDate"
                    class="form-text text-muted text-danger"
                  >{{ 'Required' }}</small>
                </div>
                <div class="form-group col-md-3">
                  <label for="startTime">Start Time</label>
                  <input type="time"
                         class="form-control custom-border-styling"
                         [(ngModel)]="data.startTime"
                         id="startTime"
                         name="startTime" placeholder="">
                </div>
                <div class="form-group col-md-3">
                  <label for="endTime">End Time</label>
                  <input type="time"
                         class="form-control custom-border-styling"
                         [(ngModel)]="data.endTime"
                         id="endTime" name="endTime"
                         placeholder="">
                </div>
              </div>

              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox"
                         [(ngModel)]="data.recurring"
                         class="custom-control-input border border-dark"
                         id="recurringChk" name="recurringChk">
                  <label class="custom-control-label" for="recurringChk"> Recurring</label>
                </div>
              </div>
                <br />
              <h6 *ngIf="data.recurring"> Frequency </h6>

              <div *ngIf="data.recurring" class="form-group">

                <ov-suite-recurrence
                  [id]="'recurrence'"
                  [name]="'Occurrence'"
                  [inputLabel]="'Occurrence'"
                  [tooltip]="''"
                  [(ngModel)]="data.recurrence"
                  [data]="_data.recurrence"
                  [danger]="false"
                >
                </ov-suite-recurrence>

              </div>

            </form>

              <button class="btn btn-outline-primary float-left ml-3" (click)="onCancel()">
                Cancel
              </button>

              <button
                class="btn btn-primary btn-round save-button float-right mr-2"
                type="submit"
                (click)="onNext(createTemplate)">
                Save & Next
              </button>

          </div>
        </div>
      </div>
    </div>

    <div class="col-md"> </div>

  </div>
</div>

</div>
</div>

<ng-template #createTemplate let-modal>
  <div class="">
  <div class="modal-header text-left">
    <h4 class="modal-title" id="modal-basic-title">
      <ov-suite-icon class="text-dark card-close-icon"
                     [name]="'arrow-left'"
                     (click)="modalService.dismissAll('Cross click')">
      </ov-suite-icon>
      Save wave as template?
    </h4>
  </div>
  <div class="modal-body">

    <div class="form-group">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input"
               [(ngModel)]="saveAsTemplate"
               id="saveAsTemplate" name="saveAsTemplate">
        <label class="custom-control-label" for="saveAsTemplate"> Save WAVE as template</label>
      </div>
    </div>

    <div *ngIf="saveAsTemplate" class="form-group">
      <label for="waveTemplateName">Wave template name</label>
      <input type="text" class="form-control"
             [(ngModel)]="templateName"
             id="waveTemplateName" name="waveTemplateName" placeholder="Wave Name">
    </div>

  </div>
  <div class="modal-footer d-flex flex-row justify-content-between mb-3 ml-4 mr-4">
    <button type="button" class="btn btn-outline-primary" (click)="modalService.dismissAll('Cross click')">
      Cancel
    </button>

    <button type="button" class="btn btn-outline-dark" (click)="onSave(updateWave)">
      Save & Next
      <ov-suite-icon class="text-white" [name]="'arrow-right'"> </ov-suite-icon>
    </button>
  </div>
  </div>
</ng-template>

<ng-template #updateWave let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Picking Wave Update</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p><strong>Are you sure you want to update this picking wave </strong></p>
    <div *ngIf="affected">
      <p>The following overlapping waves will be overridden</p>
      <ul>
        <li *ngFor="let wave of affected"> {{ wave.startDate }} </li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-info" (click)="modal.close('Ok click')">Ok</button>
  </div>

</ng-template>
