import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleTemplate } from '@ov-suite/models-warehouse';
import { LoadAllocationBaseService } from '../load-allocation.base.service';
import { ExternalVehicleCreateComponent } from '../external-vehicle/external-vehicle-create.component';
import { LoadAllocationDataService, LoadFilter } from '../load-allocation.data.service';
import { VehicleAllocation } from '../load-allocation.interface';
import { LoadAllocationVehicleLayoutService } from '../load-allocation.vehicle-layout.service';
import { LoadAllocationActionService } from '../load-allocation.action.service';

@Component({
  selector: 'ov-suite-load-allocation-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent implements OnInit, OnDestroy {
  @ViewChild('leftTable') leftTable: ElementRef;

  capacityBar: Record<number, { cap1: number; cap2: number }> = {};

  minDate = this.calendar.getToday();

  loaders: Record<string, boolean> = {};

  date: Date = new Date();

  filterStatuses = [
    {
      id: 1,
      label: 'All',
      value: LoadFilter.All,
    },
    {
      id: 2,
      label: 'Planning',
      value: LoadFilter.Planning,
    },
    {
      id: 3,
      label: 'Confirmed',
      value: LoadFilter.Confirmed,
    },
    {
      id: 4,
      label: 'Processed',
      value: LoadFilter.Processed,
    },
  ];

  filterClasses = [
    {
      id: 0,
      name: 'All',
      value: null,
    },
  ];

  classFilter: Record<string, any>;

  statusFilter: Record<string, any>;

  templates: VehicleTemplate[] = [];

  template: VehicleTemplate;

  constructor(
    public base: LoadAllocationBaseService,
    private readonly calendar: NgbCalendar,
    private readonly modalService: NgbModal,
    public data: LoadAllocationDataService,
    public action: LoadAllocationActionService,
    public layout: LoadAllocationVehicleLayoutService,
  ) {}

  ngOnInit() {
    this.data.vehicleClasses.observable.subscribe(classes => {
      this.filterClasses = [
        this.filterClasses[0],
        ...classes.map(cl => ({
          id: cl.id,
          name: cl.name,
          value: cl,
        })),
      ];
    });

    this.data.vehicleTemplates.observable.subscribe(templates => {
      this.templates = templates;
    });
  }

  ngOnDestroy() {
    this.data.vehicleClasses.observable.unsubscribe();
    this.data.vehicleTemplates.observable.unsubscribe();
  }

  onDateChange() {
    this.data.setDate(this.date);
  }

  onTemplateChange() {
    // Todo: Make Neater Confirmation Box
    // eslint-disable-next-line no-restricted-globals
    const conf = confirm(`Are you sure you want to apply template: ${this.template.name}?`);
    if (conf) {
      this.data.updateLoadsFromTemplate(this.template);
    }
    this.template = undefined;
  }

  onClassFilterChange() {
    this.data.vehicleFilter.vehicleClassFilter = this.classFilter.value;
    this.data.filterVehicles();
  }

  onStatusFilterChange() {
    this.data.vehicleFilter.activeFilter = this.statusFilter.value;
    this.data.filterVehicles();
  }

  // todo: Find correct Event Type
  searchVehicles(event): void {
    const term = event?.target?.value;
    this.data.filterVehicles(term);
  }

  getCapacity1(vehicle: VehicleAllocation): number {
    return vehicle.slots.reduce((prevSlot, slot) => prevSlot + slot.pins.reduce((prevPin, pin) => prevPin + pin.capacity1, 0), 0);
  }

  getCapacity2(vehicle: VehicleAllocation): number {
    return vehicle.slots.reduce((prevSlot, slot) => prevSlot + slot.pins.reduce((prevPin, pin) => prevPin + pin.capacity2, 0), 0);
  }

  getCapacity1Display(vehicle: VehicleAllocation): string {
    return `${this.getCapacity1(vehicle).toLocaleString()} / ${vehicle.capacity1.toLocaleString()} kg`;
  }

  getCapacity2Display(vehicle: VehicleAllocation): string {
    return `${this.getCapacity2(vehicle).toLocaleString()} / ${vehicle.capacity2.toLocaleString()} m³`;
  }

  getCapacity1Style(vehicle: VehicleAllocation): Record<string, unknown> {
    const reserved = this.getCapacity1(vehicle);
    const percentage = (reserved / vehicle.capacity1) * 100;

    let color: string;
    const { weightOrangePercentage, weightRedPercentage } = vehicle.load.vehicle.class;

    if (percentage >= weightRedPercentage) {
      color = '#FBF2F1';
    } else if (percentage >= weightOrangePercentage) {
      color = '#fed8b1';
    } else {
      color = '#D0F0C0';
    }

    return {
      width: `${percentage > 100 ? 100 : percentage}%`,
      'background-color': color
    };
  }

  getCapacity2Style(vehicle: VehicleAllocation): Record<string, unknown> {
    const reserved = this.getCapacity2(vehicle);
    const percentage = (reserved / vehicle.capacity2) * 100;

    let color: string;
    const { volumeOrangePercentage, volumeRedPercentage } = vehicle.load.vehicle.class;

    if (percentage >= volumeRedPercentage) {
      color = '#FFCCCB';
    } else if (percentage >= volumeOrangePercentage) {
      color = '#fed8b1';
    } else {
      color = '#CAE4F1'
    }

    return {
      width: `${percentage > 100 ? 100 : percentage}%`,
      'background-color': color,
    };
  }

  getMouseUpHandler(element: HTMLDivElement, mouseMoveHandler: (e: MouseEvent) => void): () => void {
    const output = () => {
      element.style.cursor = 'grab';
      element.style.removeProperty('user-select');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', output);
    };
    return output;
  }

  dragRight(event: MouseEvent, yElement: HTMLDivElement, xElement: HTMLDivElement, xElementStatic: HTMLDivElement): void {
    xElement.style.cursor = 'grabbing';
    xElement.style.userSelect = 'none';

    const dragPos = {
      left: xElement.scrollLeft,
      top: yElement.scrollTop,
      x: event.clientX,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - dragPos.x;
      const dy = e.clientY - dragPos.y;

      yElement.scrollTop = dragPos.top - dy;
      xElement.scrollLeft = dragPos.left - dx;
      xElementStatic.scrollLeft = dragPos.left - dx;
    };

    const mouseUpHandler = this.getMouseUpHandler(xElement, mouseMoveHandler);

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  dragLeft(event: MouseEvent, element: HTMLDivElement, grabElement: HTMLDivElement): void {
    grabElement.style.cursor = 'grabbing';
    grabElement.style.userSelect = 'none';

    const dragPos = {
      top: element.scrollTop,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dy = e.clientY - dragPos.y;

      element.scrollTop = dragPos.top - dy;
    };

    const mouseUpHandler = this.getMouseUpHandler(grabElement, mouseMoveHandler);

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  commit(event: MouseEvent, allocation: VehicleAllocation): void {
    event.stopPropagation();
    allocation.load.commitDate ? allocation.load.commitDate = null : allocation.load.commitDate = new Date();
    this.data.makeDirtyAndSave(allocation);
    this.data.filterVehicles();
  }

  onAddExternalVehicle() {
    this.modalService.open(ExternalVehicleCreateComponent, { size: 'xl' });
  }
}
