export enum WarehousePermission {
  waves = 101,
  vehicles = 102,
  routes = 103,
  loadAllocation = 104,
  waveAllocation = 105,
  inventoryList = 106,
  inventoryProduct = 107,
  processDefinitions = 108,
  workflowProcesses = 109,
  exceptions = 110,
  resources = 111,
}
