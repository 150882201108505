import { Component, OnInit } from '@angular/core';
import { MasterRoute } from '@ov-suite/models-warehouse';
import { HierarchyTab } from '@ov-suite/ui';

@Component({
  selector: 'ov-suite-master-route-list',
  templateUrl: './master-route-list.component.html',
})
export class MasterRouteListComponent implements OnInit {
  tabs: HierarchyTab[] = [
    { title: 'Picking Waves', path: '/waves' },
    { title: 'Vehicles', path: '/vehicles' },
    { title: 'Routes', path: '/master-route' },
  ];

  formClass = MasterRoute;

  constructor() { }

  ngOnInit(): void {}
}
