import { Component, OnInit } from '@angular/core';
import { Order, OrderItem } from '@ov-suite/models-order';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';
import { WaveAllocationActionService } from '../wave-allocation.action.service';
import { WaveAllocationVehicleChangeComponent } from '../wave-allocation-vehicle-change/wave-allocation-vehicle-change.component';
import { OrderPriorityConstant } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-wave-allocation-detail',
  templateUrl: './wave-allocation-detail.component.html',
  styleUrls: ['./wave-allocation-detail.component.scss'],
})
export class WaveAllocationDetailComponent implements OnInit {
  active = 1;
  orderPriority = OrderPriorityConstant;
  constructor(
    public base: WaveAllocationBaseService,
    public action: WaveAllocationActionService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {}

  getOrderItems(orders: Order[]): OrderItem[] {
    const orderItems = [];
    orders.forEach(order => {
      order.orderItems.forEach(orderItem => {
        orderItems.push(orderItem);
      });
    });

    return orderItems;
  }

  onChangeVehicle() {
    const modalRef = this.modalService.open(WaveAllocationVehicleChangeComponent, { size: 'xl' });
    modalRef.componentInstance.load = this.action.loadSelected;
    modalRef.componentInstance.submitted.subscribe(res => {
      if (res) {
        window.location.reload();
      }
    });
  }
}
