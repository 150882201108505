import { OVField, OVEntity, OVTable } from '@ov-suite/ov-metadata';
import { MapLocation } from '@ov-suite/models-helper';
import { Customer } from '@ov-suite/models-admin';
import { OrderItem } from './order-item.model';
import moment from 'moment/moment';
import { OrderPriorityConstant } from '@ov-suite/helpers-shared';

@OVEntity({ name: 'Order' })
@OVTable<Order>([
  {
    key: 'id',
    title: 'Order ID',
    type: 'number',
  },
  {
    key: 'orderCode',
    title: 'Order Code',
    type: 'string',
  },
  {
    title: 'Customer Code',
    type: 'other',
    keys: ['customer' ,'customer.customerCode'],
    action: (order)=> order?.customer?.customerCode
  },
  {
    title: 'Customer Name',
    type: 'other',
    keys: ['customer.name'],
    action: (order)=> order?.customer?.name
  },
  {
    title: 'Delivery Location',
    type: 'other',
    keys: ['address'],
    action: (order)=> order?.address
  },
  {
    key: 'orderDate',
    title: 'Order Date',
    type: 'date',
  },
  {
    key: 'fulfilmentDate',
    title: 'Shipping Date',
    type: 'date',
  },
  {
    title: 'SLA',
    type: 'other',
    keys: [''],
    action: (order)=> `${moment(order?.fulfilmentDate).diff(moment(order?.orderDate), 'days')} days`
  },
  {
    title: 'Priority',
    keys: ['priority'],
    type: 'other',
    action:(order)=> `<span [class]="'badge '+ ${ OrderPriorityConstant[order?.priority]?.color }">
            ${OrderPriorityConstant[order?.priority]?.text} </span>`
  },
])
export class Order {
  @OVField({ type: 'string' })
  id: number;

   @OVField({ type: 'map' })
   map: MapLocation;

  @OVField({ type: 'string' })
  specialInstructions?: string;

  // @OVField({ type: 'string' })
  // dueDate: string;

  @OVField({ type: 'string' })
  fulfilmentDate: string;

  @OVField({ type: 'string' })
  orderDate: string;

  @OVField({ type: 'string' })
  orderCode: string;

  @OVField({ type: 'string' })
  address: string;


  @OVField({ type: 'number' })
  priority: number;

  @OVField({ type: () => Customer })
  customer: Customer;

  @OVField({
    type: () => [OrderItem],
    keys: ['id', 'quantity', 'productSku.sku']
  })
  orderItems: OrderItem[];
}
