import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowProcessService } from './workflow-process.service';
import { WorkflowProcessRoutes } from './workflow-process.routing';
import { WorkflowProcessListComponent } from './list/workflow-process-list.component';
import { ProcessViewComponent } from './process-view/process-view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(WorkflowProcessRoutes),
    FormsModule,
    UiModule,
    NgbAlertModule,
  ],
  declarations: [ProcessViewComponent, WorkflowProcessListComponent],
  providers: [WorkflowProcessService],
})
export class WorkflowProcessModule {}
