import { ChangeDetectionStrategy, Component, ViewChild, Input } from "@angular/core";
import { AbstractValueAccessor, MakeProvider } from "../input/abstruct-value-accessor";
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

export interface DateRange {
  type: 'date-range';
  from: Date | null;
  to: Date | null;
}

@Component({
  selector: 'ov-suite-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [MakeProvider(DateRangeComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent extends AbstractValueAccessor<DateRange> {

  @ViewChild('datepicker') datePicker: NgbInputDatepicker;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null = null;

  toDate: NgbDate | null = null;

  displayDate = '';

  _placeholder = '';

  @Input() hideClearButton?: boolean;

  @Input() set placeholder(range: DateRange[]) {
    const date = range?.length ? range[0]: null;
    if (!date) {
      this._placeholder = '';
    } else {
      const from = moment(date.from);
      if (!date.to || date.from === date.to) {
        this._placeholder = from.format('DD MMM YY');
      } else {
        const to = moment(date.to)
        this._placeholder = from.format('DD MMM YY') + ' - ' + to.format('DD MMM YY');
      }
    }
  }

  constructor(private readonly calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    super();
  }

  emit() {
    if (this.fromDate && this.toDate) {
      const value: DateRange = {
        type: 'date-range',
        from: moment(this.formatter.format(this.fromDate)).toDate(),
        to: moment(this.formatter.format(this.toDate)).endOf('day').toDate()
      };
      this.val = value;
      this.onChange(value);
    } else if (!this.fromDate && !this.toDate) {
      const value: DateRange = {
        type: 'date-range',
        from: null,
        to: null
      }
      this.val = value;
      this.onChange(value);
    }
  }

  setDisplayDate() {
    if (!this.fromDate) {
      this.displayDate = '';
    } else {
      const from = moment(this.formatter.format(this.fromDate))
      if (!this.toDate || this.fromDate?.equals(this.toDate)) {
        this.displayDate = from.format('DD MMM YY');
      } else {
        const to = moment(this.formatter.format(this.toDate))
        this.displayDate = from.format('DD MMM YY') + ' - ' + to.format('DD MMM YY');
      }
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && !date.before(this.fromDate)) {
      this.toDate = date;
      this.datePicker.toggle();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.setDisplayDate();
    this.emit();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearDateRange() {
    this.fromDate = null;
    this.toDate = null;
    this._placeholder = '';
    this.setDisplayDate();
    this.emit();
  }
}
