<div class="main-content">
  <div class="main-container">
    <div class="master-route-card">
      <div class="details row">
        <div class="col-md-6">
          <div>
            <ov-suite-input
              type="text"
              placeholder="Master Route Name"
              [(ngModel)]="data.name"
              title="Master Route Name"
            >
          </ov-suite-input>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ov-suite-input
                type="dropdown"
                title="Start Location"
                [data]="factories"
                [label]="['name']"
                placeholder="Select Start Factory"
                [(ngModel)]="source"
              ></ov-suite-input>
            </div>
            <div class="col-md-6">
              <ov-suite-input
                type="dropdown"
                title="End Location"
                [data]="factories"
                [label]="['name']"
                placeholder="Select End Factory"
                [(ngModel)]="destination"
              ></ov-suite-input>
            </div>
          </div>
        </div>
        <div class="col-md-6 key-holder">
          <ng-container *ngTemplateOutlet="keyDiagram"></ng-container>
        </div>
      </div>
      <div class="map-container">
        <div class="top-panel">
          <div class="top-panel-inner">
            Customer List
          </div>
          <div class="d-inline-block">
            <input
              class="input"
              placeholder="Search"
              [(ngModel)]="searchTerm"
              (change)="onSearch()"
            />
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="input" id="filter" ngbDropdownToggle>Filter </button>
            <div ngbDropdownMenu aria-labelledby="filter">
              <button
                ngbDropdownItem
                class="filter-button"
                (click)="filterSelected()"
                [ngClass]="{'selected': filter == 0}">
                Selected
              </button>
              <button
                ngbDropdownItem
                class="filter-button"
                (click)="filterUnselected()"
                [ngClass]="{'selected': filter == 1}">
                Unselected
              </button>
              <button
                ngbDropdownItem
                class="filter-button"
                (click)="filterUnAssigned()"
                [ngClass]="{'selected': filter == 2}">
                Unassigned
              </button>
              <button
                ngbDropdownItem
                class="filter-button"
                (click)="filterClear()"
                [ngClass]="{'selected': filter == 3}">
                Show All
              </button>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="input" id="bulkActions" ngbDropdownToggle>Bulk Actions </button>
            <div ngbDropdownMenu aria-labelledby="bulkActions">
              <button ngbDropdownItem (click)="bulkFocus()">Focus</button>
              <button ngbDropdownItem (click)="bulkClear()">Clear Selection</button>
            </div>
          </div>
        </div>
        <div class="map-container-inner">
          <div class="side-panel p-2">
            <div *ngIf="!customersArray.length && !searchTerm"
                 class="flex-d text-center p-4 justify-content-center align-content-center master-routes-placeholder">
              <div> <img src="assets/img/icon_route.svg" alt="route icon"/> </div>
              <div>
                <h5>You have no customers </h5>
                <p>Why don't you start by clicking below and onboard customers.</p>
              </div>
              <div>
                <button (click)="OpenAdminLink()" type="button" class="btn btn-primary">
                  Add Customers <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
                </button>
              </div>
            </div>
            <table *ngIf="customersArray.length" class="table">

              <thead>
                <tr>
                  <th scope="col">
                    <input type="checkbox" [checked]="allTicked" (change)="toggleAll()">
                  </th>
                  <th scope="col">Customers</th>
                  <th scope="col">ID</th>
                </tr>
              </thead>
              <tbody cdkDropList
                     [cdkDropListData]="displayedCustomersArray"
                     (cdkDropListDropped)="drop($event)"
                     (cdkDropListSorted)="onDragEnter($event)">
                <tr *ngFor="let customer of displayedCustomersArray; let i = index"
                    cdkDrag
                    cdkDragLockAxis="y"
                    (cdkDragStarted)="onStartDrag(customer)"
                    (cdkDragEnded)="onEndDrag()"
                    [cdkDragDisabled]="!selectedCustomersMap[customer.id]"
                    (click)="focusCustomer($event, customer)"
                    (dblclick)="customerDoubleClick($event, customer)"
                    (mouseenter)="customerOnHover(customer)"
                    (mouseleave)="customerOffHover(customer)"
                    class="customer"
                    [ngClass]="{'selected-customer': selectedCustomersMap[customer.id]}"
                >
                  <th scope="col">
                    <input type="checkbox"
                           [checked]="tickedCustomersMap[customer.id]"
                           (click)="customerCheckBoxClick($event, customer)">
                  </th>
                  <th scope="col">{{selectedCustomersMap[customer.id] ? i + 1 + '. ' : null}}{{customer.name}}</th>
                  <th scope="col">{{customer.customerCode}}</th>
                </tr>
              </tbody>
            </table>

            <div *ngIf="!displayedCustomersArray.length && searchTerm.length > 0"
                 class="flex-d text-center p-4 justify-content-center align-content-center master-routes-placeholder">
              <div> <img src="assets/img/icon_route.svg" alt="route icon"/> </div>
              <div>
                <h5> we could not find a match for "{{searchTerm}}". please try another search. </h5>
              </div>
            </div>

          </div>
          <div class="map-panel">
            <div style="height: 500px;"
                 leaflet
                 [leafletFitBounds]="bounds"
                 [leafletOptions]="options"
                 (leafletMapReady)="onMapReady($event)"
            >
              <div *ngIf="!previewArray" [leafletLayers]="lineArray"></div>
              <div *ngIf="previewArray" [leafletLayers]="previewArray"></div>
              <div [leafletLayers]="customerPinArray"></div>
              <div [leafletLayers]="factoryPinArray"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-outline-primary float-left">Cancel</button>
        <button class="btn btn-primary float-right" (click)="save()">Save Route</button>
      </div>
    </div>
  </div>
</div>

<ng-template #keyDiagram>
  <div>
    <span class="key-title">Key</span>
    <div class="key-row">
      <span class="dot" [style]="{backgroundColor: styles.sourceFactoryColor}"></span>Start factory location
    </div>
    <div class="key-row">
      <span class="dot" [style]="{backgroundColor: styles.destinationFactoryColor}"></span>End factory location
    </div>
    <div class="key-row">
      <span class="dot" [style]="{backgroundColor: styles.selectedCustomerColor}"></span>Planned on route
    </div>
    <div class="key-row">
      <span class="dot" [style]="{backgroundColor: styles.unselectedCustomerColor}"></span>Planned on another route
    </div>
    <div class="key-row">
      <span class="dot" [style]="{backgroundColor: styles.noRouteCustomerColor}"></span>Unplanned across all routes
    </div>
  </div>
</ng-template>
