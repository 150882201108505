import { Component, OnInit } from '@angular/core';
import { MasterRoute } from '@ov-suite/models-warehouse';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationActionService } from '../wave-allocation.action.service';
import { WaveAllocationLoadSheetComponent } from '../wave-allocation-load-sheet/wave-allocation-load-sheet.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';

@Component({
  selector: 'ov-suite-wave-allocation-main',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './wave-allocation-main.component.html',
  styleUrls: ['./wave-allocation-main.component.scss'],
})
export class WaveAllocationMainComponent implements OnInit {
  customerSearchTerm: string;

  selectedMasterRoute: MasterRoute;

  constructor(
    private readonly modalService: NgbModal,
    public baseService: WaveAllocationBaseService,
    public dataService: WaveAllocationDataService,
    public action: WaveAllocationActionService) {}

  ngOnInit() {
    this.dataService.onInit();
  }

  onOpenLoadSheet() {
    this.modalService.open(WaveAllocationLoadSheetComponent, { size: 'xl' });
  }
}
