import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { MobileStartConfig } from '@ov-suite/models-shared';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: MobileStartConfig,
      api: 'shared',
      createTitle: 'Mobile Start Configuration',
      route: ['mobile-start-config'],
      tableTitle: 'Mobile Start Configurations'
    })
  ]
})
export class MobileStartConfigModule {}
