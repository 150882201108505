<div
  cdkDropList
  [id]="pinId"
  class="wave-bay-plan"
  cdkDropListOrientation="horizontal"
  [cdkDropListData]="waveBay"
  [cdkDropListConnectedTo]="baseService.lists"
  (cdkDropListDropped)="actionService.drop($event, wave, loadBay)"
  [cdkDropListDisabled]="!baseService.permissions.create"
>
  <ng-template #loadSettingsContent>
    <button
      [disabled]="!baseService.permissions.create"
      (click)="onRelease(waveBay)"
      class="btn btn-sm"
    > Release </button>
  </ng-template>
  <div *ngFor="let pin of waveBay"
       [cdkDragData]="pin"
       (click)="actionService.selectLoad(pin.load)"
       class="wave-bay-pin"
       [ngClass]="{'conflict-overbooked': waveBay.length > 1,
       'conflict-vehicle': actionService.vehicleConflicts[pin.load.id],
       'locked': !!pin.load.releaseDate}"
       cdkDrag
       [cdkDragDisabled]="!!pin.load.releaseDate"
       [ngbTooltip]="actionService.vehicleConflicts[pin.load.id] ?
        'This vehicle has already been planned for this wave' : null"
  >
    <i *ngIf="waveBay?.length" class="fa fa-ellipsis-v align-self-end custom-wave-release-pin"
       placement="right" [ngbPopover]="loadSettingsContent" aria-hidden="true"></i>
    <span *ngIf="waveBay.length <= 2">{{ pin.load.id }}</span>
    <span>{{ pin.load.getVehicle()?.registration }}</span>
    <span *ngIf="waveBay.length <= 1">{{ pin.loadBay.name }}</span>
    <span *ngIf="waveBay.length <= 1 && pin.load.releaseDate ">{{ pin.load.releaseDate | date }}</span>
  </div>
</div>
